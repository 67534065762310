import styled, { css, keyframes } from 'styled-components'

interface ContainerAnimation {
  isShow: string
  height?: string
  id?: string
}

const topToBottom = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`

const showAnimation = (props: ContainerAnimation) => {
  const currentHeight = document.getElementById(props.id)?.offsetHeight || 0
  let totalHeight = document.getElementById(props.id)?.scrollHeight || 0
  if (!document.getElementById(props.id)) {
    const element = document.getElementsByClassName('formation_container')[0]
    if (element) {
      totalHeight = element.scrollHeight
    }
  }
  if (document.getElementById(props.id)) {
    if (
      document.getElementById(props.id)?.offsetHeight ===
      document.getElementById(props.id)?.scrollHeight
    ) {
      return
    }
  }

  return keyframes`
  from {
    opacity: 0;
    height: ${currentHeight}px;
  }

  to {
    opacity: 1;
    height: ${totalHeight}px;
  }
`
}
const hiddenAnimation = (props: ContainerAnimation) => {
  if (
    !document.getElementById(props.id) &&
    !document.getElementById(props.id)?.offsetHeight
  ) {
    return
  }
  return keyframes`
    to {
      opacity: 0;
      height: 0;
    }

    from {
      opacity: 1;
      height: ${document.getElementById(props.id)?.offsetHeight}px;
    }
  `
}

export const ContainerAnimation = styled.div<ContainerAnimation>`
  /* display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; */
  height: 0px;
  transition: all 0.7s ease-in-out;
  /* clear: both; */
  /* opacity: 0; */
  /* position: absolute; */
  /* margin-top: 6.7rem;
  margin-left: 9px; */
  overflow: hidden;
  /* padding: 0;
  will-change: height; */
  ${props => {
    let totalHeight = document.getElementById(props.id)?.scrollHeight || 0
    if (!document.getElementById(props.id)) {
      const element = document.getElementsByClassName('formation_container')[0]
      if (element) {
        totalHeight = element.scrollHeight
      }
    }
    if (props.isShow === 'show') {
      return css`
        /* width: 100%; */
        /* position: absolute; */
        /* background-color: #fff; */
        /* opacity: 1; */
        height: ${totalHeight}px;
        animation: ${showAnimation(props)} 1s ease-in-out;
        /* margin-top: 6.7rem;
        margin-left: 9px; */
        /* z-index: 1; */
        /* box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
          0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%); */
      `
    }
    if (props.isShow === 'show child') {
      return css`
        /* width: 100%; */
        /* position: absolute; */
        /* background-color: #fff; */
        /* opacity: 1; */
        opacity: 1;
        height: ${totalHeight}px;
        animation: ${showAnimation(props)} 1s ease-in-out;
        /* margin-top: 6.7rem;
        margin-left: 9px; */
        /* z-index: 1; */
        /* box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
          0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%); */
      `
    }
    if (props.isShow === 'hidden') {
      return css`
        opacity: 0;
        height: 0;
        animation: ${hiddenAnimation(props)} 1s ease-in-out;
      `
    }
  }}
`
