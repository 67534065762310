import moment from 'moment'
import { InputHTMLAttributes, useCallback, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { IMaskInput } from 'react-imask'
import {
  Checkbox,
  Input,
  InputProps,
  Select,
  SelectProps
} from '../../../../../../components/Form'
import {
  Date as DatePicker,
  DateProps
} from '../../../../../../components/Form/date'
import { ContainerAnimation } from './styles'

interface DatepickerProps extends DateProps {
  selected: Date
  setSelected: React.Dispatch<React.SetStateAction<Date>>
}

interface FormPeopleProps extends InputHTMLAttributes<HTMLInputElement> {
  datepickerBirthDate: DatepickerProps
  inputName: InputProps
  inputRelationship: InputProps
  selectGender: SelectProps
  label?: string
  positive?: InputProps
  negative?: InputProps
  data: any[]
  setData: React.Dispatch<React.SetStateAction<any[]>>
  index?: number
  parent?: string
  hasHeader?: {
    label?: string
    positive?: InputProps
    negative?: InputProps
  }
  tooltip?: {
    type: string
  }
  changeTab?: React.SetStateAction<string>
}

export const FormPeople: React.FC<FormPeopleProps> = ({
  datepickerBirthDate,
  selectGender,
  inputRelationship,
  inputName,
  positive,
  negative,
  label,
  data,
  setData,
  index,
  tooltip,
  hasHeader,
  changeTab
}) => {
  const {
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
    clearErrors
  } = useFormContext()
  const inputMaskFormat = 'DD/MM/YYYY'
  const watchFieldDate = watch(datepickerBirthDate.name)
  const watchShowField = hasHeader?.positive?.name
    ? watch(hasHeader?.positive?.name)
    : undefined
  const [birthDate, setBirthDate] = useState<Date>(
    moment(watchFieldDate, 'DD/MM/YYYY').isValid()
      ? moment(watchFieldDate, 'DD/MM/YYYY').toDate()
      : undefined
  )
  const [copyData, setCopyData] = useState([...data])
  const [showInput, setShowInput] = useState(
    watchShowField || data[index]?.isActive || copyData[index]?.isActive || ''
  )
  const allowClass = ['show', 'show child']

  const handleAddForm = useCallback(
    (status: string) => {
      const showClass = ['show', 'show child']

      if (data[index + 1]) {
        const prefixArray = positive?.name.split('.')
        prefixArray.pop()
        const prefix = prefixArray.join('.')
        const workFields = getValues(prefix)
        if (workFields) {
          const objectEntries = Object.entries(workFields)
          for (let [key] of objectEntries) {
            key = `${prefix}.${key}`

            if (positive?.name !== key) {
              setValue(key, '')
            }
          }
        }
        clearErrors(prefix)
        setData(oldState => {
          const data = [...oldState]

          data[index + 1].isActive = showClass.includes(
            data[index + 1].isActive
          )
            ? 'hidden'
            : 'show'
          return data
        })
      } else {
        setData(oldState => {
          const data = [...oldState]
          data[index + 1] = {
            id: index + 1,
            isActive: showClass.includes(status) ? 'show child' : 'hidden',
            parent: index
          }
          data[index].isActive = 'show'
          return [...data]
        })
      }
    },
    [clearErrors, data, getValues, index, positive?.name, setData, setValue]
  )

  const handleClose = useCallback(() => {
    const showClass = ['show', 'show child']
    const watchField = watch(hasHeader?.positive?.name || '')
    if (watchField === 'hidden') {
      const prefixArray = hasHeader?.positive?.name.split('.')
      prefixArray.pop()
      const prefix = prefixArray.join('.')
      const workFields = getValues(prefix)
      if (workFields) {
        const objectEntries = Object.entries(workFields)
        for (let [key] of objectEntries) {
          key = `${prefix}.${key}`
          if (hasHeader?.positive?.name !== key) {
            setValue(key, '')
          }
        }
      }
      clearErrors(prefix)
    }
    if (showClass.includes(data[index]?.isActive)) {
      setData(oldState => {
        const data = [...oldState]
        data[index].isActive = showClass.includes(data[index].isActive)
          ? 'hidden'
          : 'show child'
        return data
      })
    }
  }, [
    clearErrors,
    data,
    getValues,
    hasHeader?.positive?.name,
    index,
    setData,
    setValue,
    watch
  ])

  return (
    <div className={'row pe-xl-5'}>
      <div>
        {hasHeader?.label && (
          <p className="mb-2 form-check-label text-gray-700 fw-bold  p-0">
            {hasHeader?.label}
          </p>
        )}
        <div className="d-flex">
          {hasHeader && hasHeader.positive && (
            <Checkbox
              className="col-xl-1"
              label="Sim"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              register={register}
              {...hasHeader.positive}
              errors={errors}
              type="radio"
              value="show"
              rules={{
                required: changeTab !== 'previous',
                onChange: () => {
                  const watchField = watch(hasHeader.positive.name)
                  if (watchField === 'show') {
                    setCopyData(oldState => {
                      const data = [...oldState]
                      data[index].isActive = 'show'
                      return [...data]
                    })
                    setShowInput('show')
                  } else {
                    handleClose()
                    setShowInput('hidden')
                  }
                }
              }}
              checked={allowClass.includes(showInput)}
              hasCustom
              hasErrorCustom
            />
          )}
          {hasHeader && hasHeader.negative && (
            <Checkbox
              className="col-xl-1"
              label="Não"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              {...hasHeader.negative}
              register={register}
              type="radio"
              value="hidden"
              rules={{ required: changeTab !== 'previous' }}
              errors={errors}
              checked={showInput === 'hidden'}
              hasCustom
            />
          )}
        </div>
      </div>
      <ContainerAnimation
        isShow={showInput}
        id={'people ' + datepickerBirthDate.name}
        className="people_container"
      >
        <div className="row mb-5 pe-xl-5">
          <Input
            className="col-xl pe-xl-5"
            label="Nome completo"
            register={register}
            inputOptions={{
              classname: 'd-flex align-items-center'
            }}
            {...inputName}
            tooltip={{
              container: {
                classname: 'ms-2'
              },
              classname: 'mw-30px',
              message: `<p style="max-width: 300px">
              Entre com o nome da pessoa que ${tooltip?.type}
                          </p>`
            }}
            rules={
              changeTab !== 'previous'
                ? {
                    required: allowClass.includes(showInput)
                  }
                : { required: false }
            }
            errors={errors}
            hasErrorCustom
            hasCustom
          />
          <Controller
            shouldUnregister={true}
            name={datepickerBirthDate.name}
            rules={
              changeTab !== 'previous'
                ? {
                    required: allowClass.includes(showInput)
                  }
                : { required: false }
            }
            render={({ field }) => (
              <DatePicker
                popperProps={{ strategy: 'fixed' }}
                locale="ptBR"
                className="col-xl"
                register={register}
                inputOptions={{ classname: 'd-flex align-items-center' }}
                label="Data de nascimento"
                dateFormat="dd/MM/yyyy"
                placeholderText="DD/MM/AAAA"
                tooltip={{
                  container: {
                    classname: 'ms-2'
                  },
                  classname: 'mw-30px',
                  message: `<p style="max-width: 300px">
                  Entre com a data de nascimento da pessoa que ${tooltip?.type}
                    </p>`
                }}
                hasCustom
                selected={birthDate}
                {...field}
                errors={errors}
                hasErrorCustom
                customInput={
                  <IMaskInput
                    mask={Date}
                    pattern={'d/m/Y'}
                    format={date => {
                      return moment(date).format(inputMaskFormat)
                    }}
                    parse={value => {
                      return moment(value, inputMaskFormat).toDate()
                    }}
                  />
                }
                onChange={(e: any) => {
                  field.onChange(e)
                  setBirthDate(e)
                }}
              />
            )}
          />
        </div>
        <div className="row mb-5">
          <Input
            className="col-xl"
            label="Relacionamento"
            register={register}
            inputOptions={{
              classname: 'd-flex align-items-center'
            }}
            {...inputRelationship}
            rules={
              changeTab !== 'previous'
                ? {
                    required: allowClass.includes(showInput)
                  }
                : { required: false }
            }
            errors={errors}
            hasErrorCustom
            hasCustom
          />
          <Controller
            shouldUnregister={true}
            name={selectGender.name}
            rules={
              changeTab !== 'previous'
                ? {
                    required: allowClass.includes(showInput)
                  }
                : { required: false }
            }
            render={({ field }) => (
              <Select
                className="col-xl"
                label="Sexo"
                register={register}
                {...selectGender}
                {...field}
                errors={errors}
                hasErrorCustom
                hasCustom
              />
            )}
          />
        </div>
        <div className="row mb-5 ">
          {!!label && <p>{label}</p>}
          <div className="d-flex">
            <Checkbox
              className="col-xl-1"
              label="Sim "
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              register={register}
              name={positive?.name}
              type="radio"
              value="show"
              rules={{
                onChange: () => {
                  const watchField = watch(positive.name)
                  if (watchField === 'show') {
                    handleAddForm('show')
                  } else {
                    handleAddForm('hidden')
                  }
                }
              }}
              checked={
                data[index + 1]?.isActive === 'show' ||
                data[index + 1]?.isActive === 'show child'
              }
              hasCustom
            />
            <Checkbox
              className="col-xl-1"
              label="Não"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name={negative?.name}
              register={register}
              type="radio"
              value="hidden"
              checked={data[index + 1]?.isActive === 'hidden'}
              hasCustom
            />
          </div>
        </div>
      </ContainerAnimation>
    </div>
  )
}
