import React, { useEffect, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../components/Container'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import { nameActions } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'
import { TUTORIALS_TYPES } from '../../../../common/constants/tutorial-type'

const TutorialsView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const { addToast } = useToast()

  const { disableLoading, activeLoading } = useLoading()
  const [tutorials, setTutorials] = useState<TutorialsApiResponse | null>(null)

  useEffect(() => {
    activeLoading()
    async function loadTutorials(): Promise<void> {
      activeLoading()
      try {
        const responseTutorial = await api.get<TutorialsApiResponse>(
          apiList(id)
        )
        setTutorials({ ...responseTutorial.data })

        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o tutorialse',
          description:
            'Houve um error ao carregar o tutorialse, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadTutorials()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLoading, addToast, disableLoading, history, location.pathname])

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Serviços removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Serviços não removido, pois ainda está sendo usada.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }

  return (
    <div className="d-flex flex-column container-fluid">
      <>
        <Container
          pageTitle={nameActions.read.name}
          breadcrumb={breadcrumbView}
          tools={[
            toolsViewUpdate(String(id)),
            toolsViewDelete(() => {
              handleOnClickRemoveParent()
            }),
            toolsViewCreate(),
            toolsViewList()
          ]}
        >
          <div className="form-body">
            <div className="row mb-5">
              <div className="col-md-1">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Cód.
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {tutorials?.id}
                  </p>
                </div>
              </div>
              <div className="col-md-1">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Tipo
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {TUTORIALS_TYPES[tutorials?.type]}
                  </p>
                </div>
              </div>
              <div className="col-md-10">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Título
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {tutorials?.title}
                  </p>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-md-12">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Url
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {tutorials?.url}
                  </p>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-md-12">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Descrição
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {tutorials?.description}
                  </p>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-md-12">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Descrição resumida
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {tutorials?.description_short}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <Alert
          message={`Tem certeza que deseja excluir o registro ${tutorials?.id} ?`}
          onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
          onClickConfirmButton={() =>
            handlerOnClickButtonConfirmRemoveParent(Number(tutorials?.id))
          }
          isActive={alertRemoveParent}
        />
      </>
    </div>
  )
}

export default TutorialsView
