import React, { ReactElement, useState } from 'react'
import TabTitle from './TabTitle'

type Props = {
  children: ReactElement[]
  subtitle?: string
  classname?: string
  activeTab?: number
  setActiveTab?: React.Dispatch<React.SetStateAction<number>>
  onePage?: boolean
}

const Tabs: React.FC<Props> = ({
  children,
  subtitle,
  classname,
  setActiveTab,
  activeTab,
  onePage
}) => {
  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <div>
      <ul
        className={
          'nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder ' +
          classname
        }
      >
        {onePage ? (
          <TabTitle
            key={0}
            active
            title="Resumo"
            index={0}
            setSelectedTab={setActiveTab || setSelectedTab}
          />
        ) : (
          children.map((item, index) => (
            <TabTitle
              key={index}
              active={
                activeTab !== undefined
                  ? index === activeTab
                  : index === selectedTab
              }
              title={item.props.title}
              index={index}
              setSelectedTab={setActiveTab || setSelectedTab}
              notClick={item.props.notClick}
            />
          ))
        )}
      </ul>
      <div className="card mb-5 mb-xl-10">
        {subtitle && !!subtitle.length && (
          <div className="d-flex justify-content-end">
            <span className="row">{subtitle}</span>
          </div>
        )}
        {children.map((item, index) => {
          if (onePage) {
            return item
          }
          if (index === (activeTab !== undefined ? activeTab : selectedTab)) {
            return item
          }
          return null
        })}
        {/* {onePage
          ? children[activeTab !== undefined ? activeTab : selectedTab]
          : children[activeTab !== undefined ? activeTab : selectedTab]} */}
      </div>
    </div>
  )
}

export default Tabs
