import { useCallback, useEffect, useLayoutEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { Checkbox, Input, InputProps } from '../../../../../../components/Form'
import { ContainerAnimation } from './styles'
import useCollapse from 'react-collapsed'
interface CheckboxWithConditionFormProps {
  label: string
  positive: InputProps
  negative: InputProps
  changeTab?: React.SetStateAction<string>
  positiveChildren?: () => JSX.Element
  negativeChildren?: () => JSX.Element
}

export const CheckboxWithConditionForm: React.FC<
  CheckboxWithConditionFormProps
> = ({
  label,
  negative,
  positive,
  changeTab,
  positiveChildren,
  negativeChildren
}) => {
  const {
    register,
    watch,
    resetField,
    getValues,
    clearErrors,
    setValue,
    formState: { errors }
  } = useFormContext()
  const watchField = watch(positive?.name)
  const clearValues = useCallback(
    ({ data, prefix }: { data: any; prefix: string }) => {
      const objectEntries = Object.entries(data)
      for (const [key, value] of objectEntries) {
        if (!value) continue
        if (typeof value === 'object') {
          clearValues({
            data: value,
            prefix: prefix?.length ? `${prefix}.${key}` : prefix
          })
        }
        setValue(`${prefix}.${key}`, null)
      }
    },
    [setValue]
  )
  useEffect(() => {
    if (watchField === 'true') {
      const prefix = 'has_plan_trip.no'
      const workFields = getValues(prefix)
      if (workFields) {
        clearValues({ data: workFields, prefix })
      }
      clearErrors(prefix)
    }
    if (watchField === 'false') {
      const prefix = 'has_plan_trip.yes'
      const workFields = getValues(prefix)
      if (workFields) {
        clearValues({ data: workFields, prefix })
      }
      clearErrors(prefix)
    }
  }, [clearErrors, clearValues, getValues, positive?.name, watchField])

  const hasOpen = !!watchField

  const { getToggleProps, getCollapseProps } = useCollapse({
    isExpanded: hasOpen
  })
  return (
    <div className="row">
      <div>
        <p className="mb-2 form-check-label text-gray-700 fw-bold p-0">
          {label}
        </p>
        <div className="d-xl-flex">
          <Checkbox
            className="col-sm col-xl-2 mb-2"
            label="Sim"
            register={register}
            value="true"
            inputOptions={{
              classname: 'd-flex align-items-center'
            }}
            rules={{ required: changeTab !== 'previous' }}
            type="radio"
            errors={errors}
            hasCustom
            hasErrorCustom
            {...positive}
            {...getToggleProps()}
          />
          <Checkbox
            className="col-sm col-xl-2 mb-2"
            label="Não"
            register={register}
            inputOptions={{
              classname: 'd-flex align-items-center'
            }}
            type="radio"
            value="false"
            rules={{ required: changeTab !== 'previous' }}
            errors={errors}
            hasCustom
            {...negative}
            {...getToggleProps()}
          />
        </div>
      </div>
      <div
        {...getCollapseProps()}
        // isShow={watchField === 'true' ? 'show' : 'hidden'}
        // id={positive.name}
      >
        {watchField === 'true' ? positiveChildren?.() : negativeChildren?.()}
      </div>
    </div>
  )
}
