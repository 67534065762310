import React, { useCallback, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select } from '../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { FormContainer } from './styles'
import { Loading } from '../../../../../components/Loading'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}
type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: ServiceCategoriesApiResponse & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}
const toastSuccessData: Record<string, () => ToastMessage> = {
  create: () => ({
    type: 'success',
    title: 'Registro criado',
    description: 'Registro criado com sucesso'
  }),
  update: () => ({
    type: 'success',
    title: 'Registro atualizado',
    description: 'Registro alterado com sucesso'
  })
}
const toastErrorData: Record<string, () => ToastMessage> = {
  create: () => ({
    type: 'error',
    title: 'Erro ao adicionar o registro',
    description:
      'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
  }),
  update: () => ({
    type: 'error',
    title: 'Erro ao atualizar',
    description:
      'Ocorreu um erro ao alterar os dados, por favor, tente novamente.'
  })
}

export const FormServiceCategories = ({
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const { activeLoading, disableLoading } = useLoading()
  const [isLoading, setIsLoading] = useState(false)

  const onSubmitForm = useCallback(
    async (data: any) => {
      setIsLoading(true)
      const id = initialValues?.idUpdate
      const apiRequest =
        typeForm === 'create'
          ? api.post(apiCreate(), { ...data })
          : api.put(apiUpdate(id.toString()), { ...data })

      try {
        activeLoading()
        await apiRequest
        disableLoading()
        addToast(toastSuccessData[typeForm]?.())
        if (typeForm === 'update') {
          updateDataTable()
        }
        history.push(nameActions.read.to)
      } catch (error) {
        history.push(nameActions.read.to)
        addToast(toastErrorData[typeForm]?.())
      }
      setIsLoading(false)
    },
    [
      activeLoading,
      addToast,
      disableLoading,
      history,
      initialValues?.idUpdate,
      typeForm,
      updateDataTable
    ]
  )
  if (isLoading) {
    return <Loading isActive />
  }

  return (
    <>
      <Form
        onSubmit={onSubmitForm}
        defaultValues={{
          name: initialValues?.name,
          active: Number(initialValues?.active)
        }}
      >
        <>
          <div className="card">
            <FormContainer>
              <div className="row mb-5">
                <Input
                  name="name"
                  label="Nome"
                  rules={{ required: true }}
                  className="col-md-4"
                />
                {typeForm === 'update' && (
                  <Select
                    className="col-md-2"
                    name="active"
                    label="Ativo"
                    options={[
                      {
                        name: 'Sim',
                        value: 1
                      },
                      {
                        name: 'Não',
                        value: 0
                      }
                    ]}
                    rules={{ required: true }}
                    blank
                  />
                )}
              </div>
            </FormContainer>
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button type="submit" className="btn btn-primary">
                Salvar
              </button>
            </div>
          </div>
        </>
      </Form>
    </>
  )
}
