import React from 'react'
import DataTable from '../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import Container from '../../../../components/Container'
import { toolsList } from '../domain/tools'
import { SERVICE_FORMAT_TRANSLATED } from '../../../../common/constants/service-formart'
import moment from 'moment'
import { INPUT_MASK } from '../../../../common/constants'
const ServicesList: React.FC = () => {
  return (
    <>
      <Container
        pageTitle="Listagem"
        breadcrumb={breadcrumbList}
        tools={toolsList}
      >
        <DataTable
          source={nameSource}
          entity={nameEntity}
          format={{ orderBy: 'created_at' }}
          headers={headers}
          customHeaders={[
            {
              name: 'Criado em',
              field: 'created_at',
              sortable: false,
              element: item => {
                return (
                  <p>
                    {moment(item?.created_at).format(
                      INPUT_MASK.DATE_LOCALE_WITH_TIME
                    )}
                  </p>
                )
              }
            },
            {
              name: 'Formato de atendimento',
              field: 'service_format',
              sortable: true,
              element: (item: { service_format: string }) => (
                <p>{SERVICE_FORMAT_TRANSLATED[item.service_format]}</p>
              )
            },
            {
              name: 'Ativo',
              field: 'active',
              sortable: true,
              element: (item: { active: string }) => (
                <p>{item.active ? 'Sim' : 'Não'}</p>
              )
            },
            {
              name: 'Parc. sem juros',
              field: 'interest_free_installments',
              sortable: false,
              element: item => {
                return (
                  <p>
                    {!item.interest_free_installments
                      ? 'Sem parcelamento sem juros'
                      : item.interest_free_installments}
                  </p>
                )
              }
            }
          ]}
          notHasChildren
        />
      </Container>
    </>
  )
}

export default ServicesList
