import React from 'react'
import ContractsList from '../../pages/Commercial/Forms/List'
import PrivateRoute from '../PrivateRoute'
import { CustomSwitch } from '../../components/CustomSwitch'

export const ContractFormsRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoute
      path="/commercial/contracts/forms"
      exact
      component={ContractsList}
    />
  </CustomSwitch>
)
