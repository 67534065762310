type TypeActionNameWithTo = {
  name: string
  to: string
  icon: string
}

type PortletTitleCrudType = {
  create: TypeActionNameWithTo
  read: TypeActionNameWithTo
  readContracts: TypeActionNameWithTo
  update: TypeActionNameWithTo
  delete: TypeActionNameWithTo
}
export type ClientData = {
  cpf: string
  created_at: string
  deleted_at?: string
  email: string
  id: number
  name: string
  status: string
  type: string
  values?: string
  updated_at: string
}

export type ContractApiResponse = {
  id: number
  cpf: string
  name: string
  payment_form: string
  status: string
  values: number
  created_at: string
  updated_at: string
  deleted_at?: string
}

const nameEntity = 'Form'
const nameSource = 'commercial/contracts/forms'
const namePageTitle = 'Formulários'
const nameActionPageMain: TypeActionNameWithTo = {
  name: 'Formulários',
  to: '/commercial/contracts/forms',
  icon: ''
}

const nameActions: PortletTitleCrudType = {
  create: {
    name: 'Adicionar',
    to: '/commercial/contracts/forms/create/',
    icon: 'fa fa-plus'
  },
  read: {
    name: 'Visualizar',
    to: '/commercial/contracts/forms/',
    icon: 'fa fa-list'
  },
  readContracts: {
    name: 'Contratos',
    to: '/commercial/contracts/forms/contracts',
    icon: 'fa fa-list'
  },
  update: {
    name: 'Editar',
    to: '/commercial/contracts/forms/update/',
    icon: 'fa fa-edit'
  },
  delete: {
    name: 'Remover',
    to: '#!',
    icon: 'fa fa-remove'
  }
}

export {
  nameEntity,
  nameSource,
  namePageTitle,
  nameActionPageMain,
  nameActions
}
