import moment from 'moment'
import React, { useCallback, useEffect } from 'react'
import useCollapse from 'react-collapsed'
import { Controller, useFormContext } from 'react-hook-form'
import { IMaskInput } from 'react-imask'

import {
  DIVORCED_FIELD_NAME,
  INPUT_MASK,
  LEGALLY_SEPARATED_FIELD_NAME,
  MARRIED_FIELD_NAME,
  maxDate,
  SINGLE_FIELD_NAME,
  STABLE_UNION_FIELD_NAME,
  WIDOWED_FIELD_NAME
} from '../../../../../../common/constants'
import {
  Checkbox,
  Input,
  InputProps,
  Select,
  SelectProps,
  Textarea,
  TextareaProps
} from '../../../../../../components/Form'
import { DateProps } from '../../../../../../components/Form/date'
import { DatePickerCustom } from '../../../../../../components/Form/dateWithoutState'
import { ContainerAnimation } from './styles'

interface DatepickerProps extends DateProps {
  selected: Date
  setSelected: React.Dispatch<React.SetStateAction<Date>>
}

interface FormMaritalStatusProps {
  datepickerMarriageStart: DatepickerProps
  datepickerMarriageEnd: DatepickerProps
  datepickerSpouseDateBirth: DatepickerProps
  datepickerStableUnion: DatepickerProps
  inputSpouseName: InputProps
  inputSpouseAddress: InputProps
  inputSpouseOccupation: InputProps
  inputSpouseCountry: InputProps
  inputSpouseDescriptionOccupation: InputProps
  inputSpouseCityBirth: InputProps
  selectTravelWithYou: SelectProps
  selectRelationshipType: SelectProps
  textExplain: TextareaProps
  label?: string
  labelClassname?: any
  type?: string
  inputStatus?: InputProps
  changeTab?: React.SetStateAction<string>
  noOptions?: boolean
  noForm?: boolean
  states?: any[]
}
const spouseName = 'spouse.name'
const spouseBirthDate = 'spouse.birth_date'
const spouseCityBirth = 'spouse.city_birth'
const stableUnion = 'spouse.stable_union_date'

export const FormMaritalStatus: React.FC<FormMaritalStatusProps> = ({
  inputStatus,
  label,

  changeTab,
  states,
  noOptions,
  labelClassname,
  noForm
}) => {
  const inputMaskFormat = 'DD/MM/YYYY'
  const {
    register,
    watch,
    getValues,
    setValue,
    control,
    clearErrors,

    formState: { errors }
  } = useFormContext()
  const watchField = watch(inputStatus?.name)
  const clearValues = useCallback(
    ({ data, prefix }: { data: any; prefix: string }) => {
      const objectEntries = Object.entries(data)
      for (const [key, value] of objectEntries) {
        if (!value) continue
        if (typeof value === 'object') {
          clearValues({
            data: value,
            prefix: prefix?.length ? `${prefix}.${key}` : prefix
          })
        }
        setValue(`${prefix}.${key}`, null)
      }
    },
    [setValue]
  )

  const verifyIfCheckboxValueIsEqualToDivorced =
    watchField === DIVORCED_FIELD_NAME
  const verifyIfCheckboxValueIsEqualToLegallySeparated =
    watchField === LEGALLY_SEPARATED_FIELD_NAME
  const verifyIfCheckboxValueIsEqualToWidowed =
    watchField === WIDOWED_FIELD_NAME
  const verifyIfCheckboxValueIncludesStableUnion = watchField?.includes?.(
    STABLE_UNION_FIELD_NAME
  )
  const verifyIfCheckboxValueIsEqualToMarried =
    watchField === MARRIED_FIELD_NAME
  const verifyIfCheckboxValueIsEqualToSingle = watchField === SINGLE_FIELD_NAME
  const formPrefix = 'form_marital_status'
  const marriedAddressCheck = watch(
    formPrefix + '.married.spouse.address.check'
  )
  const verifyIfAddressMarriedIsOther = marriedAddressCheck === 'other_address'
  const marriedAddressCheckAnimation = useCollapse({
    isExpanded: verifyIfAddressMarriedIsOther
  })
  const marriedAnimation = useCollapse({
    isExpanded: verifyIfCheckboxValueIsEqualToMarried
  })
  useEffect(() => {
    if (watchField !== getValues(inputStatus?.name)) {
      const workFields = getValues(formPrefix)
      if (workFields) {
        clearValues({ data: workFields, prefix: formPrefix })
      }
      clearErrors(formPrefix)
    }
  }, [clearErrors, clearValues, getValues, inputStatus?.name, watchField])
  return (
    <div>
      <div>
        {!!label &&
          ((noOptions && watchField && !verifyIfCheckboxValueIsEqualToSingle) ||
            !noOptions) && (
            <p
              className={
                'mb-2 form-check-label text-gray-700 fw-bold  p-0 ' +
                labelClassname
              }
            >
              {label}
            </p>
          )}
        {!noOptions && (
          <div className="d-flex flex-column flex-sm-row">
            <div className="col mb-2">
              <Checkbox
                className="col-xl-1"
                label="Casado"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                register={register}
                {...inputStatus}
                type="radio"
                rules={{ required: changeTab !== 'previous' }}
                errors={errors}
                hasErrorCustom
                value={MARRIED_FIELD_NAME}
                hasCustom
              />
            </div>
            <div className="col mb-2">
              <Checkbox
                className="col-xl-1"
                label="Solteiro"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                register={register}
                {...inputStatus}
                type="radio"
                rules={{ required: changeTab !== 'previous' }}
                errors={errors}
                value="single"
                hasCustom
              />
            </div>
            <div className="col mb-2">
              <Checkbox
                className="col-xl-1"
                label="União Estável"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                register={register}
                {...inputStatus}
                type="radio"
                value="stable_union_with_contract"
                rules={{ required: changeTab !== 'previous' }}
                hasCustom
                errors={errors}
              />
            </div>
            <div className="col mb-2">
              <Checkbox
                className="col-xl-1"
                label="União Civil Parceria Doméstica"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                register={register}
                {...inputStatus}
                type="radio"
                value="stable_union_without_contract"
                rules={{ required: changeTab !== 'previous' }}
                hasCustom
                errors={errors}
              />
            </div>
            <div className="col mb-2">
              <Checkbox
                className="col-xl-1"
                label="Viúvo"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                register={register}
                {...inputStatus}
                type="radio"
                value={WIDOWED_FIELD_NAME}
                rules={{ required: changeTab !== 'previous' }}
                hasCustom
                errors={errors}
              />
            </div>
            <div className="col mb-2">
              <Checkbox
                className="col-xl-1"
                label="Divorciado"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                register={register}
                {...inputStatus}
                type="radio"
                value="divorced"
                rules={{ required: changeTab !== 'previous' }}
                errors={errors}
                hasCustom
              />
            </div>
            <div className="col mb-2">
              <Checkbox
                className="col-xl-1"
                label="Separado legalmente"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                register={register}
                {...inputStatus}
                type="radio"
                value={LEGALLY_SEPARATED_FIELD_NAME}
                rules={{ required: changeTab !== 'previous' }}
                errors={errors}
                hasCustom
              />
            </div>
          </div>
        )}
      </div>

      {!noForm && (
        <div className="row mb-5 pe-sm-5 pe-xl-0 ps-3">
          <div
            {...marriedAnimation.getCollapseProps()}
            // isShow={verifyIfCheckboxValueIsEqualToMarried ? 'show' : 'hidden'}
            // id="family_union_married"
          >
            <div className="row mb-5 ">
              <Input
                className="col-xl"
                label="Nome completo do cônjuge"
                register={register}
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={formPrefix + '.married.spouse.name'}
                // {...inputSpouseName}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToMarried
                      }
                    : { required: false }
                }
                errors={errors}
                hasErrorCustom
                hasCustom
              />
              <Controller
                shouldUnregister={true}
                control={control}
                name={formPrefix + '.married.spouse.birth_date'}
                // name={datepickerSpouseDateBirth.name}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToMarried
                      }
                    : { required: false }
                }
                render={({ field }) => (
                  <DatePickerCustom
                    popperProps={{ strategy: 'fixed' }}
                    locale="ptBR"
                    className="col-xl-3"
                    register={register}
                    inputOptions={{ classname: 'd-flex align-items-center' }}
                    label="Data de nascimento do cônjuge"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/AAAA"
                    hasCustom
                    // selected={birthDate}
                    {...field}
                    // rules={
                    //   changeTab !== 'previous'
                    //     ? {
                    //         required: verifyIfCheckboxValueIsEqualToMarried
                    //       }
                    //     : { required: false }
                    // }
                    errors={errors}
                    hasErrorCustom
                    maxDate={maxDate}
                    customInput={
                      <IMaskInput
                        mask={Date}
                        pattern={'d/m/Y'}
                        format={date => {
                          return moment(date).format(inputMaskFormat)
                        }}
                        parse={value => {
                          return moment(value, inputMaskFormat).toDate()
                        }}
                        validate={(date: string) => {
                          if (date.length === 10) {
                            if (
                              moment(date, inputMaskFormat).isAfter(moment())
                            ) {
                              return false
                            }
                            const age = moment().diff(
                              moment(date, inputMaskFormat),
                              'year'
                            )
                            if (age < 18) {
                              return false
                            }
                          }
                          return true
                        }}
                      />
                    }
                    // onChange={(e: any) => {
                    //   field.onChange(e)
                    //   setBirthDate(e)
                    // }}
                  />
                )}
              />
              <Controller
                shouldUnregister={true}
                control={control}
                name={formPrefix + '.married.stable_union_date'}
                // name={datepickerStableUnion.name}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToMarried
                      }
                    : { required: false }
                }
                render={({ field }) => (
                  <DatePickerCustom
                    popperProps={{ strategy: 'fixed' }}
                    locale="ptBR"
                    className="col-xl-3"
                    register={register}
                    inputOptions={{ classname: 'd-flex align-items-center' }}
                    label="Data de casamento"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/AAAA"
                    hasCustom
                    // selected={unionStableDate}

                    {...field}
                    errors={errors}
                    hasErrorCustom
                    customInput={
                      <IMaskInput
                        mask={Date}
                        pattern={'d/m/Y'}
                        format={date => {
                          return moment(date).format(inputMaskFormat)
                        }}
                        parse={value => {
                          return moment(value, inputMaskFormat).toDate()
                        }}
                      />
                    }
                    // onChange={(e: any) => {
                    //   field.onChange(e)
                    //   setUnionStableDate(e)
                    // }}
                  />
                )}
              />
            </div>
            <div className="d-flex flex-column flex-sm-row">
              <div className="col mb-2">
                <Checkbox
                  className="col-xl-1"
                  label="Mesmo endereço que o residencial"
                  inputOptions={{
                    classname: 'd-flex align-items-center'
                  }}
                  register={register}
                  name={formPrefix + '.married.spouse.address.check'}
                  type="radio"
                  rules={{
                    required:
                      changeTab !== 'previous' &&
                      verifyIfCheckboxValueIsEqualToMarried
                  }}
                  errors={errors}
                  hasErrorCustom
                  value="same_address"
                  hasCustom
                  {...marriedAddressCheckAnimation.getToggleProps()}
                />
              </div>
              <div className="col mb-2">
                <Checkbox
                  className="col-xl-1"
                  label="Não sei"
                  inputOptions={{
                    classname: 'd-flex align-items-center'
                  }}
                  register={register}
                  name={formPrefix + '.married.spouse.address.check'}
                  type="radio"
                  rules={{
                    required:
                      changeTab !== 'previous' &&
                      verifyIfCheckboxValueIsEqualToMarried
                  }}
                  errors={errors}
                  value="i_dont_know"
                  hasCustom
                  {...marriedAddressCheckAnimation.getToggleProps()}
                />
              </div>
              <div className="col mb-2">
                <Checkbox
                  className="col-xl-1"
                  label="Outro endereço"
                  inputOptions={{
                    classname: 'd-flex align-items-center'
                  }}
                  register={register}
                  name={formPrefix + '.married.spouse.address.check'}
                  type="radio"
                  value="other_address"
                  rules={{
                    required:
                      changeTab !== 'previous' &&
                      verifyIfCheckboxValueIsEqualToMarried
                  }}
                  hasCustom
                  errors={errors}
                  {...marriedAddressCheckAnimation.getToggleProps()}
                />
              </div>
            </div>

            <div {...marriedAddressCheckAnimation.getCollapseProps()}>
              <div className="row mb-5">
                <Controller
                  shouldUnregister={true}
                  control={control}
                  name={formPrefix + '.married.spouse.address.zip_code'}
                  rules={
                    changeTab !== 'previous'
                      ? {
                          required: verifyIfAddressMarriedIsOther
                        }
                      : { required: false }
                  }
                  render={({ field }) => (
                    <Input
                      register={register}
                      className="col-xl-2"
                      label="CEP"
                      inputOptions={{
                        classname: 'd-flex align-items-center'
                      }}
                      hasErrorCustom
                      // name={formPrefix + '.' + "address.zip_code"}
                      mask={{
                        mask: INPUT_MASK.ZIP_CODE,
                        type: 'zipCode',
                        prefixInputsData:
                          formPrefix + '.married.spouse.address.',
                        customFields: [
                          {
                            name: 'state',
                            type: 'state',
                            api_field: 'uf'
                          }
                        ]
                      }}
                      rules={
                        changeTab !== 'previous'
                          ? {
                              required: verifyIfAddressMarriedIsOther
                            }
                          : { required: false }
                      }
                      maxLength={10}
                      hasCustom
                      {...field}
                      errors={errors}
                    />
                  )}
                />
                <Input
                  register={register}
                  className="col-xl"
                  label="Rua, nº, complemento de nascimento"
                  inputOptions={{
                    classname: 'd-flex align-items-center'
                  }}
                  name={formPrefix + '.married.spouse.address.street'}
                  rules={
                    changeTab !== 'previous'
                      ? {
                          required: verifyIfAddressMarriedIsOther
                        }
                      : { required: false }
                  }
                  errors={errors}
                  hasErrorCustom
                  hasCustom
                />
                {/* <Input
                  register={register}
                  className="col-xl-2"
                  label="Número "
                  inputOptions={{
                    classname: 'd-flex align-items-center'
                  }}
                  name={formPrefix + '.married.spouse.address.number'}
                  rules={
                    changeTab !== 'previous'
                      ? {
                          required: verifyIfAddressMarriedIsOther
                        }
                      : { required: false }
                  }
                  hasErrorCustom
                  hasCustom
                  errors={errors}
                /> */}
              </div>
              <div className="row mb-5">
                <Input
                  register={register}
                  className="col-xl"
                  label="Bairro de nascimento"
                  inputOptions={{
                    classname: 'd-flex align-items-center'
                  }}
                  name={formPrefix + '.married.spouse.address.district'}
                  rules={
                    changeTab !== 'previous'
                      ? {
                          required: verifyIfAddressMarriedIsOther
                        }
                      : { required: false }
                  }
                  hasErrorCustom
                  hasCustom
                  errors={errors}
                />
                <Input
                  register={register}
                  className="col-xl"
                  label="Cidade de nascimento"
                  inputOptions={{
                    classname: 'd-flex align-items-center'
                  }}
                  name={formPrefix + '.married.spouse.address.city'}
                  rules={
                    changeTab !== 'previous'
                      ? {
                          required: verifyIfAddressMarriedIsOther
                        }
                      : { required: false }
                  }
                  hasErrorCustom
                  hasCustom
                  errors={errors}
                />
                <Select
                  className="col-xl"
                  label="Estado de nascimento"
                  labelOptions={{ classname: 'col-xl' }}
                  selectOptions={{
                    classname: 'col-xl'
                  }}
                  name={formPrefix + '.married.spouse.address.state'}
                  rules={
                    changeTab !== 'previous'
                      ? {
                          required: {
                            value: verifyIfAddressMarriedIsOther,
                            message: 'Este Campo é Obrigatorio'
                          }
                        }
                      : { required: false }
                  }
                  options={states}
                  register={register}
                  blank
                  errors={errors}
                />
                <Input
                  className="col-xl"
                  label="País de nascimento"
                  inputOptions={{
                    classname: 'd-flex align-items-center'
                  }}
                  name={formPrefix + '.married.spouse.address.country'}
                  defaultValue="BRASIL"
                  rules={
                    changeTab !== 'previous'
                      ? {
                          required: verifyIfAddressMarriedIsOther
                        }
                      : { required: false }
                  }
                  hasCustom
                  hasErrorCustom
                />
              </div>
            </div>
          </div>
          <ContainerAnimation
            isShow={
              verifyIfCheckboxValueIncludesStableUnion ? 'show' : 'hidden'
            }
            id="family_union_stable_union"
          >
            <div className="row mb-5 ">
              <Input
                className="col-xl"
                label="Nome completo do companheiro"
                register={register}
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                // {...inputSpouseName}
                name={formPrefix + '.stable_union.spouse.name'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIncludesStableUnion
                      }
                    : { required: false }
                }
                errors={errors}
                hasErrorCustom
                hasCustom
              />
              <Controller
                shouldUnregister={true}
                control={control}
                name={formPrefix + '.stable_union.spouse.birth_date'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIncludesStableUnion
                      }
                    : { required: false }
                }
                render={({ field }) => (
                  <DatePickerCustom
                    popperProps={{ strategy: 'fixed' }}
                    locale="ptBR"
                    className="col-xl-3"
                    register={register}
                    inputOptions={{ classname: 'd-flex align-items-center' }}
                    label="Data de nascimento do companheiro"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/AAAA"
                    hasCustom
                    // selected={birthDate}
                    {...field}
                    rules={
                      changeTab !== 'previous'
                        ? {
                            required: verifyIfCheckboxValueIncludesStableUnion
                          }
                        : { required: false }
                    }
                    errors={errors}
                    hasErrorCustom
                    maxDate={maxDate}
                    customInput={
                      <IMaskInput
                        mask={Date}
                        pattern={'d/m/Y'}
                        format={date => {
                          return moment(date).format(inputMaskFormat)
                        }}
                        parse={value => {
                          return moment(value, inputMaskFormat).toDate()
                        }}
                        validate={(date: string) => {
                          if (date.length === 10) {
                            if (
                              moment(date, inputMaskFormat).isAfter(moment())
                            ) {
                              return false
                            }
                            const age = moment().diff(
                              moment(date, inputMaskFormat),
                              'year'
                            )
                            if (age < 18) {
                              return false
                            }
                          }
                          return true
                        }}
                      />
                    }
                  />
                )}
              />
              <Controller
                shouldUnregister={true}
                control={control}
                name={formPrefix + '.married.stable_union_date_date'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIncludesStableUnion
                      }
                    : { required: false }
                }
                render={({ field }) => (
                  <DatePickerCustom
                    popperProps={{ strategy: 'fixed' }}
                    locale="ptBR"
                    className="col-xl-3"
                    register={register}
                    inputOptions={{ classname: 'd-flex align-items-center' }}
                    label="Data de união estável"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/AAAA"
                    hasCustom
                    {...field}
                    errors={errors}
                    hasErrorCustom
                    customInput={
                      <IMaskInput
                        mask={Date}
                        pattern={'d/m/Y'}
                        format={date => {
                          return moment(date).format(inputMaskFormat)
                        }}
                        parse={value => {
                          return moment(value, inputMaskFormat).toDate()
                        }}
                      />
                    }
                  />
                )}
              />
            </div>

            <div className="row mb-5">
              <Controller
                shouldUnregister={true}
                control={control}
                name={formPrefix + '.stable_union.spouse.address.zip_code'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIncludesStableUnion
                      }
                    : { required: false }
                }
                render={({ field }) => (
                  <Input
                    register={register}
                    className="col-xl-2"
                    label="CEP"
                    inputOptions={{
                      classname: 'd-flex align-items-center'
                    }}
                    hasErrorCustom
                    // name={formPrefix + '.' + "address.zip_code"}
                    mask={{
                      mask: INPUT_MASK.ZIP_CODE,
                      type: 'zipCode',
                      prefixInputsData:
                        formPrefix + '.stable_union.spouse.address.',
                      customFields: [
                        {
                          name: 'state',
                          type: 'state',
                          api_field: 'uf'
                        }
                      ]
                    }}
                    rules={
                      changeTab !== 'previous'
                        ? {
                            required: verifyIfCheckboxValueIncludesStableUnion
                          }
                        : { required: false }
                    }
                    maxLength={10}
                    hasCustom
                    {...field}
                    errors={errors}
                  />
                )}
              />
              <Input
                register={register}
                className="col-xl"
                label="Rua, nº, complemento de nascimento"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={formPrefix + '.stable_union.spouse.address.street'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIncludesStableUnion
                      }
                    : { required: false }
                }
                errors={errors}
                hasErrorCustom
                hasCustom
              />
              {/* <Input
                register={register}
                className="col-xl-2"
                label="Número "
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={formPrefix + '.stable_union.spouse.address.number'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIncludesStableUnion
                      }
                    : { required: false }
                }
                hasErrorCustom
                hasCustom
                errors={errors}
              /> */}
            </div>
            <div className="row mb-5">
              <Input
                register={register}
                className="col-xl"
                label="Bairro de nascimento"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={formPrefix + '.stable_union.spouse.address.district'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIncludesStableUnion
                      }
                    : { required: false }
                }
                hasErrorCustom
                hasCustom
                errors={errors}
              />
              <Input
                register={register}
                className="col-xl"
                label="Cidade de nascimento"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={formPrefix + '.stable_union.spouse.address.city'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIncludesStableUnion
                      }
                    : { required: false }
                }
                hasErrorCustom
                hasCustom
                errors={errors}
              />
              <Select
                className="col-xl"
                label="Estado de nascimento"
                labelOptions={{ classname: 'col-xl' }}
                selectOptions={{
                  classname: 'col-xl'
                }}
                name={formPrefix + '.stable_union.spouse.address.state'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIncludesStableUnion
                      }
                    : { required: false }
                }
                options={states}
                register={register}
                blank
                errors={errors}
              />
              <Input
                className="col-xl"
                label="País de nascimento"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={formPrefix + '.stable_union.spouse.address.country'}
                defaultValue="BRASIL"
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIncludesStableUnion
                      }
                    : { required: false }
                }
                hasCustom
                hasErrorCustom
              />
            </div>
          </ContainerAnimation>
          <ContainerAnimation
            isShow={verifyIfCheckboxValueIsEqualToWidowed ? 'show' : 'hidden'}
            id="family_union_widowed"
          >
            <div className="row mb-5 ">
              <Input
                className="col-xl"
                label="Nome completo do cônjuge falecido"
                register={register}
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                // {...inputSpouseName}
                name={formPrefix + '.widowed.spouse.name'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToWidowed
                      }
                    : { required: false }
                }
                errors={errors}
                hasErrorCustom
                hasCustom
              />
              <Controller
                shouldUnregister={true}
                control={control}
                name={formPrefix + '.widowed.spouse.birth_date'}
                // name={datepickerSpouseDateBirth.name}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToWidowed
                      }
                    : { required: false }
                }
                render={({ field }) => (
                  <DatePickerCustom
                    popperProps={{ strategy: 'fixed' }}
                    locale="ptBR"
                    className="col-xl-3"
                    register={register}
                    inputOptions={{ classname: 'd-flex align-items-center' }}
                    label="Data de nascimento do cônjuge falecido"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/AAAA"
                    hasCustom
                    // selected={birthDate}
                    {...field}
                    rules={
                      changeTab !== 'previous'
                        ? {
                            required: verifyIfCheckboxValueIsEqualToWidowed
                          }
                        : { required: false }
                    }
                    errors={errors}
                    hasErrorCustom
                    maxDate={maxDate}
                    customInput={
                      <IMaskInput
                        mask={Date}
                        pattern={'d/m/Y'}
                        format={date => {
                          return moment(date).format(inputMaskFormat)
                        }}
                        parse={value => {
                          return moment(value, inputMaskFormat).toDate()
                        }}
                        validate={(date: string) => {
                          if (date.length === 10) {
                            if (
                              moment(date, inputMaskFormat).isAfter(moment())
                            ) {
                              return false
                            }
                            const age = moment().diff(
                              moment(date, inputMaskFormat),
                              'year'
                            )
                            if (age < 18) {
                              return false
                            }
                          }
                          return true
                        }}
                      />
                    }
                    // onChange={(e: any) => {
                    //   field.onChange(e)
                    //   setBirthDate(e)
                    // }}
                  />
                )}
              />
              <Controller
                shouldUnregister={true}
                control={control}
                name={formPrefix + '.widowed.stable_union_date'}
                // name={datepickerStableUnion.name}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToWidowed
                      }
                    : { required: false }
                }
                render={({ field }) => (
                  <DatePickerCustom
                    popperProps={{ strategy: 'fixed' }}
                    locale="ptBR"
                    className="col-xl-3"
                    register={register}
                    inputOptions={{ classname: 'd-flex align-items-center' }}
                    label="Data de casamento "
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/AAAA"
                    hasCustom
                    // selected={unionStableDate}

                    {...field}
                    errors={errors}
                    hasErrorCustom
                    customInput={
                      <IMaskInput
                        mask={Date}
                        pattern={'d/m/Y'}
                        format={date => {
                          return moment(date).format(inputMaskFormat)
                        }}
                        parse={value => {
                          return moment(value, inputMaskFormat).toDate()
                        }}
                      />
                    }
                    // onChange={(e: any) => {
                    //   field.onChange(e)
                    //   setUnionStableDate(e)
                    // }}
                  />
                )}
              />
            </div>

            <div className="row mb-5">
              <Controller
                shouldUnregister={true}
                control={control}
                name={formPrefix + '.widowed.spouse.address.zip_code'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToWidowed
                      }
                    : { required: false }
                }
                render={({ field }) => (
                  <Input
                    register={register}
                    className="col-xl-2"
                    label="CEP"
                    inputOptions={{
                      classname: 'd-flex align-items-center'
                    }}
                    hasErrorCustom
                    // name={formPrefix + '.' + "address.zip_code"}
                    mask={{
                      mask: INPUT_MASK.ZIP_CODE,
                      type: 'zipCode',
                      prefixInputsData: formPrefix + '.widowed.spouse.address.',
                      customFields: [
                        {
                          name: 'state',
                          type: 'state',
                          api_field: 'uf'
                        }
                      ]
                    }}
                    rules={
                      changeTab !== 'previous'
                        ? {
                            required: verifyIfCheckboxValueIsEqualToWidowed
                          }
                        : { required: false }
                    }
                    maxLength={10}
                    hasCustom
                    {...field}
                    errors={errors}
                  />
                )}
              />
              <Input
                register={register}
                className="col-xl"
                label="Rua, nº, complemento de nascimento"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={formPrefix + '.widowed.spouse.address.street'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToWidowed
                      }
                    : { required: false }
                }
                errors={errors}
                hasErrorCustom
                hasCustom
              />
              {/* <Input
                register={register}
                className="col-xl-2"
                label="Número "
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={formPrefix + '.widowed.spouse.address.number'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToWidowed
                      }
                    : { required: false }
                }
                hasErrorCustom
                hasCustom
                errors={errors}
              /> */}
            </div>
            <div className="row mb-5">
              <Input
                register={register}
                className="col-xl"
                label="Bairro de nascimento"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={formPrefix + '.widowed.spouse.address.district'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToWidowed
                      }
                    : { required: false }
                }
                hasErrorCustom
                hasCustom
                errors={errors}
              />
              <Input
                register={register}
                className="col-xl"
                label="Cidade de nascimento"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={formPrefix + '.widowed.spouse.address.city'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToWidowed
                      }
                    : { required: false }
                }
                hasErrorCustom
                hasCustom
                errors={errors}
              />
              <Select
                className="col-xl"
                label="Estado de nascimento"
                labelOptions={{ classname: 'col-xl' }}
                selectOptions={{
                  classname: 'col-xl'
                }}
                name={formPrefix + '.widowed.spouse.address.state'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToWidowed
                      }
                    : { required: false }
                }
                options={states}
                register={register}
                blank
                errors={errors}
              />
              <Input
                className="col-xl"
                label="País de nascimento"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={formPrefix + '.widowed.spouse.address.country'}
                defaultValue="BRASIL"
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToWidowed
                      }
                    : { required: false }
                }
                hasCustom
                hasErrorCustom
              />
            </div>
          </ContainerAnimation>
          <ContainerAnimation
            isShow={verifyIfCheckboxValueIsEqualToDivorced ? 'show' : 'hidden'}
            id="family_union_divorced"
          >
            <div className="row mb-5 ">
              <Input
                className="col-xl"
                label="Nome completo do(a) ex"
                register={register}
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={formPrefix + '.divorced.spouse.name'}
                // {...inputSpouseName}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToDivorced
                      }
                    : { required: false }
                }
                errors={errors}
                hasErrorCustom
                hasCustom
              />
              <Controller
                shouldUnregister={true}
                control={control}
                name={formPrefix + '.divorced.spouse.birth_date'}
                // name={datepickerSpouseDateBirth.name}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToDivorced
                      }
                    : { required: false }
                }
                render={({ field }) => (
                  <DatePickerCustom
                    popperProps={{ strategy: 'fixed' }}
                    locale="ptBR"
                    className="col-xl"
                    register={register}
                    inputOptions={{ classname: 'd-flex align-items-center' }}
                    label="Data de nascimento do(a) ex"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/AAAA"
                    hasCustom
                    // selected={birthDate}
                    {...field}
                    rules={
                      changeTab !== 'previous'
                        ? {
                            required: verifyIfCheckboxValueIsEqualToDivorced
                          }
                        : { required: false }
                    }
                    errors={errors}
                    hasErrorCustom
                    maxDate={maxDate}
                    customInput={
                      <IMaskInput
                        mask={Date}
                        pattern={'d/m/Y'}
                        format={date => {
                          return moment(date).format(inputMaskFormat)
                        }}
                        parse={value => {
                          return moment(value, inputMaskFormat).toDate()
                        }}
                        validate={(date: string) => {
                          if (date.length === 10) {
                            if (
                              moment(date, inputMaskFormat).isAfter(moment())
                            ) {
                              return false
                            }
                            const age = moment().diff(
                              moment(date, inputMaskFormat),
                              'year'
                            )
                            if (age < 18) {
                              return false
                            }
                          }
                          return true
                        }}
                      />
                    }
                  />
                )}
              />
            </div>
            <div className="row mb-5">
              <Controller
                shouldUnregister={true}
                control={control}
                name={formPrefix + '.divorced.spouse.address.zip_code'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToDivorced
                      }
                    : { required: false }
                }
                render={({ field }) => (
                  <Input
                    register={register}
                    className="col-xl-2"
                    label="CEP"
                    inputOptions={{
                      classname: 'd-flex align-items-center'
                    }}
                    hasErrorCustom
                    // name={formPrefix + '.' + "address.zip_code"}
                    mask={{
                      mask: INPUT_MASK.ZIP_CODE,
                      type: 'zipCode',
                      prefixInputsData:
                        formPrefix + '.divorced.spouse.address.',
                      customFields: [
                        {
                          name: 'state',
                          type: 'state',
                          api_field: 'uf'
                        }
                      ]
                    }}
                    rules={
                      changeTab !== 'previous'
                        ? {
                            required: verifyIfCheckboxValueIsEqualToDivorced
                          }
                        : { required: false }
                    }
                    maxLength={10}
                    hasCustom
                    {...field}
                    errors={errors}
                  />
                )}
              />
              <Input
                register={register}
                className="col-xl"
                label="Rua, nº, complemento de nascimento"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={formPrefix + '.divorced.spouse.address.street'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToDivorced
                      }
                    : { required: false }
                }
                errors={errors}
                hasErrorCustom
                hasCustom
              />
              {/* <Input
                register={register}
                className="col-xl-2"
                label="Número "
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={formPrefix + '.divorced.spouse.address.number'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToDivorced
                      }
                    : { required: false }
                }
                hasErrorCustom
                hasCustom
                errors={errors}
              /> */}
            </div>
            <div className="row mb-5">
              <Input
                register={register}
                className="col-xl"
                label="Bairro de nascimento"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={formPrefix + '.divorced.spouse.address.district'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToDivorced
                      }
                    : { required: false }
                }
                hasErrorCustom
                hasCustom
                errors={errors}
              />
              <Input
                register={register}
                className="col-xl"
                label="Cidade de nascimento"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={formPrefix + '.divorced.spouse.address.city'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToDivorced
                      }
                    : { required: false }
                }
                hasErrorCustom
                hasCustom
                errors={errors}
              />
              <Select
                className="col-xl"
                label="Estado de nascimento"
                labelOptions={{ classname: 'col-xl' }}
                selectOptions={{
                  classname: 'col-xl'
                }}
                name={formPrefix + '.divorced.spouse.address.state'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToDivorced
                      }
                    : { required: false }
                }
                options={states}
                register={register}
                blank
                errors={errors}
              />
              <Input
                className="col-xl"
                label="País de nascimento"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={formPrefix + '.divorced.spouse.address.country'}
                defaultValue="BRASIL"
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToDivorced
                      }
                    : { required: false }
                }
                hasCustom
                hasErrorCustom
              />
            </div>

            <div className="row mb-5 ">
              <Controller
                shouldUnregister={true}
                control={control}
                name={formPrefix + '.divorced.stable_union_date'}
                // name={datepickerStableUnion.name}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToDivorced
                      }
                    : { required: false }
                }
                render={({ field }) => (
                  <DatePickerCustom
                    popperProps={{ strategy: 'fixed' }}
                    locale="ptBR"
                    className="col-xl"
                    register={register}
                    inputOptions={{ classname: 'd-flex align-items-center' }}
                    label="Data de casamento "
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/AAAA"
                    hasCustom
                    // selected={unionStableDate}

                    {...field}
                    errors={errors}
                    hasErrorCustom
                    customInput={
                      <IMaskInput
                        mask={Date}
                        pattern={'d/m/Y'}
                        format={date => {
                          return moment(date).format(inputMaskFormat)
                        }}
                        parse={value => {
                          return moment(value, inputMaskFormat).toDate()
                        }}
                      />
                    }
                    // onChange={(e: any) => {
                    //   field.onChange(e)
                    //   setUnionStableDate(e)
                    // }}
                  />
                )}
              />
              <Controller
                shouldUnregister={true}
                control={control}
                name={formPrefix + '.divorced.divorced_date'}
                // name={datepickerStableUnion.name}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToDivorced
                      }
                    : { required: false }
                }
                render={({ field }) => (
                  <DatePickerCustom
                    popperProps={{ strategy: 'fixed' }}
                    locale="ptBR"
                    className="col-xl"
                    register={register}
                    inputOptions={{ classname: 'd-flex align-items-center' }}
                    label="Data do divorcio"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/AAAA"
                    hasCustom
                    // selected={unionStableDate}

                    {...field}
                    errors={errors}
                    hasErrorCustom
                    customInput={
                      <IMaskInput
                        mask={Date}
                        pattern={'d/m/Y'}
                        format={date => {
                          return moment(date).format(inputMaskFormat)
                        }}
                        parse={value => {
                          return moment(value, inputMaskFormat).toDate()
                        }}
                      />
                    }
                    // onChange={(e: any) => {
                    //   field.onChange(e)
                    //   setUnionStableDate(e)
                    // }}
                  />
                )}
              />
            </div>
            {/* <div className="row mb-5 ">

            </div> */}
            <div className="row mb-5 ">
              <Textarea
                label="Descreva como aconteceu o divorcio"
                className="col-xl"
                register={register}
                hasCustom
                // inputOptions={{
                //   classname: 'd-flex align-items-center'
                // }}
                style={{ minHeight: 100 }}
                // {...textExplain}
                name={formPrefix + '.divorced.explain'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToDivorced
                      }
                    : { required: false }
                }
                // errors={errors}
                hasErrorCustom
              />
            </div>
          </ContainerAnimation>
          <ContainerAnimation
            isShow={
              verifyIfCheckboxValueIsEqualToLegallySeparated ? 'show' : 'hidden'
            }
            id="family_union_legally_separated"
          >
            <div className="row mb-5 ">
              <Input
                className="col-xl"
                label="Nome completo do(a) ex"
                register={register}
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                // {...inputSpouseName}
                name={formPrefix + '.legally_separated.' + spouseName}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToLegallySeparated
                      }
                    : { required: false }
                }
                errors={errors}
                hasErrorCustom
                hasCustom
              />
              <Controller
                shouldUnregister={true}
                control={control}
                name={formPrefix + '.legally_separated.' + spouseBirthDate}
                // name={datepickerSpouseDateBirth.name}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToLegallySeparated
                      }
                    : { required: false }
                }
                render={({ field }) => (
                  <DatePickerCustom
                    popperProps={{ strategy: 'fixed' }}
                    locale="ptBR"
                    className="col-xl"
                    register={register}
                    inputOptions={{ classname: 'd-flex align-items-center' }}
                    label="Data de nascimento do(a) ex"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/AAAA"
                    hasCustom
                    // selected={birthDate}
                    {...field}
                    rules={
                      changeTab !== 'previous'
                        ? {
                            required:
                              verifyIfCheckboxValueIsEqualToLegallySeparated
                          }
                        : { required: false }
                    }
                    errors={errors}
                    hasErrorCustom
                    maxDate={maxDate}
                    customInput={
                      <IMaskInput
                        mask={Date}
                        pattern={'d/m/Y'}
                        format={date => {
                          return moment(date).format(inputMaskFormat)
                        }}
                        parse={value => {
                          return moment(value, inputMaskFormat).toDate()
                        }}
                        validate={(date: string) => {
                          if (date.length === 10) {
                            if (
                              moment(date, inputMaskFormat).isAfter(moment())
                            ) {
                              return false
                            }
                            const age = moment().diff(
                              moment(date, inputMaskFormat),
                              'year'
                            )
                            if (age < 18) {
                              return false
                            }
                          }
                          return true
                        }}
                      />
                    }
                    // onChange={(e: any) => {
                    //   field.onChange(e)
                    //   setBirthDate(e)
                    // }}
                  />
                )}
              />
            </div>
            <div className="row mb-5">
              <Controller
                shouldUnregister={true}
                control={control}
                name={formPrefix + '.legally_separated.spouse.address.zip_code'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToLegallySeparated
                      }
                    : { required: false }
                }
                render={({ field }) => (
                  <Input
                    register={register}
                    className="col-xl-2"
                    label="CEP"
                    inputOptions={{
                      classname: 'd-flex align-items-center'
                    }}
                    hasErrorCustom
                    // name={formPrefix + '.' + "address.zip_code"}
                    mask={{
                      mask: INPUT_MASK.ZIP_CODE,
                      type: 'zipCode',
                      prefixInputsData:
                        formPrefix + '.legally_separated.spouse.address.',
                      customFields: [
                        {
                          name: 'state',
                          type: 'state',
                          api_field: 'uf'
                        }
                      ]
                    }}
                    rules={
                      changeTab !== 'previous'
                        ? {
                            required:
                              verifyIfCheckboxValueIsEqualToLegallySeparated
                          }
                        : { required: false }
                    }
                    maxLength={10}
                    hasCustom
                    {...field}
                    errors={errors}
                  />
                )}
              />
              <Input
                register={register}
                className="col-xl"
                label="Rua, nº, complemento de nascimento"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={formPrefix + '.legally_separated.spouse.address.street'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToLegallySeparated
                      }
                    : { required: false }
                }
                errors={errors}
                hasErrorCustom
                hasCustom
              />
              {/* <Input
                register={register}
                className="col-xl-2"
                label="Número "
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={formPrefix + '.legally_separated.spouse.address.number'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToLegallySeparated
                      }
                    : { required: false }
                }
                hasErrorCustom
                hasCustom
                errors={errors}
              /> */}
            </div>
            <div className="row mb-5">
              <Input
                register={register}
                className="col-xl"
                label="Bairro de nascimento"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={formPrefix + '.legally_separated.spouse.address.district'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToLegallySeparated
                      }
                    : { required: false }
                }
                hasErrorCustom
                hasCustom
                errors={errors}
              />
              <Input
                register={register}
                className="col-xl"
                label="Cidade de nascimento"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={formPrefix + '.legally_separated.spouse.address.city'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToLegallySeparated
                      }
                    : { required: false }
                }
                hasErrorCustom
                hasCustom
                errors={errors}
              />
              <Select
                className="col-xl"
                label="Estado de nascimento"
                labelOptions={{ classname: 'col-xl' }}
                selectOptions={{
                  classname: 'col-xl'
                }}
                name={formPrefix + '.legally_separated.spouse.address.state'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToLegallySeparated
                      }
                    : { required: false }
                }
                options={states}
                register={register}
                blank
                errors={errors}
              />
              <Input
                className="col-xl"
                label="País de nascimento"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={formPrefix + '.legally_separated.spouse.address.country'}
                defaultValue="BRASIL"
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToLegallySeparated
                      }
                    : { required: false }
                }
                hasCustom
                hasErrorCustom
              />
            </div>
            {/* <div className="row mb-5 ">
              <Input
                className="col-xl"
                label="Cidade de nascimento do(a) ex"
                register={register}
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                // {...inputSpouseCityBirth}
                name={formPrefix + '.legally_separated.' + spouseCityBirth}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToLegallySeparated
                      }
                    : { required: false }
                }
                errors={errors}
                hasErrorCustom
                hasCustom
              />
            </div> */}
            <div className="row mb-5 ">
              <Controller
                shouldUnregister={true}
                control={control}
                name={formPrefix + '.legally_separated.' + stableUnion}
                render={({ field }) => (
                  <DatePickerCustom
                    popperProps={{ strategy: 'fixed' }}
                    locale="ptBR"
                    className="col-xl"
                    register={register}
                    inputOptions={{ classname: 'd-flex align-items-center' }}
                    label="Data de casamento "
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/AAAA"
                    hasCustom
                    // selected={unionStableDate}

                    {...field}
                    rules={
                      changeTab !== 'previous'
                        ? {
                            required:
                              verifyIfCheckboxValueIsEqualToLegallySeparated
                          }
                        : { required: false }
                    }
                    errors={errors}
                    hasErrorCustom
                    customInput={
                      <IMaskInput
                        mask={Date}
                        pattern={'d/m/Y'}
                        format={date => {
                          return moment(date).format(inputMaskFormat)
                        }}
                        parse={value => {
                          return moment(value, inputMaskFormat).toDate()
                        }}
                      />
                    }
                  />
                )}
              />
              <Controller
                shouldUnregister={true}
                control={control}
                name={formPrefix + '.legally_separated.separated_date'}
                // name={datepickerStableUnion.name}
                render={({ field }) => (
                  <DatePickerCustom
                    popperProps={{ strategy: 'fixed' }}
                    locale="ptBR"
                    className="col-xl"
                    register={register}
                    inputOptions={{ classname: 'd-flex align-items-center' }}
                    label="Data da separação"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/AAAA"
                    hasCustom
                    // selected={unionStableDate}

                    {...field}
                    rules={
                      changeTab !== 'previous'
                        ? {
                            required:
                              verifyIfCheckboxValueIsEqualToLegallySeparated
                          }
                        : { required: false }
                    }
                    errors={errors}
                    hasErrorCustom
                    customInput={
                      <IMaskInput
                        mask={Date}
                        pattern={'d/m/Y'}
                        format={date => {
                          return moment(date).format(inputMaskFormat)
                        }}
                        parse={value => {
                          return moment(value, inputMaskFormat).toDate()
                        }}
                      />
                    }
                    // onChange={(e: any) => {
                    //   field.onChange(e)
                    //   setUnionStableDate(e)
                    // }}
                  />
                )}
              />
            </div>
            {/* <div className="row mb-5 ">

            </div> */}
            <div className="row mb-5 ">
              <Textarea
                // hasCustom
                label="Descreva como aconteceu a separação"
                className="col-xl"
                register={register}
                // inputOptions={{
                //   classname: 'd-flex align-items-center'
                // }}
                style={{ minHeight: 100 }}
                // {...textExplain}
                name={formPrefix + '.legally_separated.explain'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: verifyIfCheckboxValueIsEqualToLegallySeparated
                      }
                    : { required: false }
                }
                // errors={errors}
                hasErrorCustom
              />
            </div>
          </ContainerAnimation>
        </div>
      )}
    </div>
  )
}
