export const CLIENT_STATUS_ENUM: Record<string, string> = {
  approved: 'Aprovado',
  refused: 'Reprovado'
}
export const CLIENT_STATUS_ENUM_OPTIONS = Object.entries(
  CLIENT_STATUS_ENUM
).map(([key, value]) => ({
  name: value,
  value: key
}))
