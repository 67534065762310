import { ToolsContainerProps } from '../../../../../../components/Container'
import { nameActions } from '../../info'
export const toolsViewCreate = (): ToolsContainerProps => ({
  name: 'Adicionar',
  to: nameActions.create.to,
  icon: nameActions.create.icon,
  color: 'btn-light-success'
})

export const toolsViewList = (): ToolsContainerProps => ({
  name: 'Listar',
  to: nameActions.read.to,
  icon: nameActions.read.icon
})

export const toolsViewListContracts = (): ToolsContainerProps => ({
  name: 'Listar',
  to: nameActions.read.to,
  icon: nameActions.read.icon
})

export const toolsViewUpdate = (id: string): ToolsContainerProps => ({
  name: 'Editar',
  to: `${nameActions.update.to}${id}`,
  icon: nameActions.update.icon,
  color: 'btn-light-warning'
})

export const toolsViewDelete = (
  handleOnClick: (pamams: any) => void
): ToolsContainerProps => ({
  name: 'Remover',
  to: nameActions.delete.to,
  icon: nameActions.delete.icon,
  color: 'btn-light-danger',
  handleOnClick
})

export const toolsViewFinalInformation = (
  id: number | string,
  callbackUrl: number | string
): ToolsContainerProps => {
  let url = `/commercial/contracts/finalInformation/${id}`
  if (callbackUrl) {
    url += `?callback_url=${callbackUrl}`
  }
  return {
    name: 'Inf. Finais',
    to: url,
    icon: 'fa fa-address-card'
  }
}

export const toolsViewCancelled = (
  handleOnClick: () => Promise<void>
): ToolsContainerProps => ({
  name: 'Cancelar',
  to: nameActions.delete.to,
  icon: nameActions.delete.icon,
  color: 'btn-light-danger',
  handleOnClick
})
