import React from 'react'
import ServiceCategoriesList from '../../pages/Commercial/ServiceCategories/List'
import ServiceCategoriesCreate from '../../pages/Commercial/ServiceCategories/Create'
import ServiceCategoriesUpdate from '../../pages/Commercial/ServiceCategories/Update'
import ServiceCategoriesView from '../../pages/Commercial/ServiceCategories/View'
import PrivateRoute from '../PrivateRoute'
import { CustomSwitch } from '../../components/CustomSwitch'

export const ServiceCategoriesRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoute
      path="/commercial/serviceCategories"
      exact
      component={ServiceCategoriesList}
    />
    <PrivateRoute
      path="/commercial/serviceCategories/create"
      exact
      component={ServiceCategoriesCreate}
    />
    <PrivateRoute
      path="/commercial/serviceCategories/view/:id"
      exact
      component={ServiceCategoriesView}
    />
    <PrivateRoute
      path="/commercial/serviceCategories/update/:id"
      exact
      component={ServiceCategoriesUpdate}
    />
  </CustomSwitch>
)
