import React, { useCallback, useEffect, useState } from 'react'
import { CardSimple } from '../../components/CardSimple'
import { PageTitle } from '../../assets/layout/core'
import {
  faArrowCircleRight,
  faCheck,
  faFile,
  faHourglass,
  faCheckCircle,
  faPaperPlane,
  faEdit,
  faCalendar,
  faCalendarCheck,
  faArrowAltCircleRight,
  faDollarSign
} from '@fortawesome/free-solid-svg-icons'
import api from '../../services/api'
import { useToast } from '../../hooks/toast'
import { useLoading } from '../../hooks/loading'

import { Loading } from '../../components/Loading'
import { PHASE_PROCESS } from '../../common/constants/phase-process'
import { convertToBase64 } from '../../utils/convertToBase64'

export const CARD_NAMES = {
  CONTRACTS_IN_PROGRESS: 'Em andamento',
  AWAITING_PAYMENT: 'Em aberto',
  FORM_STARTED: 'Processos iniciados',
  PROCESS_NOT_STARTED: 'Processos não iniciado',
  AWAITING_ANALYSIS: 'Aguardando analise',
  NEED_FIXES: 'Precisa de correção',
  FINISHED_FIXES: 'Correções realizadas',
  READY_FOR_APPLICATION: 'Pronto para Aplicação',
  TAXES: 'Taxas',
  TO_SCHEDULE: 'Agendar',
  GUIDE: {
    TEXT: 'Agendados',
    ID: 11,
    CALLBACK: `/commercial/contracts/forms?status=${PHASE_PROCESS.GUIDE}&title=Agendados&title_id=11`
  },
  IN_CONSULATE: 'No Consulado',
  ACCESSORY_COMPLETED: 'Acessória concluída',
  END: 'Final'
}

export const TITLES_ENUM: Record<string | number, string> = {
  11: CARD_NAMES.GUIDE.CALLBACK
}

export const Dashboard: React.FC = () => {
  const { addToast } = useToast()

  const { activeLoading, disableLoading, loading } = useLoading()

  const [contractInProgress, setContractInProgress] = useState({
    quantity: '0',
    value: '0',
    ids: ''
  })

  const [contractAwaitingPayment, setContractAwaitingPayment] = useState({
    quantity: '0',
    value: '0'
  })

  const [formNotStarted, setFormNotStarted] = useState({
    quantity: '0',
    value: '0'
  })

  const [stopProcess, setStopProcess] = useState({
    quantity: '0',
    value: '0'
  })

  const [consultancyCompleted, setConsultancyCompleted] = useState({
    quantity: '0',
    value: '0'
  })

  const [formAwaitingAnalysis, setFormAwaitingAnalysis] = useState({
    quantity: '0',
    value: '0'
  })
  const [formNeedFixes, setFormNeedFixes] = useState({
    quantity: '0',
    value: '0'
  })
  const [formFinishFixes, setFormFinishFixes] = useState({
    quantity: '0',
    value: '0'
  })
  const [formReadyToApplication, setFormReadyToApplication] = useState({
    quantity: '0',
    value: '0'
  })
  const [formToSchedule, setFormToSchedule] = useState({
    quantity: '0',
    value: '0'
  })
  const [formToTax, setFormToTax] = useState({
    quantity: '0',
    value: '0'
  })
  const [formGuide, setFormGuide] = useState({
    quantity: '0',
    value: '0'
  })
  const [formsInConsulate, setFormsInConsulate] = useState({
    quantity: '0',
    value: '0'
  })
  const [formsStarted, setFormsStarted] = useState({
    quantity: '0',
    value: '0'
  })
  const [
    formsApprovedAndReprovedVisasStatus,
    setFormsApprovedAndReprovedVisasStatus
  ] = useState({
    quantity: '0',
    value: '0'
  })

  const loadDashboardData = useCallback(async () => {
    activeLoading()
    try {
      const responseReportDashboard = await api.post<ResponseReportDashboard>(
        '/commercial/contracts/reportDashboard'
      )
      setFormsStarted({
        quantity: responseReportDashboard.data?.formsStarted?.toString() || '0',
        value: '0'
      })
      setFormsApprovedAndReprovedVisasStatus({
        quantity:
          responseReportDashboard.data?.formsApprovedAndReprovedVisasStatus?.toString() ||
          '0',
        value: '0'
      })
      setFormsInConsulate({
        quantity:
          responseReportDashboard.data?.formsInConsulate?.toString() || '0',
        value: '0'
      })
      setContractInProgress({
        quantity:
          responseReportDashboard.data?.contractsWithFormsInProgress?.toString() ||
          '0',
        value: '0',
        ids: responseReportDashboard.data?.contractsWithFormsInProgressIds
          ?.map?.(item => item.id)
          .join(',')
      })
      setContractAwaitingPayment({
        quantity:
          responseReportDashboard.data?.awaitingPayment?.toString() || '0',
        value: '0'
      })
      setFormToSchedule({
        quantity:
          responseReportDashboard.data?.formToSchedule?.toString() || '0',
        value: '0'
      })
      setFormGuide({
        quantity: responseReportDashboard.data?.formGuide?.toString() || '0',
        value: '0'
      })
      setFormNotStarted({
        quantity:
          responseReportDashboard.data?.formNotStarted?.toString() || '0',
        value: '0'
      })
      setStopProcess({
        quantity:
          responseReportDashboard.data?.formsStopProcess?.toString() || '0',
        value: '0'
      })
      setConsultancyCompleted({
        quantity:
          responseReportDashboard.data?.formsConsultancyCompleted?.toString() ||
          '0',
        value: '0'
      })
      setFormAwaitingAnalysis({
        quantity:
          responseReportDashboard.data?.formsAwaitingAnalysis?.toString() ||
          '0',
        value: '0'
      })
      setFormNeedFixes({
        quantity:
          responseReportDashboard.data?.formNeedFixes?.toString() || '0',
        value: '0'
      })
      setFormFinishFixes({
        quantity:
          responseReportDashboard.data?.formFinishFixes?.toString() || '0',
        value: '0'
      })
      setFormReadyToApplication({
        quantity:
          responseReportDashboard.data?.formReadyToApplication?.toString() ||
          '0',
        value: '0'
      })
      setFormToTax({
        quantity: responseReportDashboard.data?.formTax?.toString() || '0',
        value: '0'
      })
    } catch (error) {
      console.log(error)
      addToast({
        type: 'error',
        title: 'Erro ao carregar os registros',
        description:
          'Ocorreu um erro ao carregar os registros, por favor, tente novamente.'
      })
    }
    disableLoading()
  }, [activeLoading, addToast, disableLoading])

  useEffect(() => {
    loadDashboardData()
  }, [loadDashboardData])

  return loading ? (
    <Loading isActive={loading} />
  ) : (
    <div className="container-fluid">
      <PageTitle>Painel de Controle</PageTitle>
      <div className="row gy-5 g-xl-8">
        <div className="col-md-4">
          <CardSimple
            primaryColor="dark"
            text={CARD_NAMES.CONTRACTS_IN_PROGRESS}
            number={contractInProgress.quantity}
            footer={{
              link: `/commercial/contracts?ids=${convertToBase64(
                contractInProgress.ids
              )}&title=${CARD_NAMES.CONTRACTS_IN_PROGRESS}`,
              text: 'Mais Detalhes',
              iconSettings: {
                icon: faArrowAltCircleRight,
                style: {
                  size: 'lg'
                }
              }
            }}
            iconSettings={{
              icon: faDollarSign
            }}
          />
        </div>
        <div className="col-md-4">
          <CardSimple
            primaryColor="dark"
            text={CARD_NAMES.AWAITING_PAYMENT}
            number={contractAwaitingPayment.quantity}
            footer={{
              link: `/commercial/contracts?status=awaiting_payment&title=${CARD_NAMES.AWAITING_PAYMENT}`,
              text: 'Mais Detalhes',
              iconSettings: {
                icon: faArrowAltCircleRight,
                style: {
                  size: 'lg'
                }
              }
            }}
            iconSettings={{
              icon: faDollarSign
            }}
          />
        </div>
        <div className="col-md-4">
          <CardSimple
            primaryColor="danger"
            text={CARD_NAMES.PROCESS_NOT_STARTED}
            number={formNotStarted.quantity}
            footer={{
              link: `/commercial/contracts/forms?status=3&title=${CARD_NAMES.PROCESS_NOT_STARTED}`,
              text: 'Mais Detalhes',
              iconSettings: {
                icon: faArrowCircleRight,
                style: {
                  size: 'lg'
                }
              }
            }}
            iconSettings={{
              icon: faFile
            }}
          />
        </div>

        <div className="col-md-4">
          <CardSimple
            primaryColor="danger"
            text={CARD_NAMES.FORM_STARTED}
            number={formsStarted.quantity}
            footer={{
              link: `/commercial/contracts/forms?status=${PHASE_PROCESS.FILL_STARTED}&title=${CARD_NAMES.FORM_STARTED}`,
              text: 'Mais Detalhes',
              iconSettings: {
                icon: faArrowCircleRight,
                style: {
                  size: 'lg'
                }
              }
            }}
            iconSettings={{
              icon: faHourglass
            }}
          />
        </div>
        <div className="col-md-4">
          <CardSimple
            primaryColor="danger"
            text={CARD_NAMES.AWAITING_ANALYSIS}
            number={formAwaitingAnalysis.quantity}
            footer={{
              link: `/commercial/contracts/forms?status=2&title=${CARD_NAMES.AWAITING_ANALYSIS}`,
              text: 'Mais Detalhes',
              iconSettings: {
                icon: faArrowCircleRight,
                style: {
                  size: 'lg'
                }
              }
            }}
            iconSettings={{
              icon: faHourglass
            }}
          />
        </div>

        <div className="col-md-4">
          <CardSimple
            primaryColor="danger"
            text={CARD_NAMES.NEED_FIXES}
            number={formNeedFixes.quantity}
            footer={{
              link: `/commercial/contracts/forms?status=33&title=${CARD_NAMES.NEED_FIXES}`,
              text: 'Mais Detalhes',
              iconSettings: {
                icon: faArrowCircleRight,
                style: {
                  size: 'lg'
                }
              }
            }}
            iconSettings={{
              icon: faEdit
            }}
          />
        </div>

        <div className="col-md-4">
          <CardSimple
            primaryColor="primary"
            text={CARD_NAMES.FINISHED_FIXES}
            number={formFinishFixes.quantity}
            footer={{
              link: `/commercial/contracts/forms?status=37&title=${CARD_NAMES.FINISHED_FIXES}`,
              text: 'Mais Detalhes',
              iconSettings: {
                icon: faArrowCircleRight,
                style: {
                  size: 'lg'
                }
              }
            }}
            iconSettings={{
              icon: faCheckCircle
            }}
          />
        </div>

        <div className="col-md-4">
          <CardSimple
            primaryColor="primary"
            text={CARD_NAMES.READY_FOR_APPLICATION}
            number={formReadyToApplication.quantity}
            footer={{
              link: `/commercial/contracts/forms?status=38&title=${CARD_NAMES.READY_FOR_APPLICATION}`,
              text: 'Mais Detalhes',
              iconSettings: {
                icon: faArrowCircleRight,
                style: {
                  size: 'lg'
                }
              }
            }}
            iconSettings={{
              icon: faPaperPlane
            }}
          />
        </div>

        <div className="col-md-4">
          <CardSimple
            primaryColor="primary"
            text={CARD_NAMES.TAXES}
            number={formToTax.quantity}
            footer={{
              link: `/commercial/contracts/forms?status=${PHASE_PROCESS.TAX}&title=${CARD_NAMES.TAXES}`,
              text: 'Mais Detalhes',
              iconSettings: {
                icon: faArrowCircleRight,
                style: {
                  size: 'lg'
                }
              }
            }}
            iconSettings={{
              icon: faCalendar
            }}
          />
        </div>
        <div className="col-md-4">
          <CardSimple
            primaryColor="primary"
            text={CARD_NAMES.TO_SCHEDULE}
            number={formToSchedule.quantity}
            footer={{
              link: `/commercial/contracts/forms?status=${PHASE_PROCESS.TO_SCHEDULE}&title=${CARD_NAMES.TO_SCHEDULE}`,
              text: 'Mais Detalhes',
              iconSettings: {
                icon: faArrowCircleRight,
                style: {
                  size: 'lg'
                }
              }
            }}
            iconSettings={{
              icon: faCalendar
            }}
          />
        </div>
        <div className="col-md-4">
          <CardSimple
            primaryColor="primary"
            text={CARD_NAMES.GUIDE.TEXT}
            number={formGuide.quantity}
            footer={{
              link: `/commercial/contracts/forms?status=${PHASE_PROCESS.GUIDE}&title=${CARD_NAMES.GUIDE.TEXT}&title_id=${CARD_NAMES.GUIDE.ID}`,
              text: 'Mais Detalhes',
              iconSettings: {
                icon: faArrowCircleRight,
                style: {
                  size: 'lg'
                }
              }
            }}
            iconSettings={{
              icon: faCalendarCheck
            }}
          />
        </div>

        <div className="col-md-4">
          <CardSimple
            primaryColor="primary"
            text={CARD_NAMES.IN_CONSULATE}
            number={formsInConsulate.quantity}
            footer={{
              link: `/commercial/contracts/forms?status=${PHASE_PROCESS.PROCESS_FINISHED}&title=${CARD_NAMES.IN_CONSULATE}`,
              text: 'Mais Detalhes',
              iconSettings: {
                icon: faArrowCircleRight,
                style: {
                  size: 'lg'
                }
              }
            }}
            iconSettings={{
              icon: faCheck
            }}
          />
        </div>
        <div className="col-md-4">
          <CardSimple
            primaryColor="primary"
            text={CARD_NAMES.ACCESSORY_COMPLETED}
            number={consultancyCompleted.quantity}
            footer={{
              link: `/commercial/contracts/forms?status=35&title=${CARD_NAMES.ACCESSORY_COMPLETED}`,
              text: 'Mais Detalhes',
              iconSettings: {
                icon: faArrowCircleRight,
                style: {
                  size: 'lg'
                }
              }
            }}
            iconSettings={{
              icon: faCheck
            }}
          />
        </div>
        <div className="col-md-4">
          <CardSimple
            primaryColor="dark"
            text={CARD_NAMES.END}
            number={formsApprovedAndReprovedVisasStatus.quantity}
            footer={{
              link: `/commercial/contracts/forms?visas_status="approved,refused"&title=${CARD_NAMES.END}`,
              text: 'Mais Detalhes',
              iconSettings: {
                icon: faArrowCircleRight,
                style: {
                  size: 'lg'
                }
              }
            }}
            iconSettings={{
              icon: faCheck
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Dashboard
