import React from 'react'
import DataTable from '../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import Container from '../../../../components/Container'

import { INPUT_MASK, SAVE_FORM_DATA } from '../../../../common/constants'
import { convertToBase64 } from '../../../../utils/convertToBase64'
import { useAuth } from '../../../../hooks/auth'
import moment from 'moment'
const ClientList: React.FC = () => {
  const { user } = useAuth()
  return (
    <>
      <Container pageTitle="Listagem" breadcrumb={breadcrumbList} tools={[]}>
        <DataTable
          source={nameSource}
          entity={nameEntity}
          format={{ orderBy: 'id', orderBySort: 'DESC' }}
          searchField="name"
          headers={headers}
          notHasChildren
          customHeaders={[
            {
              name: 'Criado em',
              field: 'created_at',
              sortable: false,
              element: item => {
                return (
                  <p>
                    {moment(item?.created_at).format(
                      INPUT_MASK.DATE_LOCALE_WITH_TIME
                    )}
                  </p>
                )
              }
            }
          ]}
          actions={[
            {
              name: 'Adicionar ajuda',
              title: 'Adicionar ajuda',
              spanIcon: 'fa fa-edit',
              onClick: (item: any) => {
                localStorage.removeItem(SAVE_FORM_DATA)
                const convertedObject = convertToBase64(
                  JSON.stringify({
                    form_id: item.id,
                    user_type: 'admin-helpers',
                    url_callback: `${process.env.REACT_APP_URL}/commercial/formTypes/`,
                    token: user.token
                  })
                )
                const a = document.createElement('a')
                a.href = `${process.env.REACT_APP_FORMS_SERVICE}/${item.link}?pass=${convertedObject}`
                a.click()
                a.remove()
              }
            }
          ]}
        />
      </Container>
    </>
  )
}

export default ClientList
