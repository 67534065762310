import React, { useCallback, useEffect, useState } from 'react'
import DataTable from '../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import Container from '../../../../components/Container'
import moment from 'moment'
import { INPUT_MASK } from '../../../../common/constants'
import { toolsList } from '../domain/tools'
import api from '../../../../services/api'
import { Select } from '../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'
import { Loading } from '../../../../components/Loading'
const ClientsList: React.FC = () => {
  const history = useHistory()
  const [activeQuery] = useQueryParam('active', StringParam)
  const [searchParameters, setSearchParameters] = useState<any[]>(undefined)
  const handleExport = useCallback(async () => {
    let getUrl = '/commercial/clients/export?'
    if (activeQuery) {
      getUrl += `active=${activeQuery}`
    }
    try {
      const response = await api.get(getUrl)
      const blob = new Blob(['\ufeff' + response.data], {
        type: 'text/csv;charset=utf-8"'
      })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.download = 'relatório-contratacoes.csv'
      a.href = url
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    } catch (error) {}
  }, [activeQuery])

  const handleSetActiveFilter = useCallback(
    (active: number) => {
      history.replace(`/commercial/clients?active=${active}`)
    },
    [history]
  )

  const handleClearFilter = useCallback(async () => {
    history.replace('/commercial/clients')
  }, [history])

  useEffect(() => {
    if (!activeQuery) {
      setSearchParameters([])
      return
    }
    setSearchParameters([
      {
        active: activeQuery
      }
    ])
  }, [activeQuery])

  if (!searchParameters) {
    return <Loading isActive />
  }

  return (
    <>
      <Container
        pageTitle="Listagem"
        breadcrumb={breadcrumbList}
        tools={toolsList}
      >
        <div className="form">
          <div className="row mb-5">
            <Select
              className="col-md-3"
              name="active"
              label="Ativo"
              options={[
                {
                  name: 'SIM',
                  value: 1
                },
                {
                  name: 'NÃO',
                  value: 0
                }
              ]}
              blank
              defaultValue=""
              value={activeQuery || ''}
              onChange={event => {
                handleSetActiveFilter(Number(event.target.value))
              }}
            />
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <div className="col-md-1 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleExport}
            >
              Exportar
            </button>
          </div>
          <div className="col-md-1 d-flex justify-content-end">
            <button
              type="reset"
              className="btn btn-light-primary"
              onClick={handleClearFilter}
            >
              Limpar
            </button>
          </div>
        </div>

        <DataTable
          source={nameSource}
          entity={nameEntity}
          format={{ orderBy: 'created_at' }}
          headers={headers}
          notHasChildren
          searchParameters={searchParameters}
          customHeaders={[
            {
              name: 'Nascimento',
              field: 'birth_date',
              sortable: true,
              element: item => {
                return (
                  <p>
                    {moment(item?.birth_date).format(INPUT_MASK.DATE_LOCALE)}
                  </p>
                )
              }
            },
            {
              name: 'Criado em',
              field: 'created_at',
              sortable: false,
              element: item => {
                return (
                  <p>
                    {moment(item?.created_at).format(
                      INPUT_MASK.DATE_LOCALE_WITH_TIME
                    )}
                  </p>
                )
              }
            }
          ]}
        />
      </Container>
    </>
  )
}

export default ClientsList
