import React from 'react'
import TutorialsList from '../../pages/Commercial/Tutorials/List'
import TutorialsCreate from '../../pages/Commercial/Tutorials/Create'
import TutorialsUpdate from '../../pages/Commercial/Tutorials/Update'
import TutorialsView from '../../pages/Commercial/Tutorials/View'
import PrivateRoute from '../PrivateRoute'
import { CustomSwitch } from '../../components/CustomSwitch'

export const TutorialsRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoute
      path="/commercial/tutorials"
      exact
      component={TutorialsList}
    />
    <PrivateRoute
      path="/commercial/tutorials/create"
      exact
      component={TutorialsCreate}
    />
    <PrivateRoute
      path="/commercial/tutorials/view/:id"
      exact
      component={TutorialsView}
    />
    <PrivateRoute
      path="/commercial/tutorials/update/:id"
      exact
      component={TutorialsUpdate}
    />
  </CustomSwitch>
)
