export const CONTRACTS_STATUS_PAYMENT_TRANSLATED: Record<string, string> = {
  awaiting_payment: 'Aguardando pagamento',
  paid: 'Pagamento Confirmado',
  cancelled: 'Cancelado',
  payment_reversal: 'Devolvido'
}
export const CONTRACTS_PAYMENT_METHOD_TRANSLATED: Record<string, string> = {
  C: 'Cartão de crédito',
  P: 'Pix',
  B: 'Boleto'
}

export const CONTRACTS_STATUS_PAYMENT = Object.entries(
  CONTRACTS_STATUS_PAYMENT_TRANSLATED
).map(([key, value]) => ({
  name: value,
  value: key
}))
