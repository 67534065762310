export const TUTORIALS_TYPES: Record<string, string> = {
  client: 'Cliente',
  indicator: 'Indicador',
  both: 'Ambos',
  american_visas: 'Visto americano',
  canada_visas: 'Visto canadense',
  mexico_visas: 'Visto mexicano',
  passaport: 'Passaporte'
}

export const TUTORIALS_TYPES_OPTIONS = Object.entries(TUTORIALS_TYPES).map(
  ([englishValue, portugueseValue]) => {
    return {
      name: portugueseValue,
      value: englishValue
    }
  }
)
