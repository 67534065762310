import React from 'react'
import ClientsList from '../../pages/Commercial/Clients/List'
import ClientsCreate from '../../pages/Commercial/Clients/Create'
import ClientsUpdate from '../../pages/Commercial/Clients/Update'
import ClientsView from '../../pages/Commercial/Clients/View'
import PrivateRoute from '../PrivateRoute'
import { CustomSwitch } from '../../components/CustomSwitch'

export const ClientsRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoute path="/commercial/clients" exact component={ClientsList} />
    <PrivateRoute
      path="/commercial/clients/create"
      exact
      component={ClientsCreate}
    />
    <PrivateRoute
      path="/commercial/clients/view/:id"
      exact
      component={ClientsView}
    />
    <PrivateRoute
      path="/commercial/clients/update/:id"
      exact
      component={ClientsUpdate}
    />
  </CustomSwitch>
)
