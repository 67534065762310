import { Header } from '../../../../../components/DataTable'

export const headers: Header[] = [
  { name: 'Nome', field: 'name', sortable: true },
  { name: 'Criado em', field: 'created_at', sortable: true, custom: true },
  {
    name: 'Ações',
    field: 'actions',
    sortable: false
  }
]
