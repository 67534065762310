import React, { useCallback } from 'react'
import api from '../../../../../services/api'
import Form, { Select } from '../../../../../components/Form'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { FormContainer } from './styles'
import { FORM_VISAS_STATUS_OPTIONS } from '../../../../../common/constants'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}
type TypesFormProps = {
  isOpenInModal?: IsOpenInModalProps
  initialValues?: FormApiResponse & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}
export const FormChangeStatusVisa = ({
  isOpenInModal,
  initialValues
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()

  const { updateDataTable } = useUpdateDataTable()

  const { activeLoading, disableLoading } = useLoading()

  const onSubmitForm = useCallback(
    async (data: any) => {
      try {
        activeLoading()
        await api.put(`/commercial/forms/update/${initialValues?.id}`, {
          ...data
        })
        updateDataTable()
        disableLoading()
        isOpenInModal?.handleOnClose?.()
        addToast({
          type: 'success',
          title: 'Registro atualizado',
          description: 'Registro alterado com sucesso'
        })
      } catch (error) {
        disableLoading()
        isOpenInModal?.handleOnClose?.()
        addToast({
          type: 'error',
          title: 'Erro ao atualizar o registro',
          description:
            'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
        })
      }
      disableLoading()
    },
    [
      activeLoading,
      addToast,
      disableLoading,
      initialValues,
      isOpenInModal,
      updateDataTable
    ]
  )

  return (
    <>
      <Form
        onSubmit={onSubmitForm}
        defaultValues={{
          visas_status: initialValues?.visas_status
        }}
      >
        <>
          <div className="card">
            <FormContainer>
              <div className="row mb-5">
                <Select
                  className="col-md-12"
                  name="visas_status"
                  label="Status"
                  options={FORM_VISAS_STATUS_OPTIONS}
                  rules={{ required: true }}
                  blank
                  defaultValue=""
                />
              </div>
            </FormContainer>
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button type="submit" className="btn btn-primary">
                Salvar
              </button>
            </div>
          </div>
        </>
      </Form>
    </>
  )
}
