import React, { createContext, useCallback, useContext, useState } from 'react'
import { v4 as uuid_v4 } from 'uuid'

import ToastContainer from '../components/ToastContainer'
import { toast } from 'react-toastify'

interface ToastContextData {
  addToast(message: Omit<ToastMessage, 'id'>): void
  removeToast(id: string): void
  messages: ToastMessage[]
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData)

const ToastProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<ToastMessage[]>([])

  const addToast = useCallback(({ title, type, id }: ToastMessage) => {
    toast(title, {
      type,
      toastId: id || uuid_v4()
    })
  }, [])

  const removeToast = useCallback((id: string) => {
    setMessages(state => state.filter(message => message.id !== id))
  }, [])

  return (
    <ToastContext.Provider value={{ addToast, removeToast, messages }}>
      {children}
      <ToastContainer messages={messages} />
    </ToastContext.Provider>
  )
}

function useToast(): ToastContextData {
  const context = useContext(ToastContext)

  if (!context) {
    throw new Error('useToast must be used witin a ToastProvider')
  }

  return context
}

export { ToastProvider, useToast }
