import moment from 'moment'
import { InputHTMLAttributes, useCallback, useState } from 'react'
import useCollapse from 'react-collapsed'
import { Controller, useFormContext } from 'react-hook-form'
import { IMaskInput } from 'react-imask'
import { INPUT_MASK } from '../../../../../../common/constants'
import {
  Checkbox,
  Input,
  InputProps,
  Select
} from '../../../../../../components/Form'
import {
  DatePickerCustom,
  DateProps
} from '../../../../../../components/Form/dateWithoutState'
import { ContainerAnimation } from './styles'
interface FormMultpleAdditionProps
  extends InputHTMLAttributes<HTMLInputElement> {
  inputData?: InputProps

  label?: string
  positive?: InputProps
  negative?: InputProps
  data: any[]

  setData: React.Dispatch<React.SetStateAction<any[]>>
  index?: number
  limit?: number
  parent?: string
  hasHeader?: {
    label?: string
    positive?: InputProps
    negative?: InputProps
  }
  hasCustomFields?: {
    label: string
    name: string
    type?: string
    mask?: any
    maxDate?: Date
    options?: { name: string | number; value: string | number }[]
  }[]
  tooltip?: {
    type: string
  }
  changeTab?: React.SetStateAction<string>
}

export const FormMultpleAddition: React.FC<FormMultpleAdditionProps> = ({
  inputData,
  positive,
  negative,
  label,
  data,
  setData,
  index,
  hasCustomFields,
  hasHeader,
  changeTab,
  limit
}) => {
  const {
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
    clearErrors
  } = useFormContext()
  const inputMaskFormat = 'DD/MM/YYYY'
  const watchShowField = hasHeader?.positive?.name
    ? watch(hasHeader?.positive?.name)
    : undefined
  const [copyData, setCopyData] = useState([...data])
  const [showInput, setShowInput] = useState(
    watchShowField || data[index]?.isActive || copyData[index]?.isActive || ''
  )
  const allowClass = ['show', 'show child']
  const { getToggleProps, getCollapseProps } = useCollapse({
    isExpanded: allowClass.includes(showInput)
  })

  const handleAddForm = useCallback(
    (status: string) => {
      const showClass = ['show', 'show child']

      if (data[index + 1]) {
        const prefixArray = positive?.name.split('.')
        prefixArray.pop()
        const prefix = prefixArray.join('.')
        const workFields = getValues(prefix)
        if (workFields) {
          const objectEntries = Object.entries(workFields)
          for (let [key] of objectEntries) {
            key = `${prefix}.${key}`

            if (positive?.name !== key) {
              setValue(key, '')
            }
          }
        }
        clearErrors(prefix)
        setData(oldState => {
          const data = [...oldState]

          data[index + 1].isActive = showClass.includes(
            data[index + 1].isActive
          )
            ? 'hidden'
            : 'show'
          return data
        })
      } else {
        setData(oldState => {
          const data = [...oldState]
          data[index + 1] = {
            id: index + 1,
            isActive: showClass.includes(status) ? 'show child' : 'hidden',
            parent: index
          }
          data[index].isActive = 'show'
          return [...data]
        })
      }
    },
    [clearErrors, data, getValues, index, positive?.name, setData, setValue]
  )

  const handleClose = useCallback(() => {
    const showClass = ['show', 'show child']
    const watchField = watch(hasHeader?.positive?.name || '')
    if (watchField === 'hidden') {
      const prefixArray = hasHeader?.positive?.name.split('.')
      prefixArray.pop()
      const prefix = prefixArray.join('.')
      const workFields = getValues(prefix)
      if (workFields) {
        const objectEntries = Object.entries(workFields)
        for (let [key] of objectEntries) {
          key = `${prefix}.${key}`
          if (hasHeader?.positive?.name !== key) {
            setValue(key, '')
          }
        }
      }
      clearErrors(prefix)
    }
    if (showClass.includes(data[index]?.isActive)) {
      setData(oldState => {
        const data = [...oldState]
        data[index].isActive = showClass.includes(data[index].isActive)
          ? 'hidden'
          : 'show child'
        return data
      })
    }
  }, [
    clearErrors,
    data,
    getValues,
    hasHeader?.positive?.name,
    index,
    setData,
    setValue,
    watch
  ])

  return (
    <div className={'row '}>
      <div>
        {hasHeader?.label && (
          <p className="mb-2 form-check-label text-gray-700 fw-bold">
            {hasHeader?.label}
          </p>
        )}
        <div className="d-flex">
          {hasHeader && hasHeader.positive && (
            <Checkbox
              className="col-xl-1"
              label="Sim"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              register={register}
              {...hasHeader.positive}
              errors={errors}
              type="radio"
              value="show"
              rules={{
                required: changeTab !== 'previous',
                onChange: () => {
                  const watchField = watch(hasHeader.positive.name)
                  if (watchField === 'show') {
                    setCopyData(oldState => {
                      const data = [...oldState]
                      data[index].isActive = 'show'
                      return [...data]
                    })
                    setShowInput('show')
                  } else {
                    handleClose()
                    setShowInput('hidden')
                  }
                }
              }}
              checked={allowClass.includes(showInput)}
              hasCustom
              hasErrorCustom
              {...getToggleProps()}
            />
          )}
          {hasHeader && hasHeader.negative && (
            <Checkbox
              className="col-xl-1"
              label="Não"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              {...hasHeader.negative}
              register={register}
              type="radio"
              value="hidden"
              rules={{ required: changeTab !== 'previous' }}
              errors={errors}
              checked={showInput === 'hidden'}
              hasCustom
              {...getToggleProps()}
            />
          )}
        </div>
      </div>
      <div
        {...getCollapseProps()}
        // isShow={showInput}
        // id={'MultipleAddition ' + inputData?.name || hasCustomFields?.[0]?.name}
        // className="multiple_addition_container"
      >
        {hasCustomFields && (
          <div className="row mb-5 ">
            {hasCustomFields?.map(customField => {
              if (customField.type === 'select') {
                return (
                  <Controller
                    shouldUnregister={true}
                    name={customField.name}
                    rules={
                      changeTab !== 'previous'
                        ? {
                            required: allowClass.includes(showInput)
                          }
                        : { required: false }
                    }
                    render={({ field }) => (
                      <Select
                        className="col-xl"
                        register={register}
                        // inputOptions={{
                        //   classname: 'd-flex align-items-center'
                        // }}
                        label={customField?.label}
                        rules={
                          changeTab !== 'previous'
                            ? {
                                required: allowClass.includes(showInput)
                              }
                            : { required: false }
                        }
                        options={customField.options || []}
                        errors={errors}
                        hasErrorCustom
                        hasCustom
                        {...field}
                        blank
                        defaultValue=""
                        key={customField.name}
                      />
                    )}
                  />
                )
              }
              if (customField.type === 'date') {
                return (
                  <Controller
                    shouldUnregister={true}
                    name={customField.name}
                    rules={
                      changeTab !== 'previous'
                        ? {
                            required: allowClass.includes(showInput)
                          }
                        : { required: false }
                    }
                    render={({ field }) => (
                      <DatePickerCustom
                        popperProps={{ strategy: 'fixed' }}
                        locale="ptBR"
                        className="col-xl"
                        register={register}
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/AAAA"
                        hasCustom
                        label={customField?.label}
                        {...field}
                        errors={errors}
                        hasErrorCustom
                        {...customField}
                        customInput={
                          <IMaskInput
                            mask={Date}
                            pattern={'d/m/Y'}
                            format={date => {
                              return moment(date).format(inputMaskFormat)
                            }}
                            parse={value => {
                              return moment(value, inputMaskFormat).toDate()
                            }}
                          />
                        }
                      />
                    )}
                  />
                )
              }
              return (
                <Controller
                  shouldUnregister={true}
                  name={customField.name}
                  key={customField.name}
                  rules={
                    changeTab !== 'previous'
                      ? {
                          required: allowClass.includes(showInput)
                        }
                      : { required: false }
                  }
                  render={({ field }) => (
                    <Input
                      className="col-xl "
                      label="Telefone com DDD"
                      register={register}
                      inputOptions={{
                        classname: 'd-flex align-items-center'
                      }}
                      {...field}
                      {...customField}
                      rules={
                        changeTab !== 'previous'
                          ? {
                              required: allowClass.includes(showInput)
                            }
                          : { required: false }
                      }
                      errors={errors}
                      hasErrorCustom
                      hasCustom
                      key={customField.name}
                    />
                  )}
                />
              )
            })}
          </div>
        )}
        {!hasCustomFields && (
          <div className="row mb-5 ">
            <Input
              className="col-xl "
              label="Telefone com DDD"
              register={register}
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              {...inputData}
              rules={
                changeTab !== 'previous'
                  ? {
                      required: allowClass.includes(showInput)
                    }
                  : { required: false }
              }
              errors={errors}
              hasErrorCustom
              hasCustom
            />
          </div>
        )}

        {(limit || 0) > index + 1 && (
          <div className="row mb-5 ">
            <p>{label}</p>
            <div className="d-flex">
              <Checkbox
                className="col-xl-1"
                label="Sim "
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                register={register}
                name={positive?.name}
                type="radio"
                value="show"
                rules={{
                  onChange: () => {
                    const watchField = watch(positive.name)
                    if (watchField === 'show') {
                      handleAddForm('show')
                    } else {
                      handleAddForm('hidden')
                    }
                  }
                }}
                checked={
                  data[index + 1]?.isActive === 'show' ||
                  data[index + 1]?.isActive === 'show child'
                }
                hasCustom
              />
              <Checkbox
                className="col-xl-1"
                label="Não"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={negative?.name}
                register={register}
                type="radio"
                value="hidden"
                checked={data[index + 1]?.isActive === 'hidden'}
                hasCustom
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
