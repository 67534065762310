import { Header } from '../../../../../components/DataTable'

export const headers: Header[] = [
  // { name: 'Serviço', field: 'contract.service.name', sortable: true },
  // { name: 'Tipo de formulário', field: 'formType.name', sortable: true },
  // { name: 'Cod.', field: 'contract.service.code', sortable: true },
  {
    name: 'Contratante',
    field: 'contract.client.name',
    sortable: true,
    custom: true
  },
  // { name: 'Cod. Contrato', field: 'contract.id', sortable: true },
  { name: 'Aplicante', field: 'applicant_name', sortable: true, custom: true },
  // { name: 'CPF', field: 'cpf', sortable: true },
  // { name: 'Email', field: 'email', sortable: true, custom: true },
  { name: 'status', field: 'formPhaseProcess', sortable: true, custom: true },
  {
    name: 'status do visto',
    field: 'visas_status',
    sortable: true,
    custom: true
  },
  // { name: 'Criado em', field: 'created_at', sortable: true, custom: true },
  {
    name: 'Ações',
    field: 'actions',
    sortable: false
  }
]
