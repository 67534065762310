import clsx from 'clsx'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useLayout, usePageData } from '../../assets/layout/core'
import { DefaultTitle } from '../DefaultTitle'

const Toolbar: FC = () => {
  const { classes } = useLayout()
  const { pageTools } = usePageData()
  console.log(pageTools)
  return (
    <div className="toolbar" id="kt_toolbar">
      <div
        id="kt_toolbar_container"
        className={clsx(
          classes.toolbarContainer.join(' '),
          'd-flex flex-stack'
        )}
      >
        <DefaultTitle />

        <div className="d-flex align-items-center py-1">
          <div className="me-4">
            {pageTools.map((tool, index) => (
              <Link
                key={tool.name}
                to={tool.to}
                className={`btn btn-sm btn ${
                  tool.color || 'btn-light-primary'
                } fw-bolder`}
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
                data-kt-menu-flip="top-end"
                style={{ marginLeft: index !== 0 ? '5px' : '0px' }}
                onClick={e => {
                  if (tool.handleOnClick) {
                    e.preventDefault()
                    tool.handleOnClick(tool)
                  }
                }}
              >
                {tool.icon && <i className={tool.icon} />}
                {tool.name}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export { Toolbar }
