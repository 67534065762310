export const apiCreate = (): string => '/commercial/contracts/'
export const apiList = (id?: string): string =>
  !id ? '/commercial/contracts/' : `/commercial/contracts/view/${id}`
export const apiListContracts = (clientId: string): string =>
  `/commercial/contracts/client/view/${clientId}`
export const apiUpdate = (id: string): string =>
  `/commercial/contracts/update/${id}`
export const apiDelete = (id: string): string => `/commercial/contracts/${id}`
export const apiUploadDocuments = (): string => '/commercial/formDocuments'
export const apiDeleteDocument = (id: string): string =>
  `/commercial/formDocuments/${id}`
