import React, { useCallback, useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select, Textarea } from '../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { FormContainer } from './styles'
import { Alert } from '../../../../../components/Alert'
import { SELECT_BRAZILIAN_STATES } from '../../../../../common/constants/states-brazilian'
import moment from 'moment'
import { INPUT_MASK } from '../../../../../common/constants'
import axios from 'axios'
import { CLIENT_STATUS_ENUM_OPTIONS } from '../../../../../common/constants/client-status'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}
type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: ClientsApiResponse & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}
const toastSuccessData: Record<string, () => ToastMessage> = {
  create: () => ({
    type: 'success',
    title: 'Registro criado',
    description: 'Registro criado com sucesso'
  }),
  update: () => ({
    type: 'success',
    title: 'Registro atualizado',
    description: 'Registro alterado com sucesso'
  })
}
const toastErrorData: Record<string, () => ToastMessage> = {
  create: () => ({
    type: 'error',
    title: 'Erro ao adicionar o registro',
    description:
      'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
  }),
  update: () => ({
    type: 'error',
    title: 'Erro ao atualizar',
    description:
      'Ocorreu um erro ao alterar os dados, por favor, tente novamente.'
  })
}

export const FormClients = ({
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const { activeLoading, disableLoading } = useLoading()
  const [clientOrigins, setClientOrigins] = useState<
    { name: string; value: number }[]
  >([])
  const [birthDate, setBirthDate] = useState<string>('')
  const [alert, setIsActiveAlert] =
    useState<{ isActive: boolean; document: File }>()
  const [zipCodeValue, setZipCodeValue] = useState('')
  const [cityValue, setCityValue] = useState('')
  const [districtValue, setDistrictValue] = useState('')
  const [streetValue, setStreetValue] = useState('')
  const [numberValue, setNumberValue] = useState('')
  const [stateValue, setStateValue] = useState('')
  const [passwordValue, setPasswordValue] = useState('')

  async function loadClientOriginsOptions() {
    try {
      const responseClientOrigins = await api.get(
        '/commercial/clientOrigins/options'
      )
      setClientOrigins(responseClientOrigins.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (initialValues?.birth_date) {
      setBirthDate(
        moment(initialValues?.birth_date).format(INPUT_MASK.DATE_ISO_YEAR)
      )
    }
  }, [initialValues?.birth_date])

  useEffect(() => {
    loadClientOriginsOptions()
  }, [])

  const onSubmitForm = useCallback(
    async (data: any) => {
      const id = initialValues?.idUpdate
      data.birth_date = birthDate
      data.country = 'Brasil'
      const apiRequest =
        typeForm === 'create'
          ? api.post(apiCreate(), data)
          : api.put(apiUpdate(id.toString()), data)

      try {
        activeLoading()
        await apiRequest
        disableLoading()
        addToast(toastSuccessData[typeForm]?.())
        if (typeForm === 'update') {
          updateDataTable()
        }
        history.push(nameActions.read.to)
      } catch (error) {
        history.push(nameActions.read.to)
        addToast(toastErrorData[typeForm]?.())
      }
    },
    [
      activeLoading,
      addToast,
      birthDate,
      disableLoading,
      history,
      initialValues?.idUpdate,
      typeForm,
      updateDataTable
    ]
  )

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert(oldAlert => ({
      ...oldAlert,
      isActive: false
    }))
  }

  const handleGetAddress = useCallback(async event => {
    const value = event.target.value
    setZipCodeValue(value)
    const zipCodeSearch = value?.replaceAll(/[.\-/]/g, '')
    if (zipCodeSearch.length === 8) {
      const addressResponse = await axios.get(
        `https://viacep.com.br/ws/${zipCodeSearch}/json`,
        {
          timeout: 10000
        }
      )
      if (addressResponse.data.erro) {
        return
      }
      setCityValue(addressResponse.data.localidade)
      setDistrictValue(addressResponse.data.bairro)
      setStreetValue(addressResponse.data.logradouro)
      setStateValue(addressResponse.data.uf)
    }
  }, [])

  return (
    <>
      <Form
        onSubmit={onSubmitForm}
        defaultValues={{
          name: initialValues?.name,
          cpf: initialValues?.cpf,
          email: initialValues?.email,
          street: initialValues?.street,
          number: initialValues?.number,
          complement: initialValues?.complement || '',
          district: initialValues?.district,
          city: initialValues?.city,
          uf: initialValues?.uf,
          zip_code: initialValues?.zip_code,
          country: initialValues?.country,
          phone: initialValues?.phone,
          client_origin_id: initialValues?.client_origin_id,
          status: initialValues?.status,
          cell_phone: initialValues?.cell_phone,
          active: initialValues?.active ? String(initialValues?.active) : '1'
        }}
      >
        <>
          <div className="card">
            <FormContainer>
              <div className="row mb-5">
                <Input
                  name="name"
                  label="Nome"
                  rules={{ required: true }}
                  className="col-md-6"
                />
                <Input
                  name="cpf"
                  label="CPF"
                  rules={{ required: true }}
                  className="col-md-3"
                  mask={{
                    mask: INPUT_MASK.CPF
                  }}
                  controlled
                />
                <Input
                  name="birth_date"
                  label="Nascimento"
                  rules={{ required: true }}
                  className="col-md-3"
                  type="date"
                  value={birthDate}
                  onChange={event => {
                    setBirthDate(event.target.value)
                  }}
                  controlled
                />
              </div>
              <div className="row mb-5">
                <Input
                  name="phone"
                  label="Telefone"
                  rules={{ required: false }}
                  className="col-md-3"
                  mask={{
                    mask: INPUT_MASK.PHONE
                  }}
                  controlled
                />
                <Input
                  name="cell_phone"
                  label="Celular"
                  rules={{ required: true }}
                  className="col-md-3"
                  mask={{
                    mask: INPUT_MASK.CELL_PHONE
                  }}
                  controlled
                />
                <Input
                  name="email"
                  label="Email"
                  rules={{ required: true }}
                  className="col-md-6"
                />
              </div>

              <div className="row mb-5">
                <Input
                  name="zip_code"
                  label="Cep"
                  rules={{ required: true }}
                  className="col-md-2"
                  mask={{
                    mask: INPUT_MASK.ZIP_CODE
                  }}
                  controlled
                  onChange={handleGetAddress}
                  value={zipCodeValue}
                />
                <Input
                  name="city"
                  label="Cidade"
                  rules={{ required: true }}
                  className="col-md-3"
                  controlled
                  value={cityValue}
                  onChange={event => {
                    setCityValue(event.target.value)
                  }}
                />
                <Input
                  name="district"
                  label="Bairro"
                  rules={{ required: true }}
                  className="col-md-3"
                  value={districtValue}
                  onChange={event => {
                    setDistrictValue(event.target.value)
                  }}
                  controlled
                />
                <Input
                  name="street"
                  label="Endereço"
                  rules={{ required: true }}
                  className="col-md-3"
                  value={streetValue}
                  onChange={event => {
                    setStreetValue(event.target.value)
                  }}
                  controlled
                />

                <Input
                  name="number"
                  label="Número"
                  rules={{ required: true }}
                  className="col-md-1"
                  value={numberValue}
                  onChange={event => {
                    setNumberValue(event.target.value)
                  }}
                  controlled
                />
                <Select
                  name="uf"
                  label="Estado"
                  rules={{ required: true }}
                  className="col-md-2"
                  options={SELECT_BRAZILIAN_STATES}
                  value={stateValue}
                  onChange={event => {
                    setStateValue(event.target.value)
                  }}
                  controlled
                />
                <Input
                  name="password"
                  label="Senha"
                  rules={{
                    required: false,
                    minLength: {
                      value: 6,
                      message: 'No mínimo 6 caracteres'
                    }
                  }}
                  className="col-md-3"
                  value={passwordValue}
                  onChange={event => {
                    setPasswordValue(event.target.value)
                  }}
                  type="password"
                  controlled
                />
                <Select
                  name="status"
                  label="Status"
                  rules={{ required: false }}
                  className="col-md-2"
                  options={CLIENT_STATUS_ENUM_OPTIONS}
                  defaultValue=""
                  blank
                />
                <Select
                  name="client_origin_id"
                  label="Origem"
                  rules={{ required: false }}
                  className="col-md-2"
                  options={clientOrigins}
                  defaultValue=""
                  blank
                />
                {typeForm === 'update' && (
                  <Select
                    name="active"
                    label="Ativo"
                    rules={{ required: false }}
                    className="col-md-2"
                    options={[
                      {
                        name: 'SIM',
                        value: 1
                      },
                      {
                        name: 'NÃO',
                        value: 0
                      }
                    ]}
                    defaultValue=""
                    blank
                  />
                )}
              </div>
              <div className="row mb-5">
                <Textarea
                  name="complement"
                  label="Complemento"
                  className="col-md-12"
                  style={{ minHeight: 100 }}
                  maxLength={100}
                />
              </div>
            </FormContainer>
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button type="submit" className="btn btn-primary">
                Salvar
              </button>
            </div>
          </div>
        </>
      </Form>
      {alert && alert.isActive && (
        <Alert
          message={`Tem certeza que deseja excluir esse documento ${alert.document.name} ? ele não poderá ser recuperado`}
          onClickCancellButton={handlerClickButtonCancellAlert}
          onClickConfirmButton={() => {
            // handleRemoveFile(alert.document)
            setIsActiveAlert(oldAlert => ({
              ...oldAlert,
              isActive: false
            }))
          }}
          isActive={alert.isActive}
        />
      )}
    </>
  )
}
