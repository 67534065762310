import React, { useLayoutEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { KTSVG } from '../../../assets/helpers'
import { useLayout } from '../../../assets/layout/core'
import { AsideDefault } from '../../../assets/layout/components/aside/AsideDefault'
import { Toolbar } from '../../../components/Toolbar'
import logo from '../../../assets/media/logos/logo.png'
import asideSvg from '../../../assets/media/icons/duotune/abstract/abs015.svg'

import Header from '../../../components/Header'
import { MenuComponent } from '../../../assets/ts/components'

const DefaultLayout: React.FC = ({ children }) => {
  const { config } = useLayout()
  const { aside } = config

  const location = useLocation()
  useLayoutEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useLayoutEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])
  return (
    <div className="page d-flex flex-row flex-column-fluid">
      <AsideDefault />
      {aside.display && (
        <div
          className="d-flex align-items-center d-lg-none ms-n3 me-1"
          title="Show aside menu"
        >
          <div
            className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
            id="kt_aside_mobile_toggle"
          >
            <KTSVG path={asideSvg} className="svg-icon-2x mt-1" />
          </div>
        </div>
      )}
      {!aside.display && (
        <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
          <Link to="/dashboard" className="d-lg-none">
            <img alt="Logo" src={logo} className="h-30px" />
          </Link>
        </div>
      )}
      {aside.display && (
        <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
          <Link to="/" className="d-lg-none">
            <img alt="Logo" src={logo} className="h-30px" />
          </Link>
        </div>
      )}
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <Header />

        <div
          id="kt_content"
          className="content d-flex flex-column flex-column-fluid"
        >
          <Toolbar />
          <div className="post d-flex flex-column-fluid" id="kt_post">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DefaultLayout
