import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import {
  ContainerHeader,
  ContainerLogo,
  ContainerWrapperContent,
  LinkContainer,
  Table,
  Row,
  WrapperContent,
  Error
} from './styles'
import Form, { Checkbox, Input, Select } from '../../../components/Form'
import Tabs from '../../../components/Tabs'
import { useLoading } from '../../../hooks/loading'
import api from '../../../services/api'
import { useToast } from '../../../hooks/toast'
import { validate } from 'gerador-validador-cpf'
import { cpfMask, phoneMask, zipCodeMask } from '../../../utils/mask'
import { Loading } from '../../../components/Loading'
import { useHistory, useLocation } from 'react-router-dom'
import { Date as DatePicker } from '../../../components/Form/date'
import { IMaskInput } from 'react-imask'
import moment from 'moment'
import axios from 'axios'
import Tab from '../../../components/Tabs/Tab'
import { Alert } from '../../../components/Alert'
import logo from '../../../assets/media/logos/logo.png'
import { TooltipComponent } from '../../../components/TooltipComponent'
import { StringParam, useQueryParam } from 'use-query-params'
import { FiAlertCircle } from 'react-icons/fi'
import { Buffer } from 'buffer'
import { StickyComponent } from '../../../assets/ts/components'
type OptionsValue = {
  cetificationType: SelectOptionsValue[]
  gender: SelectOptionsValue[]
  maritalStatus: SelectOptionsValue[]
  previousPassportSituation: SelectOptionsValue[]
  raceColor: SelectOptionsValue[]
  reasonChange: SelectOptionsValue[]
  profession: SelectOptionsValue[]
  states: {
    UF: string
    cities: SelectOptionsValue[]
  }[]
  countries: SelectOptionsValue[]
  nationality: SelectOptionsValue[]
}
type SelectOptionsValue = {
  name: string
  value: string
}
type FormData = {
  id?: number
  full_name: string
  gender: string
  parentage: {
    name: string
    gender: string
  }[]
  race_color: string
  marital_status: string
  nationality: string
  country: string
  state: string
  city: string
  previousNames: {
    name: string
    reason: string
  }[]
  document_number: string
  issue_date: Date
  birth_date: Date
  issuing_body: string
  state_expedition: string
  cpf: string
  cpf_responsible: string
  certificate: {
    enrollment: string
    type: string
    number: string
    book: string
    sheet: string
    registry: string
    state_expedition: string
    city_expedition: string
  }
  previousPassport: {
    situation: string
    series: string
    number: string
    hasUpdateName: boolean
  }
  profession: string
  email: string
  email_confirmation: string
  applicant: {
    country: string
    zip_code: string
    state: string
    city: string
    street: string
    district: string
    ddd: string
    phone: string
  }
  emancipated: boolean
  international_adoption: boolean
  new_model_certificate: boolean
  cities?: any
  countryValue?: any
  attachments?: any
}
export const Passport: React.FC = () => {
  const location = useLocation()
  const [id] = useQueryParam('B3C1836D42ADD8F9B5C41211C401838C', StringParam)
  const { activeLoading, disableLoading, loading } = useLoading()
  const { addToast } = useToast()
  const history = useHistory()
  const [activeTab, setActiveTab] = useState(0)
  const [states, setStates] = useState<[]>([])
  const [countries, setCountries] = useState([])
  const [optionsValue, setOptionsValue] = useState<OptionsValue>()
  const [previousNames, setPreviousNames] = useState([])
  const [previousNameValue, setPreviousNameValue] = useState('')
  const [previousNameReasonValue, setPreviousNameReasonValue] = useState('')
  const [checkNewModel, setCheckNewModel] = useState(false)
  const [checkEmancipated, setCheckEmancipated] = useState(false)
  const [confirmData, setConfirmData] = useState(false)
  const [disableCheckboxes, setDisableCheckboxes] = useState({
    emancipated: true,
    internationalAdoption: true
  })
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })
  const [checkInternationalAdoption, setCheckInternationalAdoption] =
    useState(false)
  const [countryValue, setCountryValue] = useState<any>({
    country: 'BRASIL',
    'applicant.country': 'BRASIL'
  })
  const [birthDate, setBirthDate] = useState<Date>()
  const [issueDate, setIssueDate] = useState<Date>()
  const [nationalityValue, setNationalityValue] = useState('BRASIL')
  const [dataForm, setDataForm] = useState<FormData>({} as FormData)
  const [copyDataForm, setCopyDataForm] = useState<FormData>({} as FormData)
  const [cities, setCities] = useState<any>()
  const [selectedValue, setSelectedValues] = useState<any>()
  const [stateExpedition, setStateExpedition] = useState<string>('')
  const [cityExpedition, setCityExpedition] = useState<string>('')
  const [certificateType, setCertificateType] = useState<string>('')
  const [certificateNumber, setCertificateNumber] = useState<string>('')
  const [profession, setProfession] = useState<string>('')
  const [maritalStatus, setMaritalStatus] = useState<string>('')
  const [raceColor, setRaceColor] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [documentNumber, setDocumentNumber] = useState<string>('')
  const [issuingBody, setIssuingBody] = useState<string>('')
  const [parentage, setParentage] = useState<any>({})
  const [applicant, setApplicant] = useState<any>()
  const [inputsErrors, setInputsErrors] = useState<any>({})
  const [previousFormErros, setPreviousFormErros] = useState<any>({})
  const [documentFormErros, setDocumentFormErros] = useState<any>({})
  const [previousPassportSituation, setPreviousPassportSituation] = useState('')
  const [previousPassportNumber, setPreviousPassportNumber] = useState('')
  const [emailValue, setEmailValue] = useState('')
  const [confirmEmailValue, setConfirmEmailValue] = useState('')
  const [seriesValue, setSeriesValue] = useState('')
  const titlePageRef = useRef<HTMLDivElement>()
  const [hasConfirmed, setHasConfirmed] = useState(true)
  const [age, setAge] = useState(0)
  const [hasErroUpdateName, setHasErroUpdateName] = useState(false)
  const [attachments, setAttachments] = useState<
    { id: number; url?: string; name?: string; file?: File }[]
  >([{ id: 1 }])
  const [supplementaryDataFormErros, setSupplementaryDataFormErros] =
    useState<any>({})
  const [changeTab, setChangeTab] = useState('next')
  const [previousPassportHasUpdateName, setPreviousPassportHasUpdatename] =
    useState(undefined)
  const inputMaskFormat = 'DD/MM/YYYY'

  useLayoutEffect(() => {
    setTimeout(() => {
      StickyComponent.reInitialization()
    }, 500)
  }, [])

  useEffect(() => {
    async function loadData() {
      activeLoading()
      if (id) {
        const convertedId = Buffer.from(`${id}`, 'base64').toString()
        try {
          const response = await api.get(
            `/commercial/forms/view/${convertedId}`
          )
          const { data } = response
          const locationData = data.values as FormData
          const copyData: FormData = {
            ...locationData,
            cpf: data.cpf,
            email: data.email
          }
          if (moment().diff(moment(copyData.birth_date), 'year') <= 18) {
            setDisableCheckboxes(oldDisableCheckboxes => ({
              ...oldDisableCheckboxes,
              emancipated: false,
              internationalAdoption: false
            }))
          }

          if (copyData.attachments?.length) {
            const copyAttachment: any[] = []
            for (const attachment of copyData.attachments) {
              if (attachment) {
                fetch(attachment.url)
                  .then(response => response.blob())
                  .then(blob => {
                    const file = new File([blob], attachment.name)
                    copyAttachment[attachment.id] = {
                      ...attachment,
                      file
                    }
                  })
              }
            }
            setAttachments(copyAttachment)
          }

          let copyParentage: any = {
            gender: copyData.gender
          }
          if (copyData.parentage) {
            Object.entries(copyData.parentage).forEach(([, value], index) => {
              copyParentage = {
                ...copyParentage,
                [index]: value.gender
              }
            })
          }
          setInputsErrors({})
          setParentage(copyParentage)
          if (copyData?.issue_date) {
            setIssueDate(
              moment(copyData?.issue_date, inputMaskFormat)?.toDate()
            )
          }
          if (copyData?.birth_date) {
            setBirthDate(
              moment(copyData?.birth_date, inputMaskFormat)?.toDate()
            )
          }
          setParentage((oldParentage: any) => copyParentage || oldParentage)
          setCheckEmancipated(
            (oldEmancipated: any) => copyData?.emancipated || oldEmancipated
          )
          setCheckInternationalAdoption(
            oldInternational =>
              copyData?.international_adoption || oldInternational
          )
          setRaceColor(oldRaceColor => copyData?.race_color || oldRaceColor)
          setMaritalStatus(
            oldMaritalStatus => copyData?.marital_status || oldMaritalStatus
          )
          setNationalityValue(
            oldNationality => copyData?.nationality || oldNationality
          )
          setDocumentNumber(
            oldDocumentNumber => copyData?.document_number || oldDocumentNumber
          )
          setSeriesValue(
            oldSeriesValue =>
              copyData?.previousPassport?.series || oldSeriesValue
          )
          setPreviousPassportNumber(
            oldPreviousPassaportNumber =>
              copyData?.previousPassport?.number || oldPreviousPassaportNumber
          )
          setIssuingBody(
            oldIssuingBody => copyData?.issuing_body || oldIssuingBody
          )
          setStateExpedition(
            oldStateExpedition =>
              copyData?.state_expedition || oldStateExpedition
          )
          setCertificateType(
            oldCertificateType =>
              copyData?.certificate?.type || oldCertificateType
          )
          setCertificateNumber(
            oldCertificateNumber =>
              copyData?.certificate?.number || oldCertificateNumber
          )
          setApplicant(
            (oldApplicant: any) => copyData?.applicant || oldApplicant
          )
          setProfession(
            oldProfessions => copyData?.profession || oldProfessions
          )
          setEmailValue(oldEmail => copyData?.email || oldEmail)
          setConfirmEmailValue(
            oldConfirmedEmail =>
              copyData?.email_confirmation || oldConfirmedEmail
          )
          setSelectedValues((oldSelectedValue: any) => ({
            ...oldSelectedValue,
            state: copyData?.state,
            'certificate?.state_expedition':
              copyData?.certificate?.state_expedition,
            'applicant?.state': copyData?.applicant?.state
          }))

          setCity(oldCity => copyData?.city || oldCity)
          setPreviousNames(
            oldPreviousName => copyData?.previousNames || oldPreviousName
          )
          setCheckNewModel(
            oldCheckNewModel =>
              copyData?.new_model_certificate || oldCheckNewModel
          )
          setPreviousPassportSituation(
            oldPreviousPassportSituation =>
              copyData?.previousPassport?.situation ||
              oldPreviousPassportSituation
          )
          setPreviousPassportHasUpdatename(
            (oldPreviousPassaport: any) =>
              copyData?.previousPassport?.hasUpdateName || oldPreviousPassaport
          )
          setCityExpedition(
            oldCityExpedition =>
              copyData?.certificate?.city_expedition || oldCityExpedition
          )
          if (copyData?.cities) {
            setCities({
              ...copyData?.cities
            })
          } else {
            const findState = optionsValue?.states?.find(
              state => state?.UF === copyData?.state
            )
            setCities((oldCities: any) => ({
              ...oldCities,
              state: findState?.cities
            }))
          }
          if (copyData?.applicant?.country) {
            setCountryValue((oldCountryValue: any) => ({
              ...oldCountryValue,
              'applicant.country': copyData?.applicant?.country
            }))
          }
          if (copyData?.country) {
            setCountryValue((oldCountryValue: any) => ({
              ...oldCountryValue,
              country: copyData.country
            }))
          }
          setDataForm({
            id: data?.id,
            ...copyData
          })
          setCopyDataForm({
            id: data?.id,
            ...copyData
          })
          disableLoading()
        } catch (err) {
          disableLoading()
          addToast({
            type: 'error',
            title: 'Error ao carregar o cliente',
            description:
              'Houve um error ao carregar o cliente, tente novamente mais tarde!'
          })
        }
      } else {
        addToast({
          title: 'Usuário sem autorização',
          description: 'Você não tem permissão para preencher um formulário',
          type: 'error'
        })
        history.replace('/dashboard')
      }
      disableLoading()
    }
    loadData()
  }, [
    activeLoading,
    addToast,
    disableLoading,
    history,
    id,
    location,
    optionsValue
  ])

  useEffect(() => {
    window.addEventListener('keydown', event => {
      if (event.key === 'Enter') {
        event.preventDefault()
      }
    })
    return () => {
      window.removeEventListener('keydown', event => {
        if (event.key === 'Enter') {
          event.preventDefault()
        }
      })
    }
  }, [])

  const onSubmit = useCallback(async () => {
    const formData = new FormData()
    formData.append('form_id', copyDataForm?.id?.toString())
    formData.append('phase_process_id', '2')

    try {
      activeLoading()
      // await api.post('/commercial/formPhasesProcess', formData)
      history.push('/commercial/contracts/forms')
      disableLoading()
    } catch (error) {
      disableLoading()
    }
    disableLoading()
  }, [activeLoading, copyDataForm.id, disableLoading, history])

  // const onSubmit = useCallback(
  //   async (formValues: FormData) => {
  //     activeLoading()
  //     try {
  //       const data = {
  //         ...dataForm,
  //         ...formValues,
  //         emancipated: checkEmancipated,
  //         international_adoption: checkInternationalAdoption,
  //         new_model_certificate: checkNewModel,
  //         issue_date: issueDate?.toLocaleDateString() || dataForm.issue_date,
  //         birth_date: birthDate?.toLocaleDateString() || dataForm.birth_date,
  //         previousPassport: {
  //           ...dataForm.previousPassport,
  //           hasUpdateName: previousPassportHasUpdateName,
  //           situation: previousPassportSituation
  //         },
  //         applicant: {
  //           ...applicant,
  //           ...dataForm.applicant,
  //           state: selectedValue['applicant.state']
  //         },
  //         previousNames: [...previousNames],
  //         country: countryValue.country,

  //         cities,
  //         countryValue,
  //         email: emailValue,
  //         email_confirmation: emailValue,
  //         document_number: documentNumber,
  //         issuing_body: issuingBody,
  //         profession,
  //         certificate: {
  //           ...dataForm.certificate,
  //           number: certificateNumber,
  //           type: certificateType,
  //           state_expedition: selectedValue['certificate.state_expedition'],
  //           city_expedition: cityExpedition
  //         },
  //         state_expedition: stateExpedition,
  //         gender: parentage.gender
  //       }
  //       const copyParatage = dataForm.parentage.map(
  //         (item, index) =>
  //           item && {
  //             name: item.name,
  //             gender: parentage[index]
  //           }
  //       )
  //       const formData = new FormData()
  //       if (data?.attachments) {
  //         const attachmentsData = data?.attachments?.map(
  //           // eslint-disable-next-line array-callback-return
  //           (dataItem: any, index: number) => {
  //             if (dataItem) {
  //               const findAttachment = attachments.find(
  //                 attachment => index === attachment.id
  //               )
  //               if (findAttachment) {
  //                 if (findAttachment.file) {
  //                   return findAttachment
  //                 }
  //                 return dataItem
  //               }
  //             }
  //           }
  //         )

  //         for (const attachment of attachmentsData) {
  //           if (attachment) {
  //             if (attachment[0]) {
  //               formData.append('attachments', attachment[0])
  //             }
  //             if (attachment.file) {
  //               formData.append('attachments', attachment.file)
  //             }
  //           }
  //         }
  //       }
  //       delete data.attachments
  //       formData.append('name', data.full_name)
  //       formData.append('email', data.email)
  //       formData.append('cpf', data.cpf)
  //       formData.append('type', 'passport')

  //       if (dataForm.id) {
  //         formData.append(
  //           'values',
  //           JSON.stringify({
  //             ...data,
  //             parentage: copyParatage.filter(item => item)
  //           })
  //         )
  //         await api.put(
  //           `/commercial/public/clients/update/${dataForm.id}`,
  //           formData
  //         )
  //         disableLoading()
  //         addToast({
  //           type: 'success',
  //           title: 'Registro alterado',
  //           description: 'Registro alterado com sucesso'
  //         })
  //       } else {
  //         formData.append('values', JSON.stringify(data))
  //         await api.post('/commercial/public/clients', formData)
  //         disableLoading()
  //         addToast({
  //           type: 'success',
  //           title: 'Registro criado',
  //           description: 'Registro criado com sucesso'
  //         })
  //       }

  //       history.go(0)
  //     } catch (error) {
  //       console.log(error)
  //       addToast({
  //         type: 'error',
  //         title: 'Erro ao adicionar registro',
  //         description:
  //           'Ocorreu um erro ao adicionar o registro, por favor, tente novamente.'
  //       })
  //       disableLoading()
  //     }
  //   },
  //   [
  //     activeLoading,
  //     addToast,
  //     applicant,
  //     attachments,
  //     birthDate,
  //     certificateNumber,
  //     certificateType,
  //     checkEmancipated,
  //     checkInternationalAdoption,
  //     checkNewModel,
  //     cities,
  //     cityExpedition,
  //     countryValue,
  //     dataForm,
  //     disableLoading,
  //     documentNumber,
  //     emailValue,
  //     history,
  //     issueDate,
  //     issuingBody,
  //     parentage,
  //     previousNames,
  //     previousPassportHasUpdateName,
  //     previousPassportSituation,
  //     profession,
  //     selectedValue,
  //     stateExpedition
  //   ]
  // )

  const handleNextForm = useCallback(
    async (data: any) => {
      const formData = {
        ...JSON.parse(JSON.stringify(copyDataForm)),
        ...JSON.parse(JSON.stringify(dataForm)),
        ...JSON.parse(JSON.stringify(data)),
        nationality: nationalityValue
      }
      if (data?.attachments) {
        const attachmentsData = data?.attachments
          ?.map(
            // eslint-disable-next-line array-callback-return
            (dataItem: any, index: number) => {
              if (dataItem) {
                const findAttachment = attachments.find(
                  attachment => index === attachment?.id
                )
                if (findAttachment) {
                  if (findAttachment.file) {
                    return findAttachment
                  }
                  return {
                    id: index,
                    name: dataItem[0]?.name,
                    file: dataItem[0]
                  }
                }
              }
            }
          )
          .filter((item: any) => item)
        setAttachments(attachmentsData)
      }
      setDataForm(formData)
      if (id) {
        setCopyDataForm({
          ...copyDataForm,
          ...formData
        })
      }
      if (changeTab === 'previous') {
        setChangeTab('next')
        setActiveTab(activeTab - 1)
      } else {
        if (
          previousPassportSituation?.length &&
          previousPassportSituation !==
            'Nunca teve Passaporte Comum, de Emergência, para Estrangeiro ou Laissez-Passer brasileiro'
        ) {
          if (previousPassportHasUpdateName === undefined) {
            setHasErroUpdateName(true)
            return
          } else {
            setHasErroUpdateName(false)
          }
        }
        setActiveTab(activeTab + 1)
      }
    },
    [
      copyDataForm,
      dataForm,
      nationalityValue,
      id,
      changeTab,
      attachments,
      activeTab,
      previousPassportSituation,
      previousPassportHasUpdateName
    ]
  )

  const loadOptions = useCallback(async () => {
    activeLoading()
    try {
      const { data } = await api.get('/options')
      setOptionsValue({
        ...data
      })
      setStates(
        data.states
          .sort((currentItem: { UF: string }, nextItem: { UF: string }) =>
            currentItem.UF.localeCompare(nextItem.UF)
          )
          .map(
            (item: { UF: string }) => item && { name: item.UF, value: item.UF }
          )
      )
      setCountries(data.countries)
      disableLoading()
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao buscar dados',
        description:
          'Ocorreu um erro ao buscar os dados, por favor, tente novamente.'
      })
      disableLoading()
    }
  }, [activeLoading, addToast, disableLoading])

  const handleSelectNationality = useCallback(
    (country: string) => {
      if (country.toLowerCase() !== 'brasil') {
        setIsActiveAlert({ ...alert, isActive: true, name: country })
      } else {
        setNationalityValue(country)
      }
    },
    [alert]
  )

  const handleSelectState = useCallback(
    (
      event?: React.ChangeEvent<HTMLSelectElement>,
      defaultValues?: { name: string; value: string }
    ) => {
      const targetEvent: any = { ...event }
      const fieldName =
        targetEvent?.target?.getAttribute('name') ||
        targetEvent?.name ||
        defaultValues?.name
      const value = targetEvent?.target?.value || defaultValues?.value
      const findState = optionsValue?.states?.find(state => state.UF === value)
      setCities({
        ...cities,
        [fieldName]: [...findState?.cities]
      })
      setSelectedValues({
        ...selectedValue,
        [fieldName]: value
      })
    },
    [cities, optionsValue?.states, selectedValue]
  )
  const handleSelectCountry = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>, optionsValue?: any) => {
      const targetEvent: any = { ...event }
      const fieldName =
        targetEvent?.target?.getAttribute('name') || optionsValue?.name
      const value = targetEvent.target.value
      setCountryValue({
        ...countryValue,
        [fieldName]: value
      })
      if (value.toLowerCase() !== 'brasil') {
        if (fieldName === 'applicant.country') {
          setApplicant({})
          setSelectedValues({
            ...selectedValue,
            'applicant.state': ''
          })
          setDataForm({
            ...dataForm,
            applicant: {
              ...dataForm.applicant,
              zip_code: '',
              state: '',
              city: '',
              district: '',
              street: '',
              country: value
            }
          })
        }
        if (fieldName === 'country') {
          setSelectedValues({
            ...selectedValue,
            state: ''
          })
          setCity('')
          setDataForm(oldData => {
            const copyData = { ...JSON.parse(JSON.stringify(oldData)) }
            copyData.city = ''
            copyData.state = ''
            return copyData
          })
        }
      }
    },
    [countryValue, dataForm, selectedValue]
  )
  const handleInputCpfValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const fieldName = event.target.getAttribute('name')
      const value = event.target.value
      if (value?.length && value?.length === 14) {
        if (validate(value)) {
          setInputsErrors({
            ...inputsErrors,
            [fieldName]: undefined
          })
        } else {
          setInputsErrors({
            ...inputsErrors,
            [fieldName]: { error: true, message: 'CPF Inválido' }
          })
        }
      } else {
        setInputsErrors({
          ...inputsErrors,
          [fieldName]: undefined
        })
      }
      setDataForm({
        ...dataForm,
        [fieldName]: cpfMask(value)
      })
    },
    [dataForm, inputsErrors]
  )
  const checkPreviousSituation = useCallback(
    (value?: string) => {
      const enableSituations = [
        'Passaporte anterior válido (obrigatória a apresentação)',
        'Passaporte anterior vencido (recomenda-se apresentação para cancelamento físico do documento)'
      ]
      const situation = value || previousPassportSituation
      if (enableSituations.includes(situation)) {
        return false
      }
      return true
    },
    [previousPassportSituation]
  )

  useEffect(() => {
    loadOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
  }
  const handlerClickButtonConfirmAlert = async () => {
    setNationalityValue(alert.name)
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
  }

  const getDataCep = useCallback(
    async (zipCodeData: string) => {
      const zipCodeSearch = zipCodeData?.replaceAll(/[.\-/]/g, '')
      if (zipCodeSearch?.length === 8) {
        activeLoading()
        const response = await axios.get(
          `https://viacep.com.br/ws/${zipCodeSearch}/json`,
          {
            timeout: 10000
          }
        )
        if (response.data.erro) {
          setDataForm({
            ...dataForm,
            applicant: {
              ...dataForm.applicant,
              zip_code: zipCodeMask(zipCodeData)
            }
          })
          disableLoading()
          return
        }
        const { bairro, localidade, logradouro, uf } = response.data

        handleSelectState(undefined, {
          name: 'applicant.state',
          value: uf
        })
        setApplicant({
          ...dataForm.applicant,
          city: localidade,
          district: bairro,
          street: logradouro
        })
        disableLoading()
      }
      setDataForm({
        ...dataForm,
        applicant: {
          ...dataForm.applicant,
          zip_code: zipCodeMask(zipCodeData)
        }
      })
    },
    [activeLoading, dataForm, disableLoading, handleSelectState]
  )

  const handleRemovePreviousName = useCallback(
    removeItem => {
      const filterPreviousName = previousNames.filter(
        previousName => previousName.id !== removeItem.id
      )
      setPreviousNames([...filterPreviousName])
    },
    [previousNames]
  )

  const handleVerifyEmail = useCallback(
    (email: string) => {
      if (email?.length >= emailValue?.length) {
        if (email !== emailValue) {
          setInputsErrors({
            ...inputsErrors,
            confirmEmail: { error: true, message: 'Os email devem ser iguais' }
          })
        } else {
          setInputsErrors({
            ...inputsErrors,
            confirmEmail: undefined
          })
        }
      }
    },
    [emailValue, inputsErrors]
  )
  const handleVerifyPreviousNames = useCallback(() => {
    let formErros = {}
    if (
      previousNameValue?.length >= 10 &&
      previousNameValue?.length &&
      previousNameReasonValue?.length
    ) {
      setPreviousNames([
        ...previousNames,
        {
          id: previousNameValue + previousNameReasonValue,
          name: previousNameValue,
          reason: previousNameReasonValue
        }
      ])
      setPreviousNameValue('')
      setPreviousNameReasonValue('')
      setPreviousFormErros({})
      return
    }
    if (!previousNameValue?.length) {
      formErros = {
        ...formErros,
        name: { error: true, message: 'Campo Nome Anterior é obrigatório' }
      }
    }
    if (!previousNameReasonValue?.length) {
      formErros = {
        ...formErros,
        reason: { '': true }
      }
    }
    if (previousNameValue?.length && previousNameValue?.length < 11) {
      formErros = {
        ...formErros,
        name: { error: true, message: 'Digite Nome e Sobrenome Completos' }
      }
    }
    setPreviousFormErros(formErros)
  }, [previousNameReasonValue, previousNameValue, previousNames])

  const handleVerifyInputNumber = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      formErrosData: any,
      setFormErros: (value: any) => void,
      setInputValue: (value: any) => void
    ) => {
      let formErros = {
        ...formErrosData
      }
      const inputName = event.target.getAttribute('name')
      const value = event.target.value
      const cleanValue = value.replace(/[^\d]/g, '')
      if (!cleanValue?.length && value?.length) {
        formErros = {
          ...formErros,
          [inputName]: { error: true, message: 'Somete Números' }
        }
      }
      if (cleanValue?.length && value?.length) {
        formErros = {
          ...formErros,
          [inputName]: undefined
        }
      }
      setFormErros(formErros)
      setInputValue(cleanValue)
    },
    []
  )

  const handleVerifyInputText = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      setFormErros: (value: any) => void,
      setInputValue: (value: any) => void
    ) => {
      let formErros = {}
      const inputName = event.target.getAttribute('name')
      const value = event.target.value.toUpperCase()
      const testValue = value.match(/^[A-Za-z]+$/g)
      if (!testValue && value?.length) {
        formErros = {
          ...formErros,
          [inputName]: { error: true, message: 'Somete Letras' }
        }
      }
      if (testValue && value?.length) {
        formErros = {
          ...formErros,
          [inputName]: undefined
        }
      }
      setFormErros(formErros)
      setInputValue(value.replace(/[\d]/g, ''))
    },
    []
  )

  useEffect(() => {
    if (hasConfirmed) {
      Array.from(window.document.getElementsByClassName('btn')).forEach(
        element => {
          if (
            [...element.classList.values()].some(item =>
              item.includes('not-exclude')
            )
          ) {
            return element
          }
          if (element.getAttribute('type') === 'submit') {
            element.remove()
          }
        }
      )
    }
  }, [hasConfirmed])

  return (
    <div ref={titlePageRef}>
      <Loading isActive={loading} />
      <div
        className="row gy-5 g-xl-8"
        data-kt-sticky="true"
        data-kt-sticky-name="docs-sticky-summary"
        data-kt-sticky-width="100vw"
        data-kt-sticky-left="auto"
        data-kt-sticky-top="0px"
        data-kt-sticky-animation="true"
        data-kt-sticky-zindex="95"
      >
        <ContainerHeader>
          <WrapperContent>
            <ContainerLogo>
              <a href="/">
                <img
                  alt="Logo"
                  src={logo}
                  className="w-150px logo"
                  style={{ objectFit: 'cover' }}
                />
              </a>
            </ContainerLogo>
            <div>
              <button
                className="btn btn-primary"
                onClick={() => history.push('/commercial/contracts/forms')}
              >
                Salvar e fechar
              </button>
            </div>
          </WrapperContent>
        </ContainerHeader>
      </div>
      <ContainerWrapperContent>
        <div className="row gy-5 g-xl-8">
          <h1>Nova Solicitação De Passaporte</h1>
          <p>
            Não é possível salvar os dados, por isso o formulário deverá ser
            totalmente preenchido e enviado em ocasião única. As informações
            podem ser perdidas em caso de problemas de rede, internet etc.
            Preencha todos os dados, caso axlum não se aplique a você, escreva
            N/A.
          </p>
          <div className="card px-10 py-3 mt-3">
            <Tabs
              classname="justify-content-between"
              subtitle="(*) Preenchimento Obrigatório"
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              onePage={hasConfirmed}
            >
              <Tab notClick title="Dados Pessoais" key={0}>
                <div className="form mt-5">
                  <Form
                    className="form"
                    onSubmit={handleNextForm}
                    defaultValues={dataForm}
                  >
                    <h1 className="mb-5">Dados Pessoais</h1>
                    <div className="row mb-5">
                      <Input
                        className="col-xl-9"
                        label="Nome completo*:"
                        labelOptions={{ classname: 'col-xl-4' }}
                        inputOptions={{ classname: 'col-xl-8' }}
                        name="full_name"
                        hasCustom
                        rules={{
                          required: changeTab !== 'previous',
                          minLength: {
                            value: 10,
                            message: 'Digite Nome e Sobrenome Completos'
                          }
                        }}
                      />
                      <Select
                        className="col-xl-3"
                        label="Sexo*:"
                        labelOptions={{ classname: 'col-xl-4' }}
                        selectOptions={{ classname: 'col-xl-8' }}
                        name="gender"
                        options={optionsValue?.gender || []}
                        onChange={event =>
                          setParentage({
                            ...parentage,
                            gender: event.target.value
                          })
                        }
                        controlled
                        value={parentage?.gender || ''}
                        blank
                        rules={{ required: changeTab !== 'previous' }}
                        hasCustom
                      />
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl-9"
                        label="Filiação 1*:"
                        labelOptions={{ classname: 'col-xl-4' }}
                        inputOptions={{ classname: 'col-xl-8' }}
                        name="parentage.0.name"
                        rules={{
                          required: true,
                          minLength: {
                            value: 10,
                            message: 'Digite Nome e Sobrenome Completos'
                          }
                        }}
                        hasCustom
                      />
                      <Select
                        className="col-xl-3"
                        label="Sexo*:"
                        labelOptions={{ classname: 'col-xl-4' }}
                        selectOptions={{ classname: 'col-xl-8' }}
                        name="parentage.0.gender"
                        options={optionsValue?.gender || []}
                        onChange={event =>
                          setParentage({
                            ...parentage,
                            0: event.target.value
                          })
                        }
                        controlled
                        value={parentage?.[0] || ''}
                        blank
                        hasCustom
                        rules={{ required: true }}
                      />
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl-9"
                        label="Filiação 2:"
                        labelOptions={{ classname: 'col-xl-4' }}
                        inputOptions={{ classname: 'col-xl-8' }}
                        name="parentage.1.name"
                        hasCustom
                      />
                      <Select
                        className="col-xl-3"
                        label="Sexo:"
                        labelOptions={{ classname: 'col-xl-4' }}
                        selectOptions={{ classname: 'col-xl-8' }}
                        name="parentage.1.gender"
                        options={optionsValue?.gender || []}
                        onChange={event =>
                          setParentage({
                            ...parentage,
                            1: event.target.value
                          })
                        }
                        controlled
                        value={parentage?.[1] || ''}
                        blank
                        hasCustom
                      />
                    </div>
                    <div className="row mb-5">
                      <DatePicker
                        locale="ptBR"
                        name="birth_date"
                        className="col-xl"
                        labelOptions={{
                          classname: 'col-xl-8',
                          styles: { maxWidth: '282px' }
                        }}
                        inputOptions={{ classname: 'col-xl-4' }}
                        label="Data de nascimento*:"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/AAAA"
                        hasCustom
                        selected={birthDate}
                        rules={{ required: changeTab !== 'previous' }}
                        maxDate={new Date()}
                        customInput={
                          <IMaskInput
                            mask={Date}
                            pattern={'d/m/Y'}
                            format={date => {
                              return moment(date).format(inputMaskFormat)
                            }}
                            parse={value => {
                              return moment(value, inputMaskFormat).toDate()
                            }}
                            validate={(date: string) => {
                              if (date?.length === 10) {
                                if (
                                  moment(date, inputMaskFormat).isAfter(
                                    moment()
                                  )
                                ) {
                                  return false
                                }
                                const age = moment().diff(
                                  moment(date, inputMaskFormat),
                                  'year'
                                )
                                setAge(age)
                                if (age < 18) {
                                  setDisableCheckboxes({
                                    ...disableCheckboxes,
                                    emancipated: false,
                                    internationalAdoption: false
                                  })
                                } else {
                                  setDisableCheckboxes({
                                    ...disableCheckboxes,
                                    emancipated: true,
                                    internationalAdoption: true
                                  })
                                  setCheckEmancipated(false)
                                  setCheckInternationalAdoption(false)
                                }
                              }
                              return true
                            }}
                          />
                        }
                        onChange={date => {
                          setBirthDate(date)
                        }}
                        controlled
                      />
                      <div className="col-xl-3 mb-5">
                        <label className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input me-5"
                            name="emancipated"
                            type="checkbox"
                            checked={checkEmancipated}
                            onChange={() =>
                              setCheckEmancipated(!checkEmancipated)
                            }
                            disabled={disableCheckboxes.emancipated}
                          />
                          <span className="form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2">
                            Emancipado
                          </span>
                        </label>
                      </div>
                      <div className="col-xl-3">
                        <label className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input me-5"
                            type="checkbox"
                            name="international_adoption"
                            checked={checkInternationalAdoption}
                            onChange={() =>
                              setCheckInternationalAdoption(
                                !checkInternationalAdoption
                              )
                            }
                            disabled={disableCheckboxes.internationalAdoption}
                          />
                          <span className="form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2">
                            Adoção Internacional
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="row mb-5">
                      <Select
                        className="col-xl-8"
                        label="Raça ou cor*:"
                        labelOptions={{
                          classname: 'col-xl-6',
                          styles: { maxWidth: '282px' }
                        }}
                        selectOptions={{ classname: 'col-xl-7' }}
                        name="race_color"
                        hasCustom
                        rules={{ required: changeTab !== 'previous' }}
                        options={optionsValue?.raceColor || []}
                        blank
                        onChange={event => setRaceColor(event.target.value)}
                        controlled
                        value={raceColor || ''}
                      />
                      <Select
                        className="col-xl-4"
                        label="Estado civil:"
                        labelOptions={{ classname: 'col-xl-4' }}
                        selectOptions={{ classname: 'col-xl-8' }}
                        name="marital_status"
                        hasCustom
                        options={optionsValue?.maritalStatus || []}
                        onChange={event => setMaritalStatus(event.target.value)}
                        controlled
                        value={maritalStatus || ''}
                        blank
                      />
                    </div>
                    <div className="row mb-5">
                      <Select
                        className="col-xl"
                        label="Nacionalidade*:"
                        labelOptions={{
                          classname: 'col-xl-6',
                          styles: { maxWidth: '282px' }
                        }}
                        selectOptions={{
                          classname: 'col-xl-7 mw-xl-425px pe-xl-0'
                        }}
                        name="nationality"
                        hasCustom
                        rules={{ required: changeTab !== 'previous' }}
                        options={[
                          {
                            name: 'APATRIDA',
                            value: 'APATRIDA'
                          },
                          ...countries
                        ]}
                        onChange={event =>
                          handleSelectNationality(event.target.value)
                        }
                        value={nationalityValue}
                        controlled
                      />
                      <div className="col-xl-4">
                        <p>
                          (para brasileiros com múltipla nacionalidade,
                          selecionar Brasil)
                        </p>
                      </div>
                    </div>

                    <h1 className="mb-5">Local de nascimento</h1>
                    <div className="row mb-5">
                      <Select
                        className="col-xl-9"
                        label="País*:"
                        labelOptions={{
                          classname: 'col-xl-4',
                          styles: { maxWidth: '282px' }
                        }}
                        selectOptions={{ classname: 'col-xl-8' }}
                        name="country"
                        hasCustom
                        rules={{
                          required:
                            changeTab !== 'previous' &&
                            !countryValue?.country?.length
                        }}
                        options={countries}
                        onChange={handleSelectCountry}
                        value={countryValue?.country || ''}
                        controlled
                        blank
                      />
                      <Select
                        className="col-xl-3"
                        label="UF*:"
                        labelOptions={{ classname: 'col-xl-4' }}
                        selectOptions={{ classname: 'col-xl-8' }}
                        name="state"
                        hasCustom
                        rules={{
                          required:
                            countryValue?.country?.toLowerCase() === 'brasil' &&
                            changeTab !== 'previous'
                        }}
                        options={states}
                        disabled={
                          countryValue?.country?.toLowerCase() !== 'brasil'
                        }
                        onChange={handleSelectState}
                        value={selectedValue?.state || ''}
                        controlled
                        blank
                      />
                    </div>
                    <div className="row mb-5">
                      <Select
                        className="col-xl-9"
                        label="Cidade*:"
                        labelOptions={{ classname: 'col-xl-4' }}
                        selectOptions={{
                          classname: 'col-xl-8 '
                        }}
                        name={
                          countryValue?.country?.toLowerCase() !== 'brasil'
                            ? ''
                            : 'city'
                        }
                        hasCustom
                        rules={{
                          required:
                            countryValue?.country?.toLowerCase() === 'brasil' &&
                            changeTab !== 'previous'
                        }}
                        options={cities?.state || []}
                        onChange={event => setCity(event.target.value)}
                        controlled
                        value={city || ''}
                        disabled={
                          countryValue?.country?.toLowerCase() !== 'brasil'
                        }
                        blank
                      />
                    </div>
                    {countryValue?.country?.toLowerCase() !== 'brasil' && (
                      <div className="row mb-5">
                        <Input
                          className="col-xl-9"
                          label="Nome da Cidade*:"
                          labelOptions={{
                            classname: 'col-xl-4',
                            styles: { maxWidth: '282px' }
                          }}
                          inputOptions={{ classname: 'col-xl-8' }}
                          name="city"
                          hasCustom
                          rules={{ required: true }}
                        />
                      </div>
                    )}
                    <div className="row d-flex mb-5">
                      <div className="col-xl " style={{ maxWidth: '230px' }}>
                        <h1>Nomes Anteriores</h1>
                      </div>
                      <TooltipComponent
                        label=""
                        message={`<p style="max-width: 300px">
                          Preenchimento obrigatório para requerente que já alterou o nome em razão de casamento, 
                          separação, divórcio ou decisão judicial. Nesses casos, será obrigatória a apresentação da Certidão de Casamento,
                          com as devidas averbações, ou a decisão judicial respectiva. Caso nunca tenha alterado o nome, não preencher.
                        </p>`}
                        classname="col-xl"
                      />
                    </div>

                    <p className="mb-3">
                      Para cada nome anterior, digite o nome, escolha o motivo
                      da mudança e clique em Adicionar.
                    </p>
                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="Nome anterior:"
                        labelOptions={{
                          classname: 'col-xl-6',
                          styles: { maxWidth: '282px' }
                        }}
                        inputOptions={{ classname: 'col-xl-6' }}
                        name=""
                        hasCustom
                        hasError={previousFormErros?.name}
                        rules={{
                          minLength: {
                            value: 10,
                            message: 'Digite Nome e Sobrenome Completos'
                          }
                        }}
                        onChange={event => {
                          setPreviousFormErros({
                            ...previousFormErros,
                            name: undefined
                          })
                          setPreviousNameValue(event.target.value)
                        }}
                        controlled
                        value={previousNameValue}
                      />
                    </div>
                    <div className="row mb-5">
                      <Select
                        className="col-xl"
                        label="Motivo da mudança:"
                        labelOptions={{
                          classname: 'col-xl-6',
                          styles: { maxWidth: '282px' }
                        }}
                        selectOptions={{
                          classname: 'col-xl-7 mw-xl-425px pe-xl-0 mb-5'
                        }}
                        name=""
                        hasCustom
                        errors={previousFormErros?.reason}
                        onChange={event => {
                          setPreviousFormErros({
                            ...previousFormErros,
                            reason: undefined
                          })
                          setPreviousNameReasonValue(event.target.value)
                        }}
                        controlled
                        value={previousNameReasonValue}
                        blank
                        options={optionsValue?.reasonChange || []}
                      />
                      <div className="text-center col-xl-2 d-flex align-items-end justify-content-end">
                        <button
                          type="button"
                          className="btn btn-xl btn-primary mb-5"
                          onClick={handleVerifyPreviousNames}
                        >
                          <span className="indicator-label">Adicionar</span>
                        </button>
                      </div>
                    </div>
                    {!!previousNames?.length && (
                      <div className="row mb-5">
                        <div className="table-responsive">
                          <Table
                            id="kt_datatable"
                            className="table table-striped table-row-bordered gs-3 border table-hover dataTable"
                          >
                            <thead className="border-bottom border-end">
                              <tr>
                                <th style={{ minWidth: '280px' }}>Nome</th>
                                <th>Motivo</th>
                                <th>Ação</th>
                              </tr>
                            </thead>
                            <tbody>
                              {previousNames.map(previousName => (
                                <React.Fragment key={Math.random() * 9999}>
                                  <Row>
                                    <td data-label="Nome">
                                      {previousName?.name}
                                    </td>
                                    <td data-label="Motivo">
                                      {previousName?.reason}
                                    </td>
                                    <td>
                                      <LinkContainer>
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-primary mb-5"
                                          onClick={() =>
                                            handleRemovePreviousName(
                                              previousName
                                            )
                                          }
                                        >
                                          Remover
                                        </button>
                                      </LinkContainer>
                                    </td>
                                  </Row>
                                </React.Fragment>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    )}

                    <div className="separator border-2 my-6"></div>

                    {/* <div className="row  d-flex align-items-end justify-content-end">
                      <div className="text-center col-xl-2 d-flex align-items-end justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-xl btn-primary mb-5"
                        >
                          <span className="indicator-label">Próximo</span>
                        </button>
                      </div>
                    </div> */}
                  </Form>
                </div>
              </Tab>
              <Tab notClick title="Documentos" key={1}>
                <div className="form mt-5">
                  <Form
                    className="form"
                    onSubmit={handleNextForm}
                    defaultValues={dataForm}
                  >
                    <div className="row mb-5 d-flex">
                      <div className="col-xl " style={{ maxWidth: '330px' }}>
                        <h2>Documentos de Identificação</h2>
                      </div>
                      <TooltipComponent
                        label=""
                        message={`<p style="max-width: 300px">
                        Obrigatório o preenchimento a partir dos 12 anos de idade. Podem servir como documento de identificação: 
                        cédula de identidade (RG); carteira de habilitação - CNH(modelo atual); carteira de trabalho - CTPS; 
                        carteira de identidade militar, dos Bombeiros ou da Polícia Militar; passaporte brasileiro anterior; 
                        carteira funcional expedida por Órgão público, reconhecida por lei federal como válida em todo território nacional e 
                        carteira de identidade expedida por Órgão fiscalizador do exercício de profissão regulamentada por lei. 
                        ATENÇÃO: preencher conforme o documento de identificação que será apresentado no dia do atendimento presencial.
                        </p>`}
                        classname="col-xl"
                      />
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl-8"
                        label="Número:"
                        labelOptions={{
                          classname: 'col-xl-5',
                          styles: { maxWidth: '282px' }
                        }}
                        inputOptions={{ classname: 'col-xl-7' }}
                        name="document_number"
                        hasCustom
                        value={documentNumber || ''}
                        controlled
                        onChange={event =>
                          setDocumentNumber(event.target.value)
                        }
                        maxLength={20}
                      />
                      <DatePicker
                        locale="ptBR"
                        name="issue_date"
                        className="col-xl-4"
                        labelOptions={{
                          classname: 'col-xl-6',
                          styles: { maxWidth: '282px' }
                        }}
                        inputOptions={{ classname: 'col-xl-6' }}
                        label="Data de emissão:"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/AAAA"
                        hasCustom
                        selected={issueDate}
                        customInput={
                          <IMaskInput
                            mask={Date}
                            pattern={'d/m/Y'}
                            format={date => {
                              return moment(date).format(inputMaskFormat)
                            }}
                            parse={value => {
                              return moment(value, inputMaskFormat).toDate()
                            }}
                          />
                        }
                        onChange={date => {
                          setIssueDate(date)
                        }}
                        controlled
                      />
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl-8"
                        label="Órgão emissor:"
                        labelOptions={{
                          classname: 'col-xl-5',
                          styles: { maxWidth: '282px' }
                        }}
                        inputOptions={{ classname: 'col-xl-7' }}
                        name="issuing_body"
                        hasCustom
                        controlled
                        value={issuingBody || ''}
                        onChange={event => setIssuingBody(event.target.value)}
                        maxLength={10}
                      />
                      <Select
                        className="col-xl-4"
                        label="UF de expedição:"
                        labelOptions={{ classname: 'col-xl-6' }}
                        selectOptions={{ classname: 'col-xl-6' }}
                        name="state_expedition"
                        options={states}
                        blank
                        controlled
                        value={stateExpedition || ''}
                        onChange={event =>
                          setStateExpedition(event.target.value)
                        }
                        hasCustom
                      />
                    </div>
                    <div className="row mb-5">
                      <div className="col-xl" style={{ maxWidth: '230px' }}>
                        <h1>CPF</h1>
                      </div>
                    </div>

                    <div className="row mb-5">
                      <Input
                        className="col-xl-6"
                        label="CPF*:"
                        labelOptions={{ classname: 'col-xl-6' }}
                        inputOptions={{ classname: 'col-xl-6' }}
                        name="cpf"
                        hasCustom
                        onChange={handleInputCpfValue}
                        value={dataForm?.cpf || ''}
                        hasError={inputsErrors?.cpf}
                        maxLength={14}
                        rules={{
                          required: changeTab !== 'previous',
                          minLength:
                            changeTab !== 'previous'
                              ? {
                                  value: 14,
                                  message: 'Campo Invalido'
                                }
                              : undefined
                        }}
                      />
                      <Input
                        className="col-xl-6"
                        label={
                          age < 18
                            ? 'CPF do responsável*:'
                            : 'CPF do responsável:'
                        }
                        labelOptions={{ classname: 'col-xl-4' }}
                        inputOptions={{ classname: 'col-xl-8' }}
                        name="cpf_responsible"
                        hasCustom
                        onChange={handleInputCpfValue}
                        value={dataForm?.cpf_responsible || ''}
                        hasError={inputsErrors?.cpf_responsible}
                        maxLength={14}
                        rules={{
                          required: changeTab !== 'previous' && age < 18,
                          minLength:
                            changeTab !== 'previous' && age < 18
                              ? {
                                  value: 14,
                                  message: 'Campo Invalido'
                                }
                              : undefined
                        }}
                      />
                    </div>
                    <div className="row mb-5">
                      <div className="col-xl" style={{ maxWidth: '130px' }}>
                        <h1 className="mb-5">Certidão</h1>
                      </div>
                      <TooltipComponent
                        label=""
                        message={`<p style="max-width: 300px">
                        O preenchimento deste campo é obrigatório somente para menor de 12 anos (Certidão de Nascimento) que ainda não tenha um dos documentos de identificação descritos na 'lista de documentos'. Nos casos de alteração de nome (casamento/separação/divórcio), recomenda-se o preenchimento com os dados da certidão que traz a última alteração realizada.
                        </p>`}
                        classname="col-xl"
                      />
                    </div>

                    <div className="row mb-5">
                      <div className="col-xl-3">
                        <label className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input me-5"
                            type="checkbox"
                            name="new_model_certificate"
                            checked={checkNewModel}
                            onChange={() => {
                              if (!checkNewModel) {
                                setDataForm({
                                  ...dataForm,
                                  certificate: {
                                    ...dataForm.certificate,
                                    type: '',
                                    number: '',
                                    book: '',
                                    sheet: ''
                                  }
                                })
                                setCertificateType('')
                                setCertificateNumber('')
                              } else {
                                setDataForm({
                                  ...dataForm,
                                  certificate: {
                                    ...dataForm.certificate,
                                    enrollment: ''
                                  }
                                })
                              }
                              setCheckNewModel(!checkNewModel)
                            }}
                          />
                          <span className="form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2">
                            Certidão modelo novo?
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl-6"
                        label={checkNewModel ? 'Matrícula*:' : 'Matrícula:'}
                        labelOptions={{ classname: 'col-xl-6' }}
                        inputOptions={{ classname: 'col-xl-6' }}
                        name="certificate.enrollment"
                        hasCustom
                        disabled={!checkNewModel}
                      />
                    </div>
                    <div className="row mb-5">
                      <Select
                        className="col-xl-6"
                        label={!checkNewModel ? 'Tipo*:' : 'Tipo:'}
                        labelOptions={{
                          classname: 'col-xl-6',
                          styles: { maxWidth: '282px' }
                        }}
                        selectOptions={{ classname: 'col-xl-6' }}
                        name="certificate.type"
                        hasCustom
                        disabled={checkNewModel}
                        options={optionsValue?.cetificationType || []}
                        controlled
                        value={certificateType || ''}
                        onChange={event =>
                          setCertificateType(event.target.value)
                        }
                        rules={{
                          required: changeTab !== 'previous' && !checkNewModel
                        }}
                        blank
                      />
                      <Input
                        className="col-xl-3"
                        label={!checkNewModel ? 'Número*:' : 'Número:'}
                        labelOptions={{ classname: 'col-xl-4' }}
                        inputOptions={{ classname: 'col-xl-8' }}
                        name="certificate.number"
                        hasCustom
                        disabled={checkNewModel}
                        hasError={documentFormErros?.['certificate.number']}
                        onChange={event =>
                          handleVerifyInputNumber(
                            event,
                            documentFormErros,
                            setDocumentFormErros,
                            setCertificateNumber
                          )
                        }
                        controlled
                        value={certificateNumber || ''}
                        maxLength={8}
                        rules={{
                          required:
                            documentFormErros?.['certificate.number']
                              ?.message ||
                            (changeTab !== 'previous' && !checkNewModel)
                        }}
                      />
                      <Input
                        className="col-xl-3 pe-xl-0"
                        label={!checkNewModel ? 'Livro*:' : 'Livro:'}
                        labelOptions={{ classname: 'col-xl-4' }}
                        inputOptions={{
                          classname: 'col-xl-8 mw-xl-330px pe-xl-0'
                        }}
                        name="certificate.book"
                        hasCustom
                        disabled={checkNewModel}
                        maxLength={3}
                        rules={{
                          required: changeTab !== 'previous' && !checkNewModel
                        }}
                      />
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl-6"
                        label={!checkNewModel ? 'Folha*:' : 'Folha:'}
                        labelOptions={{ classname: 'col-xl-6' }}
                        inputOptions={{ classname: 'col-xl-6' }}
                        name="certificate.sheet"
                        hasCustom
                        disabled={checkNewModel}
                        rules={{
                          required: changeTab !== 'previous' && !checkNewModel
                        }}
                        maxLength={5}
                      />
                      <Input
                        className="col-xl-6"
                        label="Cartório*:"
                        labelOptions={{
                          classname: 'col-xl-2',
                          styles: { maxWidth: '90px' }
                        }}
                        inputOptions={{ classname: 'col-xl-10' }}
                        name="certificate.registry"
                        rules={{ required: changeTab !== 'previous' }}
                        hasCustom
                      />
                    </div>
                    <div className="row mb-5">
                      <Select
                        className="col-xl"
                        label="UF de Expedição*:"
                        labelOptions={{
                          classname: 'col-xl-6',
                          styles: { maxWidth: '282px' }
                        }}
                        selectOptions={{ classname: 'col-xl-6' }}
                        name="certificate.state_expedition"
                        onChange={handleSelectState}
                        value={
                          selectedValue?.['certificate.state_expedition'] || ''
                        }
                        hasCustom
                        options={states}
                        controlled
                        rules={{ required: changeTab !== 'previous' }}
                        blank
                      />
                      <Select
                        className="col-xl"
                        label="Cidade expedição*:"
                        labelOptions={{ classname: 'col-xl-4' }}
                        selectOptions={{
                          classname: 'col-xl-8 mw-xl-330px pe-xl-0'
                        }}
                        name="certificate.city_expedition"
                        hasCustom
                        options={cities?.['certificate.state_expedition'] || []}
                        value={cityExpedition || ''}
                        controlled
                        onChange={event =>
                          setCityExpedition(event.target.value)
                        }
                        rules={{ required: changeTab !== 'previous' }}
                        blank
                      />
                    </div>
                    <div className="row mb-5">
                      <div className="col-xl" style={{ maxWidth: '260px' }}>
                        <h1 className="mb-5">Passaporte Anterior</h1>
                      </div>
                      <TooltipComponent
                        label=""
                        message={`<p style="max-width: 300px">
                        A apresentação do passaporte anterior válido é obrigatória para requerente que já teve passaporte 
                        expedido pelo governo brasileiro em seu nome, sob pena de pagamento de taxa majorada. Em regra, o passaporte 
                        anterior (válido ou não) será cancelado e devolvido ao titular. Caso nunca tenha tido passaporte, marcar a opção: 
                        "Nunca teve passaporte comum, de emergência, para estrangeiro ou Laissez-Passer brasileiro" Caso a informação do 
                        campo "posse" referente ao passaporte anterior seja do tipo "Foi extraviado" , a solicitação estará 
                        sujeita ao pagamento de taxa majorada."
                        </p>`}
                        classname="col-xl"
                      />
                    </div>

                    <div className="row mb-5">
                      <Select
                        className="col-xl"
                        label="Situação*:"
                        labelOptions={{
                          classname: 'col-xl-6',
                          styles: { maxWidth: '282px' }
                        }}
                        selectOptions={{ classname: 'col-xl-6' }}
                        name="previousPassport.situation"
                        hasCustom
                        rules={{ required: changeTab !== 'previous' }}
                        options={optionsValue?.previousPassportSituation || []}
                        controlled
                        value={previousPassportSituation}
                        onChange={event => {
                          if (
                            event.target.value ===
                            'Nunca teve Passaporte Comum, de Emergência, para Estrangeiro ou Laissez-Passer brasileiro'
                          ) {
                            setPreviousPassportHasUpdatename(undefined)
                            setDataForm({
                              ...dataForm,
                              previousPassport: {
                                ...dataForm.previousPassport,
                                series: '',
                                number: ''
                              }
                            })
                          }
                          if (checkPreviousSituation(event.target.value)) {
                            setDataForm({
                              ...dataForm,
                              previousPassport: {
                                ...dataForm.previousPassport,
                                series: '',
                                number: ''
                              }
                            })
                            setSeriesValue('')
                            setPreviousPassportNumber('')
                          }
                          setPreviousPassportSituation(event.target.value)
                        }}
                        blank
                      />
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl-5"
                        label="Série:"
                        labelOptions={{
                          classname: 'col-xl-10',
                          styles: { maxWidth: '282px' }
                        }}
                        inputOptions={{
                          classname: 'col-xl-3',

                          styles: {
                            paddingRight: 0
                          }
                        }}
                        name="previousPassport.series"
                        hasCustom
                        disabled={checkPreviousSituation()}
                        maxLength={2}
                        hasError={
                          documentFormErros?.['previousPassport.series']
                        }
                        onChange={event =>
                          handleVerifyInputText(
                            event,
                            setDocumentFormErros,
                            setSeriesValue
                          )
                        }
                        value={seriesValue || ''}
                        rules={{
                          required:
                            documentFormErros?.['previousPassport.series']
                              ?.message
                        }}
                      />
                      <Input
                        className="col-xl-3"
                        label="Número:"
                        labelOptions={{
                          classname: 'col-xl-6',
                          styles: { maxWidth: '282px' }
                        }}
                        inputOptions={{ classname: 'col-xl-6' }}
                        name="previousPassport.number"
                        hasCustom
                        disabled={checkPreviousSituation()}
                        hasError={
                          documentFormErros?.['previousPassport.number']
                        }
                        onChange={event =>
                          handleVerifyInputNumber(
                            event,
                            documentFormErros,
                            setDocumentFormErros,
                            setPreviousPassportNumber
                          )
                        }
                        value={previousPassportNumber || ''}
                        rules={{
                          required:
                            documentFormErros?.['previousPassport.number']
                              ?.message
                        }}
                        maxLength={6}
                      />
                      <div className="col-xl-4">
                        <p>
                          (Exemplo: Para o passaporte CP999999 a série é CP e o
                          número é 999999.)
                        </p>
                      </div>
                    </div>
                    {previousPassportSituation !== '' &&
                      previousPassportSituation !==
                        'Nunca teve Passaporte Comum, de Emergência, para Estrangeiro ou Laissez-Passer brasileiro' && (
                        <div className="row mb-5">
                          <h3 className="mb-5">
                            Houve alteração em seu nome/sobrenome por motivo de
                            casamento, divórcio ou decisão judicial, após a
                            expedição do último passaporte?
                          </h3>
                          <div className="d-flex">
                            <Checkbox
                              hasCustom
                              label="Sim"
                              name=""
                              onChange={() =>
                                setPreviousPassportHasUpdatename(true)
                              }
                              checked={previousPassportHasUpdateName}
                              controlled
                            />
                            <Checkbox
                              hasCustom
                              label="Não"
                              name=""
                              onChange={() =>
                                setPreviousPassportHasUpdatename(false)
                              }
                              checked={previousPassportHasUpdateName === false}
                              required
                              controlled
                            />
                            {previousPassportHasUpdateName === undefined &&
                              hasErroUpdateName && (
                                <Error title="Uma Das Opção Deve Ser Escolhida">
                                  <FiAlertCircle color="#c53030" size={20} />
                                </Error>
                              )}
                          </div>
                        </div>
                      )}
                    <div className="separator border-2 my-6"></div>
                    {/* <div className="row d-flex align-items-end justify-content-between">
                      <div className="text-center col-xl-2 mw-100px d-flex align-items-end justify-content-start">
                        <button
                          type="submit"
                          onClick={() => setChangeTab('previous')}
                          className="btn btn-xl btn-primary mb-5"
                        >
                          <span className="indicator-label">Anterior</span>
                        </button>
                      </div>
                      <div className="text-center col-xl-2 mw-100px d-flex align-items-end justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-xl btn-primary mb-5"
                        >
                          <span className="indicator-label">Próxima</span>
                        </button>
                      </div>
                    </div> */}
                  </Form>
                </div>
              </Tab>
              <Tab notClick title="Dados Complementares" key={2}>
                <div className="form mt-5">
                  <Form
                    className="form"
                    onSubmit={handleNextForm}
                    defaultValues={dataForm}
                  >
                    <h1 className="mb-5">Dados complementares</h1>
                    <div className="row mb-5">
                      <Select
                        className="col-xl-12"
                        label="Profissão*:"
                        labelOptions={{ classname: 'col-xl-5' }}
                        selectOptions={{ classname: 'col-xl-6' }}
                        name="profession"
                        options={optionsValue?.profession || []}
                        onChange={event => setProfession(event.target.value)}
                        controlled
                        value={profession || ''}
                        blank
                        rules={{ required: changeTab !== 'previous' }}
                        hasCustom
                      />
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl-12"
                        label="E-mail*:"
                        labelOptions={{ classname: 'col-xl-5' }}
                        inputOptions={{ classname: 'col-xl-6' }}
                        name="email"
                        hasCustom
                        // controlled
                        // value={emailValue || ''}
                        // onChange={event => {
                        //   const value = event.target.value
                        //   handleVerifyEmail(value)
                        //   setEmailValue(value)
                        // }}
                        hasError={inputsErrors?.confirmEmail}
                        rules={{
                          required: changeTab !== 'previous',
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: 'Email inválido'
                          }
                        }}
                      />
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl-12"
                        label="Confirmação do E-mail*:"
                        labelOptions={{ classname: 'col-xl-5' }}
                        inputOptions={{ classname: 'col-xl-6' }}
                        name="email_confirmation"
                        hasCustom
                        // controlled
                        // value={confirmEmailValue || ''}
                        // onChange={event => {
                        //   const value = event.target.value
                        //   handleVerifyEmail(value)
                        //   setConfirmEmailValue(value)
                        // }}
                        onPaste={event => {
                          setConfirmEmailValue('')
                          event.preventDefault()
                        }}
                        hasError={inputsErrors?.confirmEmail}
                        rules={{
                          required: changeTab !== 'previous',
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: 'Email inválido'
                          }
                        }}
                      />
                    </div>
                    <h1 className="mb-5">Endereço do requerente</h1>
                    <div className="row mb-5">
                      <Select
                        className="col-xl"
                        label="País*:"
                        labelOptions={{
                          classname: 'col-xl-6',
                          styles: { maxWidth: '470px' }
                        }}
                        selectOptions={{ classname: 'col-xl-6' }}
                        name="applicant.country"
                        hasCustom
                        rules={{ required: changeTab !== 'previous' }}
                        options={countries}
                        onChange={handleSelectCountry}
                        value={countryValue?.['applicant.country'] || ''}
                        controlled
                        blank
                      />
                    </div>
                    <div className="row mb-5 d-flex justify-content-between">
                      <Input
                        className="col-xl"
                        label="CEP:"
                        labelOptions={{
                          classname: 'col-xl-10',
                          styles: { maxWidth: '470px' }
                        }}
                        inputOptions={{ classname: 'col-xl-3' }}
                        name="applicant.zip_code"
                        hasCustom
                        onChange={event => getDataCep(event.target.value)}
                        value={dataForm?.applicant?.zip_code || ''}
                        maxLength={10}
                      />
                      <Select
                        className="col-xl-6 mw-xl-450px"
                        label="UF:"
                        labelOptions={{
                          classname: 'col-xl-3',
                          styles: { maxWidth: '378px' }
                        }}
                        selectOptions={{
                          classname: 'col-xl-6 mw-xl-210px'
                        }}
                        name="applicant.state"
                        controlled
                        onChange={handleSelectState}
                        value={selectedValue?.['applicant.state'] || ''}
                        hasCustom
                        disabled={
                          countryValue?.['applicant.country']?.toLowerCase() !==
                          'brasil'
                        }
                        options={states}
                        blank
                      />
                    </div>
                    <div className="row mb-5">
                      <Select
                        className="col-xl-12"
                        label="Cidade:"
                        labelOptions={{
                          classname: 'col-xl-5',
                          styles: { maxWidth: '470px' }
                        }}
                        selectOptions={{ classname: 'col-xl-6' }}
                        name={
                          countryValue?.['applicant.country']?.toLowerCase() !==
                          'brasil'
                            ? ''
                            : 'applicant.city'
                        }
                        hasCustom
                        options={cities?.['applicant.state'] || []}
                        disabled={
                          countryValue?.['applicant.country']?.toLowerCase() !==
                          'brasil'
                        }
                        onChange={event =>
                          setApplicant({
                            ...applicant,
                            city: event.target.value
                          })
                        }
                        controlled
                        value={
                          countryValue?.['applicant.country']?.toLowerCase() !==
                          'brasil'
                            ? ''
                            : applicant?.city || ''
                        }
                        blank
                      />
                    </div>
                    {countryValue?.['applicant.country']?.toLowerCase() !==
                      'brasil' && (
                      <div className="row mb-5">
                        <Input
                          className="col-xl-12"
                          label="Nome da Cidade:"
                          labelOptions={{
                            classname: 'col-xl-5',
                            styles: { maxWidth: '470px' }
                          }}
                          inputOptions={{ classname: 'col-xl-6' }}
                          name="applicant.city"
                          controlled
                          hasCustom
                          value={applicant?.city || ''}
                          onChange={event =>
                            setApplicant({
                              ...applicant,
                              city: event.target.value
                            })
                          }
                        />
                      </div>
                    )}
                    <div className="row mb-5">
                      <Input
                        className="col-xl-12"
                        label="Logradouro:"
                        controlled
                        labelOptions={{
                          classname: 'col-xl-5',
                          styles: { maxWidth: '470px' }
                        }}
                        inputOptions={{ classname: 'col-xl-6' }}
                        name="applicant.street"
                        hasCustom
                        value={applicant?.street || ''}
                        onChange={event =>
                          setApplicant({
                            ...applicant,
                            street: event.target.value
                          })
                        }
                      />
                    </div>

                    <div className="row mb-5">
                      <Input
                        className="col-xl-12"
                        label="Distrito/Bairro:"
                        labelOptions={{
                          classname: 'col-xl-5',
                          styles: { maxWidth: '470px' }
                        }}
                        inputOptions={{ classname: 'col-xl-6' }}
                        name="applicant.district"
                        hasCustom
                        controlled
                        value={applicant?.district || ''}
                        onChange={event =>
                          setApplicant({
                            ...applicant,
                            district: event.target.value
                          })
                        }
                      />
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="DDD:"
                        labelOptions={{
                          classname: 'col-xl-10',
                          styles: { maxWidth: '470px' }
                        }}
                        inputOptions={{ classname: 'col-xl-2' }}
                        name="applicant.ddd"
                        hasCustom
                        maxLength={2}
                        hasError={supplementaryDataFormErros?.['applicant.ddd']}
                        onChange={event =>
                          handleVerifyInputNumber(
                            event,
                            supplementaryDataFormErros,
                            setSupplementaryDataFormErros,
                            value =>
                              setApplicant({
                                ...applicant,
                                ddd: value
                              })
                          )
                        }
                        rules={{
                          required:
                            documentFormErros?.['applicant.ddd']?.message
                        }}
                      />
                      <Input
                        className="col-xl-6 mw-xl-450px"
                        label="Telefone:"
                        labelOptions={{
                          classname: 'col-xl-3',
                          styles: { maxWidth: '378px' }
                        }}
                        inputOptions={{
                          classname: 'col-xl-6 mw-xl-210px'
                        }}
                        name="applicant.phone"
                        hasCustom
                        controlled
                        value={applicant?.phone || ''}
                        onChange={event =>
                          setApplicant({
                            ...applicant,
                            phone: phoneMask(event.target.value, {
                              notHasDDD: true
                            })
                          })
                        }
                        maxLength={10}
                      />
                    </div>
                    <div className="separator border-2 my-6"></div>
                    {/* <div className="row d-flex align-items-end justify-content-between">
                      <div className="text-center col-xl-2 mw-100px d-flex align-items-end justify-content-start">
                        <button
                          type="submit"
                          onClick={() => setChangeTab('previous')}
                          className="btn btn-xl btn-primary mb-5"
                        >
                          <span className="indicator-label">Anterior</span>
                        </button>
                      </div>
                      <div className="text-center col-xl-2 mw-100px d-flex align-items-end justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-xl btn-primary mb-5"
                        >
                          <span className="indicator-label">Próxima</span>
                        </button>
                      </div>
                    </div> */}
                  </Form>
                </div>
              </Tab>
              <Tab notClick title="Revisar Dados" key={3}>
                <div className="form mt-5">
                  <h1 className="mb-5">
                    Confira atentamente as informações abaixo:
                  </h1>
                  <p>
                    Caso detecte algum erro nos dados a seguir, volte ao campo
                    correspondente para corrigir. Erro em algum desses campos
                    implicará em ATRASO na entrega do seu passaporte. Seguindo o
                    padrão internacional ICAO, os sobrenomes poderão ser
                    abreviados na impressão do documento de viagem.
                  </p>
                  <div className="row mb-5">
                    <div className="col-xl">
                      <div className="form-group">
                        <label
                          htmlFor="id"
                          className="col-form-label fw-bold fs-6 form-label fw-bold fs-6"
                        >
                          Nome Completo
                        </label>
                        <p className="fw-bolder fs-6 text-gray-800">
                          {dataForm?.full_name}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className="col-xl">
                      <div className="form-group">
                        <label
                          htmlFor="id"
                          className="col-form-label fw-bold fs-6 form-label fw-bold fs-6"
                        >
                          Nacionalidade
                        </label>
                        <p className="fw-bolder fs-6 text-gray-800">
                          {dataForm?.nationality}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className="col-xl">
                      <div className="form-group">
                        <label
                          htmlFor="id"
                          className="col-form-label fw-bold fs-6 form-label fw-bold fs-6"
                        >
                          Data de Nascimento
                        </label>
                        <p className="fw-bolder fs-6 text-gray-800">
                          {moment(birthDate).format(inputMaskFormat)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className="col-xl">
                      <div className="form-group">
                        <label
                          htmlFor="id"
                          className="col-form-label fw-bold fs-6 form-label fw-bold fs-6"
                        >
                          Sexo
                        </label>
                        <p className="fw-bolder fs-6 text-gray-800">
                          {dataForm?.gender}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className="col-xl">
                      <div className="form-group">
                        <label
                          htmlFor="id"
                          className="col-form-label fw-bold fs-6 form-label fw-bold fs-6"
                        >
                          Naturalidade
                        </label>
                        <p className="fw-bolder fs-6 text-gray-800">
                          {dataForm?.city
                            ? dataForm?.state
                              ? `${dataForm?.city}/${dataForm?.state}`
                              : dataForm?.city
                            : ''}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className="col-xl">
                      <div className="form-group">
                        <label
                          htmlFor="id"
                          className="col-form-label fw-bold fs-6 form-label fw-bold fs-6"
                        >
                          Filiação
                        </label>
                        {dataForm?.parentage?.map(parentage => (
                          <p
                            key={parentage.name}
                            className="fw-bolder fs-6 text-gray-800"
                          >
                            {parentage.name}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                  <Form
                    onSubmit={
                      changeTab === 'previous' ? handleNextForm : onSubmit
                    }
                    defaultValues={dataForm}
                  >
                    <div className="row mb-5">
                      <div className="col-xl">
                        <label className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input me-5"
                            type="checkbox"
                            checked={confirmData}
                            onChange={() => setConfirmData(!confirmData)}
                          />
                          <span className="form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2">
                            Declaro que as informações acima estão corretas e
                            que estou ciente de que qualquer erro nos dados
                            implicará em atraso na emissão do meu documento de
                            viagem.
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="separator border-2 my-6"></div>

                    {/* <div className="row mb-10">
                      {hasConfirmed && (
                        <>
                          <h3 className="col-form-label fs-3 text-danger">
                            Ao clicar no botão CONFIRMAR a baixo, você confirmar
                            que todos os dados foram preenchidos corretamente. E
                            concorda que não tera mais acesso para editar ou
                            visualizar esse formulário
                          </h3>
                        </>
                      )}
                    </div> */}

                    <div className="row d-flex align-items-end justify-content-between">
                      <div className="text-center col-md-2 mw-100px d-flex align-items-end justify-content-start">
                        <button
                          type="submit"
                          className="btn btn-xl btn-primary mb-5"
                          onClick={() => setChangeTab('previous')}
                        >
                          <span className="indicator-label">Anterior</span>
                        </button>
                      </div>
                      {hasConfirmed ? (
                        <div className="text-center col-md-2 mw-100px d-flex align-items-end justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-xl btn-primary mb-5 not-exclude"
                          >
                            <span className="indicator-label">Salvar</span>
                          </button>
                        </div>
                      ) : (
                        <div className="text-center col-md-2 mw-100px d-flex align-items-end justify-content-end">
                          <button
                            type="button"
                            className="btn btn-xl btn-primary mb-5"
                            onClick={event => {
                              event.preventDefault()
                              setHasConfirmed(true)

                              if (titlePageRef?.current) {
                                titlePageRef?.current?.scrollIntoView?.({
                                  behavior: 'smooth'
                                })
                              }
                            }}
                          >
                            <span className="indicator-label">Resumo</span>
                          </button>
                        </div>
                      )}
                    </div>
                  </Form>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </ContainerWrapperContent>
      <Alert
        message={
          'Foi selecionada nacionalidade estrangeira, essa solicitação será para passaporte para estrangeiro (caderneta amarela). Caso seja brasileiro e deseje passaporte comum (caderneta azul), selecione a nacionalidade Brasil. Deseja prosseguir assim mesmo?'
        }
        onClickCancellButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={handlerClickButtonConfirmAlert}
        isActive={alert.isActive}
      />
    </div>
  )
}

export default Passport
