export const CONTRACT_PROGRESS = {
  AWAITING_FORMS_FILL: 1,
  FORMS_STARTED: 2,
  FORMS_FULL: 3
}
export const CONTRACT_PROGRESS_TRANSLATED = {
  1: 'FORMULÁRIOS NÃO PREENCHIDOS',
  2: 'FORMULÁRIOS INICIADOS',
  3: 'FORMULÁRIOS COMPLETOS'
}

export const CONTRACTS_PROGRESS_OPTIONS = Object.entries(
  CONTRACT_PROGRESS_TRANSLATED
).map(([key, value]) => ({
  name: value,
  value: key
}))
