import React from 'react'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

type Header = {
  name: string
  field: string
  custom?: boolean
  element?: (item: any) => JSX.Element
}
type Action = {
  name: string
  element?: (item: any) => JSX.Element
}
type Row = {
  [key: string]: unknown
}
interface ResponsiveTableProps {
  headers: Header[]
  rows: Row[]
}
export function ResponsiveTable({ headers, rows }: ResponsiveTableProps) {
  return (
    <Table className="dataTable table table-striped table-row-bordered gs-3 border table-hover">
      <Thead>
        <Tr>
          {headers?.map(header => (
            <Th className="border-bottom border-end" key={header.name}>
              {header.name}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {rows?.length ? (
          rows.map(row => (
            <Tr key={`${row.field}`}>
              {headers.map(header => {
                if (header.element) {
                  return (
                    <Td key={`${header.name}-${header.field}`}>
                      {header.element(row)}
                    </Td>
                  )
                }
                return (
                  <Td key={`${header.name}-${header.field}`}>
                    {row[header.field]}
                  </Td>
                )
              })}
            </Tr>
          ))
        ) : (
          <Tr>
            <Td colSpan={headers.length} style={{ textAlign: 'center' }}>
              Sem resultado
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  )
}
