import moment from 'moment'
import { useCallback, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { IMaskInput } from 'react-imask'
import {
  Checkbox,
  Input,
  InputProps,
  Select,
  SelectProps
} from '../../../../../../components/Form'
import { Date as DatePicker } from '../../../../../../components/Form/date'
import { ContainerAnimation } from './styles'

interface DatepickerProps {
  name: string
}

interface FormFamilyProps {
  datepickerBirthDate: DatepickerProps
  inputOccupation: InputProps
  inputName: InputProps
  inputAddress: InputProps
  inputCountry: InputProps
  inputRelationship?: InputProps
  selectMaritalStatus: SelectProps
  selectTravelWithYou: SelectProps
  label?: string
  type?: string
  positive?: InputProps
  negative?: InputProps
  index?: number
  data?: any[]
  setData?: React.Dispatch<React.SetStateAction<any[]>>
  defaultShow?: string
  tooltip?: {
    type: string
  }
  changeTab?: React.SetStateAction<string>
  hasHeader?: {
    label?: string
    positive?: InputProps
    negative?: InputProps
  }
}

export const FormFamily: React.FC<FormFamilyProps> = ({
  datepickerBirthDate,
  inputRelationship,
  inputOccupation,
  inputName,
  inputAddress,
  positive,
  negative,
  label,
  type,
  selectTravelWithYou,
  selectMaritalStatus,
  inputCountry,
  index,
  data = [],
  defaultShow = '',
  tooltip,
  setData,
  hasHeader,
  changeTab
}) => {
  const {
    register,
    watch,
    setValue,
    getValues,
    control,
    clearErrors,
    formState: { errors }
  } = useFormContext()
  const inputMaskFormat = 'DD/MM/YYYY'
  const [copyData, setCopyData] = useState([...data])
  const watchFieldDate = watch(datepickerBirthDate.name)
  const [birthDate, setBirthDate] = useState<Date>(
    moment(watchFieldDate, 'DD/MM/YYYY').isValid()
      ? moment(watchFieldDate, 'DD/MM/YYYY').toDate()
      : undefined
  )
  const [showInput, setShowInput] = useState(
    watch(hasHeader?.positive?.name || '') ||
      data[index]?.isActive ||
      copyData[index]?.isActive ||
      '' ||
      defaultShow
  )
  const allowClass = ['show', 'show child']

  const handleAddForm = useCallback(
    (status: string) => {
      const showClass = ['show', 'show child']
      if (data[index + 1]) {
        const prefixArray = positive?.name.split('.')
        prefixArray.pop()
        const prefix = prefixArray.join('.')
        const workFields = getValues(prefix)
        if (workFields) {
          const objectEntries = Object.entries(workFields)
          for (let [key] of objectEntries) {
            key = `${prefix}.${key}`
            if (positive?.name !== key) {
              setValue(key, '')
            }
            clearErrors(prefix)
          }
        }
        setData(oldState => {
          const copyOld = [...oldState]

          copyOld[index + 1].isActive = showClass.includes(
            copyOld[index + 1].isActive
          )
            ? 'hidden'
            : 'show'
          return copyOld
        })
      } else {
        setData(oldState => {
          const copyOld = [...oldState]
          copyOld[index + 1] = {
            id: index + 1,
            isActive: showClass.includes(status) ? 'show child' : 'hidden',
            parent: index
          }
          copyOld[index].isActive = 'show'
          return [...copyOld]
        })
      }
    },
    [clearErrors, data, getValues, index, positive?.name, setData, setValue]
  )

  const handleClose = useCallback(() => {
    const showClass = ['show', 'show child']
    const watchField = watch(hasHeader?.positive?.name || '')
    if (watchField === 'hidden') {
      const prefixArray = hasHeader?.positive?.name.split('.')
      prefixArray.pop()
      const prefix = prefixArray.join('.')
      const workFields = getValues(prefix)
      if (workFields) {
        const objectEntries = Object.entries(workFields)
        for (let [key] of objectEntries) {
          key = `${prefix}.${key}`
          if (hasHeader?.positive?.name !== key) {
            setValue(key, '')
          }
        }
      }

      clearErrors(prefix)
    }
    if (showClass.includes(data[index]?.isActive)) {
      const prefixArray = hasHeader?.positive?.name.split('.')
      prefixArray.pop()
      const prefix = prefixArray.join('.')
      const workFields = getValues(prefix)
      if (workFields) {
        const objectEntries = Object.entries(workFields)

        for (let [key] of objectEntries) {
          key = `${prefix}.${key}`
          if (hasHeader?.positive?.name !== key) {
            setValue(key, '')
            clearErrors(prefix)
          }
        }
      }
      setData(oldState => {
        const data = [...oldState]
        data[index] = {
          name: '',
          travel: '',
          address: '',
          country: '',
          surname: '',
          isActive: showClass.includes(data[index].isActive)
            ? 'hidden'
            : 'show child',
          birth_date: '',
          occupation: ''
        }

        return data
      })
    }
  }, [
    clearErrors,
    data,
    getValues,
    hasHeader?.positive?.name,
    index,
    setData,
    setValue,
    watch
  ])
  return (
    <div className="row pe-xl-5 0">
      <div>
        {hasHeader?.label && (
          <p className="mb-2 form-check-label text-gray-700 fw-bold p-0">
            {hasHeader?.label}
          </p>
        )}
        <div className="d-flex">
          {hasHeader && hasHeader.positive && (
            <Checkbox
              className="col-xl-1"
              label="Sim"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              register={register}
              {...hasHeader.positive}
              errors={errors}
              rules={{
                required: changeTab !== 'previous',
                onChange: () => {
                  const watchField = watch(hasHeader.positive.name)
                  if (watchField === 'show') {
                    if (type !== 'parents') {
                      setCopyData(oldState => {
                        const data = [...oldState]
                        data[index].isActive = 'show'
                        return [...data]
                      })
                    }
                    setShowInput('show')
                  } else {
                    handleClose()
                    setShowInput('hidden')
                  }
                }
              }}
              value="show"
              type="radio"
              checked={allowClass.includes(showInput)}
              hasErrorCustom
              hasCustom
            />
          )}
          {hasHeader && hasHeader.negative && (
            <Checkbox
              className="col-xl-1"
              label="Não"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              {...hasHeader.negative}
              register={register}
              type="radio"
              value="hidden"
              errors={errors}
              rules={{
                required: changeTab !== 'previous'
              }}
              checked={showInput === 'hidden'}
              hasCustom
            />
          )}
        </div>
      </div>
      {type === 'parents' ? (
        <ContainerAnimation
          isShow={hasHeader ? watch(hasHeader.positive.name) : showInput}
          id={'family_parents ' + inputName.name}
          className="family_parents_container"
        >
          <div className="row pe-xl-5 mb-5">
            <Input
              className="col-xl"
              label="Nome completo"
              register={register}
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              {...inputName}
              tooltip={{
                container: {
                  classname: 'ms-2'
                },
                classname: 'mw-30px',
                message: `<p style="max-width: 300px">
                Entre com o nome ${tooltip?.type}
                          </p>`
              }}
              rules={
                changeTab !== 'previous'
                  ? {
                      required: hasHeader
                        ? watch(hasHeader.positive.name) === 'show'
                        : allowClass.includes(showInput)
                    }
                  : { required: false }
              }
              errors={errors}
              hasErrorCustom
              hasCustom
            />
            <Controller
              shouldUnregister={true}
              control={control}
              name={datepickerBirthDate.name}
              rules={
                changeTab !== 'previous'
                  ? {
                      required: hasHeader
                        ? watch(hasHeader.positive.name) === 'show'
                        : allowClass.includes(showInput)
                    }
                  : { required: false }
              }
              render={({ field }) => (
                <DatePicker
                  popperProps={{ strategy: 'fixed' }}
                  locale="ptBR"
                  className="col-xl"
                  register={register}
                  inputOptions={{ classname: 'd-flex align-items-center' }}
                  label="Data de nascimento"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/AAAA"
                  hasCustom
                  tooltip={{
                    container: {
                      classname: 'ms-2'
                    },
                    classname: 'mw-30px',
                    message: `<p style="max-width: 300px">
                    Entre com a data de nascimento ${tooltip?.type}
                      </p>`
                  }}
                  selected={birthDate}
                  {...field}
                  errors={errors}
                  hasErrorCustom
                  customInput={
                    <IMaskInput
                      mask={Date}
                      pattern={'d/m/Y'}
                      format={date => {
                        return moment(date).format(inputMaskFormat)
                      }}
                      parse={value => {
                        return moment(value, inputMaskFormat).toDate()
                      }}
                    />
                  }
                  onChange={(e: any) => {
                    field.onChange(e)
                    setBirthDate(e)
                  }}
                />
              )}
            />
          </div>
          <div className="row pe-xl-5 mb-5">
            <Input
              className="col-xl"
              label="Endereço residencial"
              register={register}
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              {...inputAddress}
              rules={
                changeTab !== 'previous'
                  ? {
                      required: hasHeader
                        ? watch(hasHeader.positive.name) === 'show'
                        : allowClass.includes(showInput)
                    }
                  : { required: false }
              }
              errors={errors}
              hasErrorCustom
              tooltip={{
                container: {
                  classname: 'ms-2'
                },
                classname: 'mw-30px',
                message: `<p style="max-width: 300px">
                Entre com o endereço ${tooltip?.type}
                          </p>`
              }}
              hasCustom
            />
            <Input
              className="col-xl "
              label="Ocupação"
              register={register}
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              {...inputOccupation}
              rules={
                changeTab !== 'previous'
                  ? {
                      required: hasHeader
                        ? watch(hasHeader.positive.name) === 'show'
                        : allowClass.includes(showInput)
                    }
                  : { required: false }
              }
              errors={errors}
              hasErrorCustom
              tooltip={{
                container: {
                  classname: 'ms-2'
                },
                classname: 'mw-30px',
                message: `<p style="max-width: 300px">
                Entre com a ocupação ${tooltip?.type}
                          </p>`
              }}
              hasCustom
            />
          </div>
          <div className="row pe-xl-5 mb-5">
            <Controller
              shouldUnregister={true}
              control={control}
              name={selectMaritalStatus.name}
              rules={
                changeTab !== 'previous'
                  ? {
                      required: hasHeader
                        ? watch(hasHeader.positive.name) === 'show'
                        : allowClass.includes(showInput)
                    }
                  : { required: false }
              }
              render={({ field }) => (
                <Select
                  className="col-xl"
                  label="Estado Civil"
                  {...field}
                  register={register}
                  selectOptions={{
                    classname: 'd-flex align-items-center'
                  }}
                  {...selectMaritalStatus}
                  errors={errors}
                  hasErrorCustom
                  tooltip={{
                    container: {
                      classname: 'ms-2'
                    },
                    classname: 'mw-30px',
                    message: `<p style="max-width: 300px">
                    Informe o estado civil ${tooltip?.type}
                      </p>`
                  }}
                  hasCustom
                />
              )}
            />
            <Input
              className="col-xl"
              register={register}
              label="País de nascimento"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              {...inputCountry}
              rules={
                changeTab !== 'previous'
                  ? {
                      required: hasHeader
                        ? watch(hasHeader.positive.name) === 'show'
                        : allowClass.includes(showInput)
                    }
                  : { required: false }
              }
              tooltip={{
                container: {
                  classname: 'ms-2'
                },
                classname: 'mw-30px',
                message: `<p style="max-width: 300px">
                Entre com o país de nascimento ${tooltip?.type}
                          </p>`
              }}
              errors={errors}
              hasErrorCustom
              hasCustom
            />
          </div>
          <div className="row pe-xl-5 mb-5">
            <Controller
              shouldUnregister={true}
              control={control}
              name={selectTravelWithYou.name}
              render={({ field }) => (
                <Select
                  className="col-xl"
                  label="Vai viajar junto com você?"
                  register={register}
                  selectOptions={{
                    classname: 'd-flex align-items-center'
                  }}
                  {...selectTravelWithYou}
                  rules={
                    changeTab !== 'previous'
                      ? {
                          required: hasHeader
                            ? watch(hasHeader.positive.name) === 'show'
                            : allowClass.includes(showInput)
                        }
                      : { required: false }
                  }
                  errors={errors}
                  hasErrorCustom
                  {...field}
                  hasCustom
                />
              )}
            />
          </div>
        </ContainerAnimation>
      ) : (
        <ContainerAnimation
          isShow={showInput}
          id={'family_child ' + inputName.name}
          className="family_child_container"
        >
          <div className="row pe-xl-5 mb-5">
            <Input
              className="col-xl"
              label="Nome completo"
              register={register}
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              {...inputName}
              tooltip={{
                container: {
                  classname: 'ms-2'
                },
                classname: 'mw-30px',
                message: `<p style="max-width: 300px">
                Entre com o nome do seu ${tooltip?.type}
                          </p>`
              }}
              rules={
                changeTab !== 'previous'
                  ? {
                      required: hasHeader
                        ? watch(hasHeader.positive.name) === 'show'
                        : allowClass.includes(showInput)
                    }
                  : { required: false }
              }
              errors={errors}
              hasErrorCustom
              hasCustom
            />
            <Controller
              shouldUnregister={true}
              control={control}
              name={datepickerBirthDate.name}
              rules={
                changeTab !== 'previous'
                  ? {
                      required: hasHeader
                        ? watch(hasHeader.positive.name) === 'show'
                        : allowClass.includes(showInput)
                    }
                  : { required: false }
              }
              render={({ field }) => (
                <DatePicker
                  popperProps={{ strategy: 'fixed' }}
                  locale="ptBR"
                  className="col-xl"
                  register={register}
                  inputOptions={{ classname: 'd-flex align-items-center' }}
                  label="Data de nascimento"
                  tooltip={{
                    container: {
                      classname: 'ms-2'
                    },
                    classname: 'mw-30px',
                    message: `<p style="max-width: 300px">
                    Entre com a data de nascimento do seu ${tooltip?.type}
                      </p>`
                  }}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/AAAA"
                  hasCustom
                  selected={birthDate}
                  {...field}
                  errors={errors}
                  hasErrorCustom
                  customInput={
                    <IMaskInput
                      mask={Date}
                      pattern={'d/m/Y'}
                      format={date => {
                        return moment(date).format(inputMaskFormat)
                      }}
                      parse={value => {
                        return moment(value, inputMaskFormat).toDate()
                      }}
                    />
                  }
                  onChange={(e: any) => {
                    field.onChange(e)
                    setBirthDate(e)
                  }}
                />
              )}
            />
          </div>
          <div className="row pe-xl-5 mb-5">
            <Controller
              shouldUnregister={true}
              control={control}
              name={selectMaritalStatus.name}
              rules={
                changeTab !== 'previous'
                  ? {
                      required: hasHeader
                        ? watch(hasHeader.positive.name) === 'show'
                        : allowClass.includes(showInput)
                    }
                  : { required: false }
              }
              render={({ field }) => (
                <Select
                  className="col-xl"
                  label="Estado Civil"
                  register={register}
                  selectOptions={{
                    classname: 'd-flex align-items-center'
                  }}
                  {...selectMaritalStatus}
                  {...field}
                  errors={errors}
                  hasErrorCustom
                  tooltip={{
                    container: {
                      classname: 'ms-2'
                    },
                    classname: 'mw-30px',
                    message: `<p style="max-width: 300px">
                    Informe o estado civil do seu ${tooltip?.type}
                      </p>`
                  }}
                  hasCustom
                />
              )}
            />

            <Input
              className="col-xl"
              label="Endereço residencial"
              register={register}
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              {...inputAddress}
              rules={
                changeTab !== 'previous'
                  ? {
                      required: hasHeader
                        ? watch(hasHeader.positive.name) === 'show'
                        : allowClass.includes(showInput)
                    }
                  : { required: false }
              }
              errors={errors}
              hasErrorCustom
              tooltip={{
                container: {
                  classname: 'ms-2'
                },
                classname: 'mw-30px',
                message: `<p style="max-width: 300px">
                Entre com o endereço do seu ${tooltip?.type}
                          </p>`
              }}
              hasCustom
            />
          </div>
          <div className="row pe-xl-5 mb-5">
            <Input
              className="col-xl"
              label="Ocupação"
              register={register}
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              {...inputOccupation}
              rules={
                changeTab !== 'previous'
                  ? {
                      required: hasHeader
                        ? watch(hasHeader.positive.name) === 'show'
                        : allowClass.includes(showInput)
                    }
                  : { required: false }
              }
              errors={errors}
              hasErrorCustom
              tooltip={{
                container: {
                  classname: 'ms-2'
                },
                classname: 'mw-30px',
                message: `<p style="max-width: 300px">
                Entre com a ocupação do seu ${tooltip?.type}
                          </p>`
              }}
              hasCustom
            />
            <Input
              className="col-xl"
              label="Relacionamento"
              register={register}
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              rules={
                changeTab !== 'previous'
                  ? {
                      required: hasHeader
                        ? watch(hasHeader.positive.name) === 'show'
                        : allowClass.includes(showInput)
                    }
                  : { required: false }
              }
              errors={errors}
              hasErrorCustom
              {...inputRelationship}
              hasCustom
            />
          </div>
          <div className="row pe-xl-5 mb-5">
            <Input
              className="col-xl"
              register={register}
              label="País de nascimento"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              {...inputCountry}
              rules={
                changeTab !== 'previous'
                  ? {
                      required: hasHeader
                        ? watch(hasHeader.positive.name) === 'show'
                        : allowClass.includes(showInput)
                    }
                  : { required: false }
              }
              errors={errors}
              hasErrorCustom
              tooltip={{
                container: {
                  classname: 'ms-2'
                },
                classname: 'mw-30px',
                message: `<p style="max-width: 300px">
                Entre com o país de nascimento do seu ${tooltip?.type}
                          </p>`
              }}
              hasCustom
            />
            <Controller
              shouldUnregister={true}
              control={control}
              name={selectTravelWithYou.name}
              rules={
                changeTab !== 'previous'
                  ? {
                      required: hasHeader
                        ? watch(hasHeader.positive.name) === 'show'
                        : allowClass.includes(showInput)
                    }
                  : { required: false }
              }
              render={({ field }) => (
                <Select
                  className="col-xl"
                  label="Vai viajar junto com você?"
                  register={register}
                  selectOptions={{
                    classname: 'd-flex align-items-center'
                  }}
                  {...selectTravelWithYou}
                  {...field}
                  errors={errors}
                  hasErrorCustom
                  hasCustom
                />
              )}
            />
          </div>
          <div className="row pe-xl-5 mb-5 ps-1">
            {!!label && <p>{label}</p>}
            <div className="d-flex">
              <Checkbox
                className="col-xl-1"
                label="Sim"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={positive?.name}
                register={register}
                value="show"
                type="radio"
                rules={{
                  onChange: () => {
                    const watchField = watch(positive.name)
                    if (watchField === 'show') {
                      handleAddForm('show')
                    } else {
                      handleAddForm('hidden')
                    }
                  }
                }}
                checked={
                  data[index + 1]?.isActive === 'show' ||
                  data[index + 1]?.isActive === 'show child'
                }
                hasCustom
              />
              <Checkbox
                className="col-xl-1"
                label="Não"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={negative?.name}
                register={register}
                value="hidden"
                type="radio"
                errors={errors}
                hasErrorCustom
                checked={data[index + 1]?.isActive === 'hidden'}
                hasCustom
              />
            </div>
          </div>
        </ContainerAnimation>
      )}
    </div>
  )
}
