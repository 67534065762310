/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../components/Container'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import {
  nameActions,
  nameEntityContractHistory,
  nameEntityForm,
  nameSourceContractHistory,
  nameSourceForm
} from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import { toolsViewDelete, toolsViewList } from '../domain/tools'
import Tabs from '../../../../components/Tabs'
import Tab from '../../../../components/Tabs/Tab'
import DataTable from '../../../../components/DataTable'
import { headersContractHistory, headersForm } from '../domain/headers'
import Modal from '../../../../components/Modal'
import { FormDocument } from '../components/FormDocument'
import { useUpdateDataTable } from '../../../../hooks/dataTable'
import { FormChangeStatus } from '../components/FormChangeStatus'
import Form, { Input, Select } from '../../../../components/Form'
import { Loading } from '../../../../components/Loading'
import {
  CONTRACTS_PAYMENT_METHOD_TRANSLATED,
  CONTRACTS_STATUS_PAYMENT_TRANSLATED
} from '../../../../common/constants/status-contract'
import { genericMaskWithTwoZeroWithPoint } from '../../../../utils/mask'
import { INPUT_MASK, SAVE_FORM_DATA } from '../../../../common/constants'
import { convertToBase64 } from '../../../../utils/convertToBase64'
import { useAuth } from '../../../../hooks/auth'
import moment from 'moment'
import {
  toolsViewCancelled,
  toolsViewFinalInformation
} from '../domain/tools/view'
import { FormContractHistory } from '../components/FormContractHistory'
import { StringParam, useQueryParam } from 'use-query-params'

const ContractView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const [callbackUrl] = useQueryParam('callback_url', StringParam)
  const [titleId] = useQueryParam('title_id', StringParam)
  const history = useHistory()
  const location = useLocation()
  const { user } = useAuth()
  const { addToast } = useToast()
  const { updateDataTable } = useUpdateDataTable()
  const { disableLoading, activeLoading } = useLoading()
  const [isLoading, setIsLoading] = useState(false)
  const [contract, setContract] = useState<ContractApiResponse | null>(null)
  const [modalEdit, setModalEdit] = useState(false)
  const [phasesProcess, setPhasesProcess] = useState<SelectOptions[]>([])
  const [currentItemCreate, setCurrentItemCreate] = useState<any>({} as any)
  const [currentItemEdit, setCurrentItemEdit] = useState<any>({} as any)
  const [openModalFolder, setOpenModalFolder] = useState(false)
  const [currentForm, setCurrentForm] = useState<any>()
  const [searchParameters, setSearchParameters] = useState<any[]>([])
  const [clients, setClients] = useState<ClientsApiResponse | null>(null)
  const contractIdRef = useRef([{ contract_id: id }])
  const [modalCreateContractHistory, setModalCreateContractHistory] =
    useState(false)
  const [modalEditContractHistory, setModalEditContractHistory] =
    useState(false)
  const loadContract = useCallback(async () => {
    activeLoading()
    try {
      const response = await api.get<ContractApiResponse>(apiList(id))
      const { data } = response
      setContract(data)
      setClients({ ...data.client })
      disableLoading()
    } catch (err) {
      const [, baseUrl, path] = location.pathname.split('/')
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar o contrato',
        description:
          'Houve um error ao carregar o contrato, tente novamente mais tarde!'
      })
      if (path.includes('view')) {
        history.push(`/${baseUrl}`)
      } else {
        history.push(`/${baseUrl}/${path}`)
      }
    }
  }, [activeLoading, addToast, disableLoading, history, id, location.pathname])
  useEffect(() => {
    loadContract()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLoading, addToast, disableLoading, history, location.pathname])

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Contrato removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Contrato não removido, pois ainda está sendo usada.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }

  const handleClickOnClose = () => {
    setModalEdit(false)
    setModalCreateContractHistory(false)
    setModalEditContractHistory(false)
    updateDataTable()
  }

  const handleClickOnCloseDocuments = () => {
    setOpenModalFolder(false)
    updateDataTable()
  }

  const handleSetFilter = useCallback(
    data => {
      setSearchParameters([{ ...data, contract_id: id }])
    },
    [id]
  )

  useEffect(() => {
    if (id) {
      setSearchParameters(oldSearchParameters => [
        ...oldSearchParameters,
        { contract_id: id }
      ])
    }
  }, [id])

  const loadPhasesProcess = useCallback(async () => {
    activeLoading()
    try {
      const responsePhasesProcess = await api.get<PhaseProcessApiResponse[]>(
        '/commercial/phasesProcess'
      )
      setPhasesProcess(
        responsePhasesProcess.data.map(phaseProcess => ({
          name: phaseProcess.name,
          value: phaseProcess.id
        }))
      )
      disableLoading()
    } catch (error) {
      disableLoading()
    }
  }, [activeLoading, disableLoading])

  const handleCancelled = useCallback(async () => {
    setIsLoading(true)
    try {
      await api.put<boolean>(`/commercial/contracts/cancelled/${id}`)
      await loadContract()
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }, [id, loadContract])

  useEffect(() => {
    loadPhasesProcess()
  }, [loadPhasesProcess])

  const toolsPage = useMemo(() => {
    const tools = []
    if (contract?.release_final_information) {
      console.log(callbackUrl)
      tools.push(toolsViewFinalInformation(id, callbackUrl))
    }
    if (contract?.status !== 'cancelled') {
      tools.push(toolsViewCancelled(() => handleCancelled()))
      tools.push(toolsViewList())
      return tools
    }
    tools.push(toolsViewList())
    return tools
  }, [
    callbackUrl,
    contract?.release_final_information,
    contract?.status,
    handleCancelled,
    id
  ])

  const handleClickOnOpenModalCreate = () => {
    setCurrentItemCreate({
      contract_id: id
    })
    setModalCreateContractHistory(true)
  }

  const handleGeneratePdf = useCallback(async () => {
    setIsLoading(true)
    try {
      const responsePdf = await api.post(
        `/commercial/contracts/generatePdf/${id}`,
        undefined,
        {
          responseType: 'arraybuffer'
        }
      )
      const blob = new Blob([responsePdf.data])
      const blobURL = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = blobURL
      a.download = `folha-rosto-contratacao-${id}-${moment().format(
        'DD-MM-YYYY-HH-mm-ss'
      )}.pdf`
      document.body.appendChild(a)
      a.click()
    } catch (error) {
      console.log(error)
      addToast({
        title: 'Não foi possível gerar o PDF',
        description: 'Ocorreu um erro ao gerar o PDF'
      })
    } finally {
      setIsLoading(false)
    }
  }, [addToast, id])

  const handleClickOnOpenModalEdit = (item: any) => {
    setCurrentItemEdit({
      contract_id: id,
      description: item.description,
      id: item.id
    })
    setModalEditContractHistory(true)
  }

  if (!id || isLoading) {
    return <Loading isActive />
  }

  return (
    <div className="d-flex flex-column container-fluid">
      <>
        <Container
          pageTitle={nameActions.read.name}
          breadcrumb={breadcrumbView}
          tools={toolsPage}
        >
          <div className="form-body">
            <h3>Contrato</h3>
            <div className="row mb-5">
              <div className="col-md-2">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Cód.
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">{contract?.id}</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Serviço
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {contract?.service?.name}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Cliente
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {contract?.client?.name}
                  </p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Valor
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {genericMaskWithTwoZeroWithPoint(contract?.total_value)}
                  </p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Total pago
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {genericMaskWithTwoZeroWithPoint(contract?.amount_charged)}
                  </p>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-md-2">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Forma de pagamento
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {
                      CONTRACTS_PAYMENT_METHOD_TRANSLATED[
                        contract?.payment_form
                      ]
                    }
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Status
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {CONTRACTS_STATUS_PAYMENT_TRANSLATED[contract?.status]}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="created"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Cadastrado em
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {moment(contract?.created_at).format(
                      INPUT_MASK.DATE_LOCALE_WITH_TIME
                    )}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="updated"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Atualizado em
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {moment(contract?.updated_at).format(
                      INPUT_MASK.DATE_LOCALE_WITH_TIME
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="my-10 separator"></div>
          <div className="form-body">
            <h3>Cliente</h3>
            <div className="row mb-5">
              <div className="col-md-1">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Cód.
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">{clients?.id}</p>
                </div>
              </div>
              <div className="col-md-5">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Nome
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {clients?.name}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    CPF
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">{clients?.cpf}</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Nascimento
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {moment(clients?.birth_date).format(INPUT_MASK.DATE_LOCALE)}
                  </p>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Email
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {clients?.email}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Celular
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {clients?.cell_phone}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Cep
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {clients?.zip_code}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Rua
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {clients?.street}
                  </p>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Número
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {clients?.number}
                  </p>
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Cidade
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {clients?.city}
                  </p>
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Bairro
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {clients?.district}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Estado
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">{clients?.uf}</p>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-md-12">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Complemento
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {clients?.complement}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="separator"></div>
          <div className="row">
            <div className="">
              <Tabs>
                <Tab title="Histórico">
                  <div className="card-header p-0 d-flex - align-items-center">
                    <div className="card-title m-0 fw-bolder fs-6 text-gray-800"></div>
                    <div
                      className=""
                      style={{
                        display: 'flex',
                        gap: '1rem',
                        justifyContent: 'center'
                      }}
                    >
                      <a
                        href="#"
                        onClick={handleClickOnOpenModalCreate}
                        style={{ cursor: 'pointer' }}
                      >
                        <span className="fa fa-plus" /> Adicionar
                      </a>

                      <p
                        className="text-primary"
                        onClick={handleGeneratePdf}
                        style={{ cursor: 'pointer' }}
                      >
                        <span className="fa fa-print" /> Imprimir
                      </p>
                    </div>
                  </div>
                  {contractIdRef && (
                    <DataTable
                      source={nameSourceContractHistory}
                      entity={nameEntityContractHistory}
                      format={{ orderBy: 'created_at', orderBySort: 'DESC' }}
                      headers={headersContractHistory}
                      notHasChildren
                      searchParameters={contractIdRef.current}
                      customHeaders={[
                        {
                          name: 'Descrição',
                          field: 'description',
                          sortable: true,
                          tdStyle: {
                            width: 5000
                          },
                          element: item => {
                            return (
                              <p>
                                {moment(item?.created_at).format(
                                  INPUT_MASK.DATE_LOCALE_WITH_TIME
                                )}
                              </p>
                            )
                          }
                        },
                        {
                          name: 'Criado em',
                          field: 'created_at',
                          sortable: false,
                          element: item => {
                            return (
                              <p>
                                {moment(item?.created_at).format(
                                  INPUT_MASK.DATE_LOCALE_WITH_TIME
                                )}
                              </p>
                            )
                          }
                        }
                      ]}
                      actions={[
                        {
                          name: 'Atualizar',
                          title: 'Atualizar',
                          element: item => {
                            if (item.created_by !== user.id) {
                              return (
                                <span
                                  style={{
                                    pointerEvents: 'none',
                                    opacity: 0.6
                                  }}
                                  className="fa fa-edit disabled"
                                ></span>
                              )
                            }
                            return (
                              <span
                                className={`fa fa-edit ${
                                  item.created_by !== user.id ? 'disabled' : ''
                                }`}
                                onClick={() => handleClickOnOpenModalEdit(item)}
                              ></span>
                            )
                          }
                        }
                      ]}
                    />
                  )}
                </Tab>
                <Tab title="Formulários">
                  <Form onSubmit={handleSetFilter}>
                    <div className="form">
                      <div className="row mb-5">
                        <Input
                          name="applicant_name"
                          label="Nome"
                          className="col-md-4"
                        />
                        {/* <Input
                          name="email"
                          label="Email"
                          type="email"
                          className="col-md-3"
                        />
                        <Input
                          name="cpf"
                          label="CPF"
                          className="col-md-2"
                          mask={{
                            mask: INPUT_MASK.CPF,
                            type: 'cpf'
                          }}
                          controlled
                        />
                        <Select
                          className="col-md-3"
                          name="phase_process_id"
                          label="Status"
                          options={phasesProcess}
                          blank
                          defaultValue=""
                        /> */}
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="col-md-2 d-flex justify-content-end">
                        <button
                          type="reset"
                          className="btn btn-light-primary"
                          onClick={() => {
                            setSearchParameters([{ contract_id: id }])
                          }}
                        >
                          Limpar
                        </button>
                      </div>
                      <div className="col-md-1 d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary">
                          Filtrar
                        </button>
                      </div>
                    </div>
                  </Form>
                  {searchParameters?.[0]?.contract_id && (
                    <DataTable
                      source={nameSourceForm}
                      entity={nameEntityForm}
                      format={{ orderBy: 'id', orderBySort: 'DESC' }}
                      searchField="applicant_name"
                      headers={headersForm}
                      notHasChildren
                      searchParameters={searchParameters}
                      customHeaders={[
                        {
                          name: 'Criado em',
                          field: 'created_at',
                          sortable: false,
                          element: item => {
                            return (
                              <p>
                                {moment(item?.created_at).format(
                                  INPUT_MASK.DATE_LOCALE_WITH_TIME
                                )}
                              </p>
                            )
                          }
                        },
                        {
                          name: 'Status',
                          field: 'formPhaseProcess',
                          sortable: true,
                          element: item => {
                            let lastPhaseProcess: Partial<FormPhaseProcessApiResponse> =
                              {
                                id: 0
                              }
                            item?.formPhaseProcess.forEach(
                              (formPhaseProcess: any) => {
                                if (
                                  lastPhaseProcess?.id < formPhaseProcess.id
                                ) {
                                  lastPhaseProcess = formPhaseProcess
                                }
                              }
                            )
                            return (
                              <p>
                                {lastPhaseProcess?.phaseProcess?.name || ''}
                              </p>
                            )
                          }
                        }
                      ]}
                      actions={[
                        {
                          name: 'Visualizar formulário',
                          title: 'Visualizar formulário',
                          spanIcon: 'fa fa-edit me-5',
                          onClick: (item: any) => {
                            localStorage.removeItem(SAVE_FORM_DATA)
                            const convertedObject = convertToBase64(
                              JSON.stringify({
                                form_id: item.id,
                                user_type: 'admin',
                                url_callback: `${process.env.REACT_APP_URL}/commercial/contracts/view/${id}`,
                                token: user.token
                              })
                            )
                            const a = document.createElement('a')
                            a.href = `${process.env.REACT_APP_FORMS_SERVICE}/${item.formType.link}?pass=${convertedObject}`
                            a.click()
                            a.remove()
                          }
                        },
                        {
                          name: 'Status',
                          title: 'Mudar Status',
                          spanIcon: 'fa-regular fa-clipboard me-5',
                          onClick: (item: any) => {
                            setModalEdit(true)
                            setCurrentItemCreate(item)
                          }
                        },
                        {
                          name: 'Arquivos',
                          title: 'Arquivos',
                          spanIcon: 'fa fa-folder',
                          onClick: (item: any) => {
                            setCurrentForm(item)
                            setOpenModalFolder(true)
                          }
                        }
                      ]}
                    />
                  )}
                </Tab>
              </Tabs>
            </div>
          </div>
        </Container>

        <Alert
          message={`Tem certeza que deseja excluir o registro ${contract?.id} ?`}
          onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
          onClickConfirmButton={() =>
            handlerOnClickButtonConfirmRemoveParent(Number(contract?.id))
          }
          isActive={alertRemoveParent}
        />

        {modalCreateContractHistory && (
          <Modal
            onClickButtonCancel={handleClickOnClose}
            isOpenModal={modalCreateContractHistory}
            pageTitle={'Adicionar histórico'}
            Children={
              <FormContractHistory
                typeForm="create"
                isOpenInModal={{
                  handleOnClose: handleClickOnClose,
                  contract_id: currentItemCreate.contract_id
                }}
              />
            }
          />
        )}

        {modalEditContractHistory && (
          <Modal
            onClickButtonCancel={handleClickOnClose}
            isOpenModal={modalEditContractHistory}
            pageTitle={'Atualizar histórico'}
            Children={
              <FormContractHistory
                typeForm="update"
                initialValues={{
                  ...currentItemEdit,
                  idUpdate: currentItemEdit?.id
                }}
                isOpenInModal={{
                  handleOnClose: handleClickOnClose,
                  contract_id: currentItemEdit.contract_id
                }}
              />
            }
          />
        )}
        {modalEdit && (
          <Modal
            onClickButtonCancel={handleClickOnClose}
            isOpenModal={modalEdit}
            pageTitle={'Editar'}
            Children={
              <FormChangeStatus
                typeForm="update"
                initialValues={{
                  ...currentItemCreate,
                  idUpdate: currentItemCreate?.id
                }}
                isOpenInModal={{
                  handleOnClose: handleClickOnClose,
                  idParent: currentItemCreate?.id
                }}
              />
            }
          />
        )}

        {openModalFolder && (
          <Modal
            onClickButtonCancel={handleClickOnCloseDocuments}
            isOpenModal={openModalFolder}
            pageTitle="Documentos"
            style={{
              padding: 0,
              stylesModal: {
                width: '1150px',
                overflow: 'auto'
              }
            }}
            Children={
              <FormDocument
                initialValues={{
                  ...currentForm
                }}
                isOpenInModal={{
                  handleOnClose: handleClickOnCloseDocuments
                }}
              />
            }
          />
        )}
      </>
    </div>
  )
}

export default ContractView
