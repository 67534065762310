import React from 'react'
import DataTable from '../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import Container from '../../../../components/Container'
import { toolsList } from '../domain/tools'
import moment from 'moment'
import { INPUT_MASK } from '../../../../common/constants'

const ServiceCategoryCategoriesList: React.FC = () => {
  return (
    <>
      <Container
        pageTitle="Listagem"
        breadcrumb={breadcrumbList}
        tools={toolsList}
      >
        <DataTable
          source={nameSource}
          entity={nameEntity}
          format={{ orderBy: 'created_at' }}
          headers={headers}
          notHasChildren
          customHeaders={[
            {
              name: 'Criado em',
              field: 'created_at',
              sortable: false,
              element: item => {
                return (
                  <p>
                    {moment(item?.created_at).format(
                      INPUT_MASK.DATE_LOCALE_WITH_TIME
                    )}
                  </p>
                )
              }
            },
            {
              name: 'Ativo',
              field: 'active',
              sortable: true,
              element: (item: { active: string }) => (
                <p>{item.active ? 'Sim' : 'Não'}</p>
              )
            }
          ]}
        />
      </Container>
    </>
  )
}

export default ServiceCategoryCategoriesList
