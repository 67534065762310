import React, { useCallback, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select, Textarea } from '../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { FormContainer } from './styles'
import { Alert } from '../../../../../components/Alert'
import { TUTORIALS_TYPES_OPTIONS } from '../../../../../common/constants/tutorial-type'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}
type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: TutorialsApiResponse & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}
const toastSuccessData: Record<string, () => ToastMessage> = {
  create: () => ({
    type: 'success',
    title: 'Registro criado',
    description: 'Registro criado com sucesso'
  }),
  update: () => ({
    type: 'success',
    title: 'Registro atualizado',
    description: 'Registro alterado com sucesso'
  })
}
const toastErrorData: Record<string, () => ToastMessage> = {
  create: () => ({
    type: 'error',
    title: 'Erro ao adicionar o registro',
    description:
      'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
  }),
  update: () => ({
    type: 'error',
    title: 'Erro ao atualizar',
    description:
      'Ocorreu um erro ao alterar os dados, por favor, tente novamente.'
  })
}

export const FormTutorials = ({
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const { activeLoading, disableLoading } = useLoading()

  const [alert, setIsActiveAlert] =
    useState<{ isActive: boolean; document: File }>()

  const onSubmitForm = useCallback(
    async (data: any) => {
      const id = initialValues?.idUpdate
      const apiRequest =
        typeForm === 'create'
          ? api.post(apiCreate(), data)
          : api.put(apiUpdate(id.toString()), data)

      try {
        activeLoading()
        await apiRequest
        disableLoading()
        addToast(toastSuccessData[typeForm]?.())
        if (typeForm === 'update') {
          updateDataTable()
        }
        history.push(nameActions.read.to)
      } catch (error) {
        history.push(nameActions.read.to)
        addToast(toastErrorData[typeForm]?.())
      }
    },
    [
      activeLoading,
      addToast,
      disableLoading,
      history,
      initialValues?.idUpdate,
      typeForm,
      updateDataTable
    ]
  )

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert(oldAlert => ({
      ...oldAlert,
      isActive: false
    }))
  }

  return (
    <>
      <Form
        onSubmit={onSubmitForm}
        defaultValues={{
          type: initialValues?.type,
          title: initialValues?.title,
          url: initialValues?.url,
          description: initialValues?.description,
          description_short: initialValues?.description_short
        }}
      >
        <>
          <div className="card">
            <FormContainer>
              <div className="row mb-5">
                <Select
                  name="type"
                  label="Tipo"
                  rules={{ required: true }}
                  className="col-md-2"
                  options={TUTORIALS_TYPES_OPTIONS}
                />
                <Input
                  name="title"
                  label="Título"
                  rules={{ required: true }}
                  className="col-md-10"
                />
              </div>
              <div className="row mb-5">
                <Input
                  name="url"
                  label="Link do video no youtube"
                  rules={{ required: true }}
                  className="col-md-12"
                />
              </div>
              <div className="row mb-5">
                <Textarea
                  name="description_short"
                  label="Descrição resumida"
                  className="col-md-12"
                  rules={{ required: true }}
                  style={{ minHeight: 100 }}
                  maxLength={100}
                />
              </div>
              <div className="row mb-5">
                <Textarea
                  name="description"
                  label="Descrição"
                  className="col-md-12"
                  style={{ minHeight: 100 }}
                />
              </div>
            </FormContainer>
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button type="submit" className="btn btn-primary">
                Salvar
              </button>
            </div>
          </div>
        </>
      </Form>
      {alert && alert.isActive && (
        <Alert
          message={`Tem certeza que deseja excluir esse documento ${alert.document.name} ? ele não poderá ser recuperado`}
          onClickCancellButton={handlerClickButtonCancellAlert}
          onClickConfirmButton={() => {
            // handleRemoveFile(alert.document)
            setIsActiveAlert(oldAlert => ({
              ...oldAlert,
              isActive: false
            }))
          }}
          isActive={alert.isActive}
        />
      )}
    </>
  )
}
