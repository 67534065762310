import React from 'react'
import ContractProgressList from '../../pages/Commercial/ContractProgress/List'
import ContractProgressCreate from '../../pages/Commercial/ContractProgress/Create'
import ContractProgressUpdate from '../../pages/Commercial/ContractProgress/Update'
import ContractProgressView from '../../pages/Commercial/ContractProgress/View'
import PrivateRoute from '../PrivateRoute'
import { CustomSwitch } from '../../components/CustomSwitch'

export const ContractProgressRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoute
      path="/commercial/contractProgress"
      exact
      component={ContractProgressList}
    />
    <PrivateRoute
      path="/commercial/contractProgress/create"
      exact
      component={ContractProgressCreate}
    />
    <PrivateRoute
      path="/commercial/contractProgress/view/:id"
      exact
      component={ContractProgressView}
    />
    <PrivateRoute
      path="/commercial/contractProgress/update/:id"
      exact
      component={ContractProgressUpdate}
    />
  </CustomSwitch>
)
