import axios from 'axios'
import { validate } from 'gerador-validador-cpf'
import moment from 'moment'
import React, {
  InputHTMLAttributes,
  SelectHTMLAttributes,
  TextareaHTMLAttributes,
  useEffect,
  useCallback,
  ReactElement,
  useState
} from 'react'
import {
  Controller,
  FormProvider,
  RegisterOptions,
  useForm,
  useFormContext,
  UseFormRegister
} from 'react-hook-form'

import { IconBaseProps } from 'react-icons'
import { FiAlertCircle } from 'react-icons/fi'
import { IMaskInput } from 'react-imask'
import { useLoading } from '../../hooks/loading'
import { checkFormatDates } from '../../utils/checkDateFormat'
import { genericMaskWithTwoZeroWithPoint } from '../../utils/mask'
import { TooltipComponent } from '../TooltipComponent'
import {
  ContainerCustom,
  Contanier,
  Error,
  SelectContanier,
  TextAreaContanier
} from './styles'
import { toast } from 'react-toastify'
import api from '../../services/api'
import { useHistory } from 'react-router-dom'
import { SAVE_FORM_DATA } from '../../common/constants'

export default function Form({
  defaultValues,
  children,
  onSubmit,
  hasErrors
}: any) {
  const history = useHistory()
  const [savedFOrm, setSavedForm] = useState({
    onConfirm: false,
    path: ''
  })
  const methods = useForm({ defaultValues })
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    control,
    formState: { errors, dirtyFields, isSubmitting }
  } = methods

  const formattingDateFields = useCallback((originDate: any) => {
    const copyData = Object.entries(originDate)
    for (const [key, value] of copyData) {
      if (value) {
        const formatDate = checkFormatDates(value as any)
        if (formatDate && moment(value, formatDate, true).isValid()) {
          originDate[key] = moment(value, formatDate).toDate()
        }
        if (typeof value === 'object') {
          formattingDateFields(value)
        }
      }
    }
  }, [])

  useEffect(() => {
    const formattingValues = { ...defaultValues }
    formattingDateFields(formattingValues)
    reset(formattingValues, {
      keepDirty: true,
      keepDirtyValues: true,
      keepDefaultValues: true
    })
  }, [defaultValues, reset, dirtyFields, formattingDateFields])

  if (hasErrors) hasErrors(errors)
  const registeredField = useCallback(
    (child: ReactElement) => {
      if (child.props.controlled) {
        child.props.value && setValue(child.props.name, child.props.value)
        return (
          <Controller
            key={child.props.name}
            shouldUnregister={true}
            control={control}
            name={child.props.name}
            rules={child.props.rules}
            render={({ field }) => {
              return React.createElement(child.type, {
                ...{
                  ...child.props,
                  ...field,
                  register,
                  onChange: (e: any) => {
                    field.onChange(e)
                    child.props.onChange &&
                      child.props.onChange(e, { name: child?.props?.name })
                  },
                  errors,
                  key: child.props.name
                }
              })
            }}
          />
        )
      }
      if (child.props.fullControlled) {
        setValue(child.props.name, child.props.value)
        return (
          <Controller
            key={child.props.name}
            shouldUnregister={true}
            control={control}
            name={child.props.name}
            rules={child.props.rules}
            render={({ field }) => {
              return React.createElement(child.type, {
                ...{
                  ...child.props,
                  ...field,
                  register,
                  onChange: (e: any) => {
                    field.onChange(e)
                    child.props.onChange && child.props.onChange(e)
                  },
                  errors,
                  key: child.props.name
                }
              })
            }}
          />
        )
      }
      if (child.props.price) {
        return (
          <Controller
            key={child.props.name}
            shouldUnregister={true}
            control={control}
            name={child.props.name}
            rules={child.props.rules}
            render={({ field }) => {
              return React.createElement(child.type, {
                ...{
                  ...child.props,
                  ...field,
                  onChange: (e: any) => {
                    field.onChange(
                      genericMaskWithTwoZeroWithPoint(e.target.value)
                    )
                    child.props.onChange && child.props.onChange(e)
                  },
                  errors,
                  key: child.props.name
                }
              })
            }}
          />
        )
      }
      return React.createElement(child.type, {
        ...{
          ...child.props,
          register,
          errors,
          key: child.props.name
        }
      })
    },
    [control, errors, register, setValue]
  )

  const buildChildren = useCallback(
    (children: ReactElement, key = 0): any => {
      if (Array.isArray(children)) {
        return children.map((child: ReactElement, index) => {
          return buildChildren(child, index)
        })
      }

      if (children?.props?.children) {
        const childCopy = React.cloneElement(children, {
          key,
          children: buildChildren(children.props.children)
        })
        return childCopy
      }
      return children?.props?.name ? registeredField(children) : children
    },
    [registeredField]
  )

  useEffect(() => {
    const currentErrors = { ...methods.formState.errors }
    if (Object.keys(currentErrors).length !== 0 && isSubmitting) {
      toast.error('Alguns campos não foram preenchidos', {
        toastId: 'toast-error-form'
      })
    }
  }, [methods.formState, isSubmitting])

  // useEffect(() => {
  //   if (isSubmitting) {
  //     const currentErrors = { ...methods.formState.errors }
  //     const firstError = Object.keys(currentErrors).reduce((field, a) => {
  //       return currentErrors[field] ? field : a
  //     }, null)

  //     if (firstError) {
  //       setFocus(firstError)
  //     }
  //   }
  // }, [isSubmitting, methods.formState.errors, setFocus])

  useEffect(() => {
    if (savedFOrm.onConfirm) {
      history.push(savedFOrm.path)
      localStorage.removeItem(SAVE_FORM_DATA)
    }
    const unblock = history.block(location => {
      if (
        history.location.pathname.includes('/forms') &&
        location.pathname !== '/forms'
      ) {
        const dataForm = JSON.parse(localStorage.getItem(SAVE_FORM_DATA))
        if (!dataForm?.id) {
          setSavedForm({
            onConfirm: true,
            path: location.pathname
          })
          return
        }
        const id = toast.loading('Salvando sua informações')
        api
          .put(`/commercial/forms/update/${dataForm.id}`, {
            values: {
              ...dataForm
            }
          })
          .then(() => {
            toast.update(id, {
              render: 'Informações salvas',
              type: 'success',
              isLoading: false,
              autoClose: 3000,
              closeOnClick: true
            })
            setSavedForm({
              onConfirm: true,
              path: location.pathname
            })
          })
          .catch(err => {
            toast.update(id, {
              render: `Não foi possível salvar suas informações: ${err.message}`,
              type: 'error',
              isLoading: false,
              autoClose: 3000,
              closeOnClick: true
            })
            setSavedForm({
              onConfirm: false,
              path: location.pathname
            })
          })

        return false
      }
    })

    return () => {
      unblock()
    }
  }, [history, methods, savedFOrm])

  useEffect(() => {
    methods.watch(dataForm => {
      localStorage.setItem(SAVE_FORM_DATA, JSON.stringify(dataForm))
    })
  }, [methods])

  return (
    <FormProvider {...methods}>
      <form autoComplete="no-fill" onSubmit={handleSubmit(onSubmit)}>
        {buildChildren(children)}
      </form>
    </FormProvider>
  )
}

interface RulesProps extends RegisterOptions {
  position?: string
}

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  register?: UseFormRegister<any>
  name: string
  rules?: RulesProps
  hasError?: any
  errors?: any
  label?: string
  controlled?: boolean
  fullControlled?: boolean
  price?: boolean
  hasCustom?: boolean
  hasErrorCustom?: boolean
  tooltip?: {
    message: string
    classname?: string
    container?: {
      classname: string
    }
    label?: string
  }
  labelOptions?: any
  inputOptions?: any
  containerOptions?: any
  hasValidationParent?: {
    parent_name: string
    condition?: string
  }
  mask?: {
    mask?: any
    type?: string
    prefixInputsData?: string
    excludeFields?: string[]
    lazy?: boolean
    customFields?: {
      name?: string
      type?: 'street' | 'district' | 'city' | 'state'
      api_field?: string
      disabled?: boolean
    }[]
  }
  icon?: React.ComponentType<IconBaseProps>
}

export function Input({
  register,
  name,
  label,
  icon: Icon,
  rules,
  hasError,
  errors,
  className,
  hasCustom,
  labelOptions,
  inputOptions,
  containerOptions,
  tooltip,
  mask,
  hasValidationParent,
  hasErrorCustom,
  ...rest
}: InputProps) {
  const { watch, setError, clearErrors, setValue } = useFormContext()
  const { activeLoading, disableLoading } = useLoading()
  const [customRules, setCustomRules] = useState(rules)
  const handleOnChageInputMask = useCallback(async () => {
    const type = mask.type
    if (type === 'cpf') {
      const watchField = watch(name) as string
      if (watchField.match(/[0-9]/g)?.length) {
        if (validate(watchField)) {
          clearErrors(name)
        } else {
          setError(
            name,
            {
              type: 'focus',
              message: 'CPF Inválido'
            },
            {
              shouldFocus: true
            }
          )
        }
      }
    }
    if (type === 'zipCode') {
      const watchField = watch(name)
      const zipCodeSearch = watchField?.replaceAll(/[.\-/]/g, '')
      if (zipCodeSearch.length === 8) {
        activeLoading()
        const addressResponse = await axios.get(
          `https://viacep.com.br/ws/${zipCodeSearch}/json`,
          {
            timeout: 10000
          }
        )
        if (addressResponse.data.erro) {
          disableLoading()
          return
        }
        const customFields = [
          {
            name: 'street',
            type: 'street',
            api_field: 'logradouro'
          },
          {
            name: 'district',
            type: 'district',
            api_field: 'bairro'
          },
          {
            name: 'city',
            type: 'city',
            api_field: 'localidade'
          },
          {
            name: 'state',
            type: 'state',
            api_field: 'uf',
            disabled: true
          }
        ].map(field => {
          const findCustomField = mask?.customFields?.find(
            customField => customField.type === field.type
          )
          if (findCustomField) {
            return findCustomField
          }
          return field
        })
        customFields.forEach(({ name, api_field, disabled }) => {
          if (
            !mask?.excludeFields?.includes(mask?.prefixInputsData + name) ||
            !disabled
          ) {
            let prefix = name
            if (mask?.prefixInputsData) {
              prefix = prefix + name
            }
            setValue(prefix, addressResponse.data?.[api_field])
            clearErrors(prefix)
          }
        })
        disableLoading()
      }
    }
  }, [
    activeLoading,
    clearErrors,
    disableLoading,
    mask?.customFields,
    mask?.excludeFields,
    mask?.prefixInputsData,
    mask?.type,
    name,
    setError,
    setValue,
    watch
  ])

  useEffect(() => {
    if (hasValidationParent) {
      const watchField = watch(hasValidationParent.parent_name)
      setCustomRules({
        ...rules,
        required: watchField === (hasValidationParent?.condition || 'true')
      })

      return
    }
    setCustomRules(rules)
  }, [hasValidationParent, name, rules, watch])
  const keys = name.split('.')
  let error = keys.length === 2 ? errors?.[keys[0]]?.[keys[1]] : errors?.[name]
  error = keys.length === 3 ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]] : error
  error =
    keys.length === 4
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]
      : error
  error =
    keys.length === 5
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]?.[keys[4]]
      : error
  return hasCustom ? (
    <Contanier
      style={containerOptions?.styles}
      className={className + ' row'}
      erro={error}
    >
      {Icon && <Icon size={20} />}
      {label && (
        <label
          htmlFor={name}
          className={`col-form-label fw-bold fs-6form-label fw-bold fs-6 ${labelOptions?.classname}`}
          style={labelOptions?.styles}
        >
          {label}
        </label>
      )}
      <div className={inputOptions?.classname}>
        {mask ? (
          <IMaskInput
            mask={mask.mask}
            style={inputOptions?.styles}
            className="form-control form-control-solid"
            {...(register &&
              register(name, {
                ...customRules,
                onChange: handleOnChageInputMask
              }))}
            {...rest}
            autoComplete="no-fill"
          />
        ) : (
          <input
            {...(register && register(name, customRules))}
            {...rest}
            autoComplete="no-fill"
            style={inputOptions?.styles}
            className="form-control form-control-solid"
          />
        )}
        {error?.message ? (
          hasErrorCustom ? (
            <p className="error">Obrigatório</p>
          ) : (
            // <p className="error">{error.message}</p>
            <Error title={'Obrigatório'}>
              <FiAlertCircle color="#c53030" size={20} />
            </Error>
          )
        ) : undefined}
        {hasError?.error && (
          <Error title={'Obrigatório'}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
        {error?.type === 'required' ? (
          hasErrorCustom ? (
            <p className="error">Obrigatório</p>
          ) : (
            <Error title={'Obrigatório'} position={rules?.position}>
              <FiAlertCircle color="#c53030" size={20} />
            </Error>
          )
        ) : undefined}
        {tooltip && (
          <TooltipComponent
            label={tooltip.label || ''}
            message={tooltip.message}
            classname={tooltip.classname}
            options={{
              icon: {
                styles: {
                  width: 36,
                  height: 36
                }
              }
            }}
            {...tooltip}
          />
        )}
      </div>
    </Contanier>
  ) : (
    <Contanier className={className} erro={error}>
      {Icon && <Icon size={20} />}
      {label && (
        <label
          htmlFor={name}
          className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
        >
          {label}
        </label>
      )}
      <div>
        {mask ? (
          <IMaskInput
            mask={mask.mask}
            style={inputOptions?.styles}
            className="form-control form-control-solid"
            {...(register &&
              register(name, {
                ...customRules,
                onChange: handleOnChageInputMask
              }))}
            {...rest}
            autoComplete="no-fill"
          />
        ) : (
          <input
            {...(register && register(name, customRules))}
            {...rest}
            className="form-control form-control-solid"
          />
        )}

        {error?.message && (
          <Error title={'Obrigatório'}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
        {hasError?.error && (
          <Error title={'Obrigatório'}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
        {error?.type === 'required' && (
          <Error title={'Obrigatório'} position={rules?.position}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </div>
    </Contanier>
  )
}

export type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  register?: UseFormRegister<any>
  name: string
  rules?: RulesProps
  hasError?: any
  errors?: any
  label?: string
  controlled?: boolean
  fullControlled?: boolean
  price?: boolean
  hasCustom?: boolean
  labelOptions?: any
  inputOptions?: any
  icon?: React.ComponentType<IconBaseProps>
  dataField?: string
  hasErrorCustom?: boolean
  setData?: React.Dispatch<React.SetStateAction<boolean>>
  hasValidationParent?: {
    parent_name: string
    condition?: string
  }
  tooltip?: {
    message: string
    classname?: string
    label?: string
    container?: {
      classname: string
    }
  }
  clearField?: {
    name?: string
    defaultedValues: any
  }
  hasCheckbox?: boolean
}

export function Checkbox({
  register,
  name,
  label,
  icon: Icon,
  rules,
  hasError,
  errors,
  className,
  hasCustom,
  labelOptions,
  inputOptions,
  tooltip,
  setData,
  dataField,
  clearField,
  hasErrorCustom,
  hasValidationParent,
  hasCheckbox,
  ...rest
}: CheckboxProps) {
  const { watch, setValue } = useFormContext()
  const watchField = watch(dataField || name)
  const [customRules, setCustomRules] = useState(rules)
  useEffect(() => {
    if (hasValidationParent) {
      const watchField = watch(hasValidationParent.parent_name)
      setCustomRules({
        ...rules,
        required: watchField === (hasValidationParent?.condition || 'true')
      })
      return
    }
    setCustomRules(rules)
  }, [hasValidationParent, rules, watch])

  useEffect(() => {
    if (setData) {
      setData(watchField === 'true')
      if (watchField === 'false' && clearField) {
        const objectEntries = Object.entries(clearField.defaultedValues)
        for (const [key, value] of objectEntries) {
          setValue(key, value)
        }
      }
    }
  }, [clearField, dataField, name, setData, setValue, watch, watchField])
  const keys = name.split('.')
  let error = keys.length === 2 ? errors?.[keys[0]]?.[keys[1]] : errors?.[name]
  error = keys.length === 3 ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]] : error
  error =
    keys.length === 4
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]
      : error
  error =
    keys.length === 5
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]?.[keys[4]]
      : error

  return hasCustom ? (
    <ContainerCustom
      erro={error}
      className={
        className +
        ' form-check form-check-custom form-check-primary form-check-solid form-check-sm me-7'
      }
    >
      {Icon && <Icon size={20} />}

      <div
        className={
          'form-check form-check-custom form-check-primary form-check-solid form-check-sm me-7 ' +
          inputOptions?.classname
        }
      >
        <input
          {...(register && register(name, customRules))}
          {...rest}
          autoComplete="no-fill"
          type={hasCheckbox ? 'checkbox' : 'radio'}
          style={inputOptions?.styles}
          className="form-check-input"
        />
        {label && (
          <label
            htmlFor={name + Math.random() * 9999}
            className={`form-check-label text-gray-700 fw-bold text-nowrap ${labelOptions?.classname}`}
            style={labelOptions?.styles}
          >
            {label}
          </label>
        )}
        {error?.message ? (
          hasErrorCustom ? (
            <p className="error-checkbox">{'Obrigatório'}</p>
          ) : undefined
        ) : undefined}
        {error?.type === 'required' ? (
          hasErrorCustom ? (
            <p className="error-checkbox">{'Obrigatório'}</p>
          ) : undefined
        ) : undefined}
        {tooltip && (
          <TooltipComponent
            label={tooltip.label || ''}
            message={tooltip.message}
            classname={tooltip.classname}
            options={{
              icon: {
                styles: {
                  width: 36,
                  height: 36
                }
              }
            }}
            {...tooltip}
          />
        )}
      </div>
    </ContainerCustom>
  ) : (
    <Contanier className={className} erro={error}>
      {Icon && <Icon size={20} />}
      {label && (
        <label
          htmlFor={name}
          className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
        >
          {label}
        </label>
      )}
      <div>
        <input
          {...(register && register(name, customRules))}
          {...rest}
          autoComplete="no-fill"
          className="form-control form-control-solid"
        />
        {error?.message && (
          <Error title={'Obrigatório'}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
        {hasError?.error && (
          <Error title={'Obrigatório'}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
        {error?.type === 'required' && (
          <Error title={'Obrigatório'} position={rules?.position}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </div>
    </Contanier>
  )
}

export type SelectProps = SelectHTMLAttributes<HTMLSelectElement> & {
  register?: UseFormRegister<any>
  options: SelectOptions[]
  name: string
  label?: string
  rules?: RegisterOptions
  errors?: any
  controlled?: boolean
  fullControlled?: boolean
  hasCustom?: boolean
  blank?: boolean
  hasErrorCustom?: boolean
  labelOptions?: any
  selectOptions?: any
  containerOptions?: any
  hasValidationParent?: {
    parent_name: string
    condition?: string
  }
  tooltip?: {
    message: string
    classname?: string
    label?: string
    container?: {
      classname: string
    }
  }
}

export function Select({
  register,
  options,
  name,
  label,
  rules,
  errors,
  className,
  blank,
  hasCustom,
  labelOptions,
  selectOptions,
  containerOptions,
  tooltip,
  hasErrorCustom,
  hasValidationParent,
  ...rest
}: SelectProps) {
  const methods = useFormContext()
  const [customRules, setCustomRules] = useState(rules)
  useEffect(() => {
    if (hasValidationParent) {
      const watchField = methods.watch(hasValidationParent.parent_name)
      setCustomRules({ ...rules, required: watchField === 'true' })
      return
    }
    setCustomRules(rules)
  }, [hasValidationParent, methods, rules])
  const keys = name.split('.')
  let error = keys.length === 2 ? errors?.[keys[0]]?.[keys[1]] : errors?.[name]
  error = keys.length === 3 ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]] : error
  error =
    keys.length === 4
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]
      : error
  error =
    keys.length === 5
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]?.[keys[4]]
      : error
  return hasCustom ? (
    <SelectContanier
      style={containerOptions?.styles}
      erro={error}
      className={className + ' row'}
    >
      {label && (
        <label
          htmlFor={name}
          className={`col-form-label fw-bold fs-6 ${labelOptions?.classname}`}
          style={labelOptions?.styles}
        >
          {label}
        </label>
      )}
      <div className={selectOptions?.classname}>
        <select
          {...(register && register(name, customRules))}
          {...rest}
          className={'form-select form-select-solid fw-boldl'}
          style={selectOptions?.styles}
        >
          {blank && (
            <option key={0} value="" selected>
              Selecione
            </option>
          )}
          {options.map(option => (
            <option
              key={Math.random() * 9999}
              value={option.value}
              disabled={option.disabled}
              hidden={option.hidden}
            >
              {option.name}
            </option>
          ))}
        </select>
        {error?.message ? (
          hasErrorCustom ? (
            <p className="error">Obrigatório</p>
          ) : undefined
        ) : undefined}
        {error?.type === 'required' ? (
          hasErrorCustom ? (
            <p className="error">Obrigatório</p>
          ) : undefined
        ) : undefined}
        {tooltip && (
          <TooltipComponent
            label={tooltip.label || ''}
            message={tooltip.message}
            classname={tooltip.classname}
            options={{
              icon: {
                styles: {
                  width: 36,
                  height: 36
                }
              }
            }}
            {...tooltip}
          />
        )}
      </div>
    </SelectContanier>
  ) : (
    <SelectContanier erro={error} className={className}>
      {label && (
        <label htmlFor={name} className="col-form-label fw-bold fs-6">
          {label}
        </label>
      )}
      <div>
        <select
          {...(register && register(name, customRules))}
          {...rest}
          autoComplete="no-fill"
          className="form-select form-select-solid fw-boldl"
        >
          {blank && (
            <option key={0} value="" selected>
              selecione
            </option>
          )}
          {options.map(option => (
            <option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
              hidden={option.hidden}
            >
              {option.name}
            </option>
          ))}
        </select>
      </div>
    </SelectContanier>
  )
}

export type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  register?: UseFormRegister<any>
  name: string
  rules?: RegisterOptions
  errors?: any
  label?: string
  controlled?: boolean
  hasCustom?: boolean
  hasErrorCustom?: boolean
  hasValidationParent?: {
    parent_name: string
    condition?: string
  }
}

export function Textarea({
  register,
  name,
  label,
  rules,
  errors,
  className,
  hasCustom,
  hasErrorCustom,
  hasValidationParent,
  ...rest
}: TextareaProps) {
  const methods = useFormContext()
  const [customRules, setCustomRules] = useState(rules)
  useEffect(() => {
    if (hasValidationParent) {
      const watchField = methods.watch(hasValidationParent.parent_name)
      setCustomRules({ ...rules, required: watchField === 'true' })
      return
    }
    setCustomRules(rules)
  }, [hasValidationParent, methods, rules])
  const keys = name.split('.')
  let error = keys.length === 2 ? errors?.[keys[0]]?.[keys[1]] : errors?.[name]
  error = keys.length === 3 ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]] : error
  error =
    keys.length === 4
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]
      : error

  return (
    <TextAreaContanier
      erro={error}
      className={hasCustom ? 'col row ' + className : className}
    >
      {label && (
        <label htmlFor={name} className="col-form-label fw-bold fs-6">
          {label}
        </label>
      )}
      <div>
        <textarea
          {...(register && register(name, customRules))}
          className="form-control form-control-lg form-control-solid"
          {...rest}
          autoComplete="no-fill"
        />
        {error?.message ? (
          hasErrorCustom ? (
            <p className="error">Obrigatório</p>
          ) : null
        ) : undefined}
        {error?.type === 'required' ? (
          hasErrorCustom ? (
            <p className="error">Obrigatório</p>
          ) : null
        ) : undefined}
      </div>
    </TextAreaContanier>
  )
}
