import React from 'react'
import ContractsList from '../../pages/Commercial/Contracts/List'
import FinalInformation from '../../pages/Commercial/FinalInformation'
import ContractsView from '../../pages/Commercial/Contracts/View'
import PrivateRoute from '../PrivateRoute'
import { CustomSwitch } from '../../components/CustomSwitch'

export const ContractsRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoute
      path="/commercial/contracts"
      exact
      component={ContractsList}
    />
    <PrivateRoute
      path="/commercial/contracts/finalInformation/:id"
      exact
      component={FinalInformation}
    />
    <PrivateRoute
      path="/commercial/contracts/view/:id"
      exact
      component={ContractsView}
    />
  </CustomSwitch>
)
