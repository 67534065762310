import React, { useEffect, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../components/Container'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import { nameActions } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'
import moment from 'moment'
import { INPUT_MASK } from '../../../../common/constants'

const ContractProgressView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const { addToast } = useToast()

  const { disableLoading, activeLoading } = useLoading()
  const [contractProgress, setContractProgress] =
    useState<ContractProgressData | null>(null)

  useEffect(() => {
    activeLoading()
    async function loadContractProgress(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<ContractProgressData>(apiList(id))
        const { data } = response
        setContractProgress(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o contractProgresse',
          description:
            'Houve um error ao carregar o contractProgresse, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadContractProgress()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLoading, addToast, disableLoading, history, location.pathname])

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'ContractProgresse removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'ContractProgresse não removido, pois ainda está sendo usada.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }
  return (
    <div className="d-flex flex-column container-fluid">
      <>
        <Container
          pageTitle={nameActions.read.name}
          breadcrumb={breadcrumbView}
          tools={[
            toolsViewUpdate(String(id)),
            toolsViewDelete(() => {
              handleOnClickRemoveParent()
            }),
            toolsViewCreate(),
            toolsViewList()
          ]}
        >
          <div className="form-body">
            <div className="row mb-5">
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Cód.
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {contractProgress?.id}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Nome
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {contractProgress?.name}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="created"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Cadastrado em
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {moment(contractProgress?.created_at).format(
                      INPUT_MASK.DATE_LOCALE_WITH_TIME
                    )}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="updated"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Atualizado em
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {moment(contractProgress?.updated_at).format(
                      INPUT_MASK.DATE_LOCALE_WITH_TIME
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <Alert
          message={`Tem certeza que deseja excluir o registro ${contractProgress?.id} ?`}
          onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
          onClickConfirmButton={() =>
            handlerOnClickButtonConfirmRemoveParent(
              Number(contractProgress?.id)
            )
          }
          isActive={alertRemoveParent}
        />
      </>
    </div>
  )
}

export default ContractProgressView
