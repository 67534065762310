import React, { useCallback, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input } from '../../../../../components/Form'

import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'

import { FormContainer } from './styles'
type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}
type TypesFormProps = {
  isOpenInModal?: IsOpenInModalProps
  initialValues?: ContractApiResponse & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}
export const FormEditQuantity = ({
  isOpenInModal,
  initialValues
}: TypesFormProps): JSX.Element => {
  const { activeLoading, disableLoading } = useLoading()
  const { addToast } = useToast()

  const { updateDataTable } = useUpdateDataTable()
  const [applicants, setApplicants] = useState([])

  const onSubmitForm = useCallback(
    async (data: any) => {
      const applicants = data.applicants.map(
        (applicant: { applicant_name: string }) => {
          return {
            contract_id: initialValues.id,
            client_id: initialValues.client_id,
            form_type_id: initialValues.service.form_type_id,
            applicant_name: applicant.applicant_name
          }
        }
      )
      try {
        try {
          activeLoading()
          await api.post('/commercial/forms/createMany', { applicants })
          updateDataTable()
          disableLoading()
          isOpenInModal?.handleOnClose?.()
          addToast({
            type: 'success',
            title: 'Registro atualizado',
            description: 'Registro alterado com sucesso'
          })
        } catch (error) {
          disableLoading()
          isOpenInModal?.handleOnClose?.()
          addToast({
            type: 'error',
            title: 'Erro ao atualizar o registro',
            description:
              'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
          })
        }
        disableLoading()
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao atualizar',
          description:
            'Ocorreu um erro ao atualizar, por favor, tente novamente.'
        })
        isOpenInModal?.handleOnClose?.()
      }
    },
    [
      activeLoading,
      addToast,
      disableLoading,
      initialValues,
      isOpenInModal,
      updateDataTable
    ]
  )

  return (
    <>
      <Form onSubmit={onSubmitForm}>
        <>
          <div className="card">
            <FormContainer>
              <div className="d-flex justify-content-end">
                <span
                  className="text-primary cursor-pointer"
                  onClick={() => {
                    setApplicants(oldApplicants => [
                      ...oldApplicants,
                      { applicant_name: '' }
                    ])
                  }}
                >
                  Adicionar
                  <span className="fa fa-plus ms-2" />
                </span>
              </div>
              {applicants.map((_, index) => (
                <div
                  className="row mb-5"
                  style={{ display: 'flex', alignItems: 'center' }}
                  key={index}
                >
                  <Input
                    name={`applicants.${index}.applicant_name`}
                    label="Nome do aplicante"
                    className="col-md-9"
                  />
                  <div
                    className="col-md-2"
                    style={{ marginBottom: '-30px', marginRight: '-50px' }}
                  >
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        setApplicants(oldApplicants =>
                          oldApplicants.filter(
                            (_, oldIndex) => oldIndex !== index
                          )
                        )
                      }}
                    >
                      Remover
                    </button>
                  </div>
                </div>
              ))}
            </FormContainer>
            <div className="card-footer d-flex justify-content-end py-6 px-9 pe-0">
              <button type="submit" className="btn btn-primary">
                Salvar
              </button>
            </div>
          </div>
        </>
      </Form>
    </>
  )
}
