export const ENDPOINT = {
  CONTRACT: {
    UPDATE: (id: string | number) => `/commercial/contracts/update/${id}`,
    VIEW: (id: string | number) => `/commercial/contracts/view/${id}`
  },
  FINAL_INFORMATION: {
    CREATE: '/commercial/contractFinalInformation',
    DELETE: (id: string | number) =>
      `/commercial/contractFinalInformation/${id}`
  }
}
