import { useCallback } from 'react'
import { Container, ContainerActions, ImagePreview } from './styles'
import { FaFilePdf, FaFileWord } from 'react-icons/fa'
interface UploadProps {
  file: File
  handleRemoveFile?: (file: File) => void
}

const TYPES_IMAGES = ['png', 'jpg', 'jpeg', 'webpp', 'webp']

type FileTypes = 'pdf' | 'word'

const RENDER_ICON: Record<FileTypes, JSX.Element> = {
  pdf: <FaFilePdf size={150} />,
  word: <FaFileWord size={150} />
}

const handleGetExtension = (fileName: string): FileTypes => {
  const splitedFileName = fileName.split('.')
  return splitedFileName[splitedFileName.length - 1] as FileTypes
}

export const Upload = ({ file, handleRemoveFile }: UploadProps) => {
  const handleDownload = useCallback(() => {
    const blobURL = URL.createObjectURL(file)
    const a = document.createElement('a')
    a.href = blobURL
    a.download = file.name
    document.body.appendChild(a)
    a.click()
  }, [file])

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        {TYPES_IMAGES.some(ext => file?.name.includes(ext)) ? (
          <ImagePreview>
            <img src={URL.createObjectURL(file)} alt={file.name} />
          </ImagePreview>
        ) : (
          <div
            style={{
              display: 'flex',
              placeContent: 'center'
            }}
          >
            {RENDER_ICON[handleGetExtension(file.name)]}
          </div>
        )}

        <span
          style={{
            maxWidth: '250px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
          }}
          className="form-label fw-bold fs-6 mt-2"
        >
          {file.name}
        </span>
      </div>

      <ContainerActions>
        <button
          type="button"
          className="btn btn-primary me-5"
          onClick={handleDownload}
        >
          Baixar
        </button>
        {!!handleRemoveFile && (
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handleRemoveFile?.(file)}
          >
            Excluir
          </button>
        )}
      </ContainerActions>
    </Container>
  )
}
