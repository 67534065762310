import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`

export const ImagePreview = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  img {
    height: 150px;
    width: auto;
    object-fit: contain;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
  }
`

export const ContainerActions = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
`
