export const PHASE_PROCESS = {
  FILL_NOT_STARTED: 3,
  FILL_STARTED: 1,
  FULL_FILL: 2,
  NEED_FIX: 33,
  FIX_FINISHER: 37,
  READY_FOR_APPLICATION: 38,
  PROCESS_FINISHED_WITH_INTERVIEW: 35,
  PROCESS_FINISHED: 36,
  TO_SCHEDULE: 39,
  TAX: 47,
  GUIDE: 46
}
