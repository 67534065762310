import React, { ChangeEvent, useCallback, useRef, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Select, Textarea } from '../../../../../components/Form'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiDeleteDocument, apiUploadDocuments } from '../../domain/api'
// import { Upload } from '../Upload'
import { getFileNameWithOutHash } from '../../../../../utils/getFileNameWithOutHash'
import { Alert } from '../../../../../components/Alert'
import { Loading } from '../../../../../components/Loading'
// import { FaCheck, FaDownload, FaTrash } from 'react-icons/fa'
import { v4 as uuidv4 } from 'uuid'

type IsOpenInModalProps = {
  handleOnClose: () => void
}
type TypesFormProps = {
  updateDocuments?: (data: any) => void
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: {
    id: string
    formDocuments: any[]
  }
}
export const FormAddDocument = ({
  isOpenInModal,
  updateDocuments,
  initialValues
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const inputFileRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const { updateDataTable } = useUpdateDataTable()
  const [documents, setDocuments] = useState<Documents[]>([])
  // const [excludeDocuments, setExcludeDocuments] = useState<number[]>([])
  const [alert, setIsActiveAlert] =
    useState<{ isActive: boolean; document: File }>()

  const { activeLoading, disableLoading } = useLoading()

  const onSubmitForm = useCallback(
    async data => {
      setIsLoading(true)
      const formData = new FormData()
      const newDocuments = documents.filter(newDocument => {
        const isDuplicated = !!initialValues.formDocuments.find(
          oldDocument =>
            getFileNameWithOutHash(oldDocument.file) ===
            newDocument.archive.name
        )
        return !isDuplicated
      })
      newDocuments.forEach(document => {
        formData.append('documents', document.archive)
      })

      formData.append('form_id', initialValues.id)
      formData.append('visibility', data.visibility)
      formData.append('message', data.message)
      formData.append('has_confirmation', data.has_confirmation)
      // formData.append('excludeDocuments', JSON.stringify(excludeDocuments))

      if (!isOpenInModal) return
      const { handleOnClose } = isOpenInModal

      try {
        await api.post(apiUploadDocuments(), formData)
        updateDocuments({
          id: uuidv4(),
          archive: documents[0].archive,
          file_url: URL.createObjectURL(documents[0].archive),
          has_confirmation: data.has_confirmation,
          file: documents[0].archive.name
        })
        updateDataTable()
        disableLoading()
        handleOnClose()
        addToast({
          type: 'success',
          title: 'Registro atualizado',
          description: 'Registro alterado com sucesso'
        })
      } catch (error) {
        handleOnClose()
        addToast({
          type: 'error',
          title: 'Erro ao atualizar o registro',
          description:
            'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
        })
      } finally {
        setIsLoading(false)
      }
    },
    [
      addToast,
      disableLoading,
      documents,
      initialValues.formDocuments,
      initialValues.id,
      isOpenInModal,
      updateDataTable,
      updateDocuments
    ]
  )

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        let newDocuments = documents
        const isDuplicated = documents.find(
          document => document.archive?.name === event.target.files[0]?.name
        )
        if (isDuplicated) {
          if (inputFileRef.current) {
            inputFileRef.current.value = ''
          }
        } else {
          newDocuments = [
            ...documents,
            { archive: event.target.files[0], id: uuidv4() }
          ]
        }
        setDocuments(newDocuments)
      }
    },
    [documents]
  )

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert(oldAlert => ({
      ...oldAlert,
      isActive: false
    }))
  }

  const handleRemoveFile = useCallback(
    async (file: File) => {
      setIsLoading(true)
      if (initialValues.formDocuments.length) {
        const findDocument = initialValues.formDocuments.find(document => {
          const fileName = getFileNameWithOutHash(document.file)
          return fileName === file.name
        })
        if (findDocument) {
          try {
            await api.delete(apiDeleteDocument(findDocument.id))
            setIsLoading(false)
            addToast({
              title: 'Documento removido com sucesso',
              type: 'success'
            })
          } catch (error) {
            setIsLoading(true)
            addToast({
              title: 'Documento removido com sucesso',
              type: 'error'
            })
          }
        }
      }
      if (inputFileRef.current) {
        inputFileRef.current.value = ''
      }
      setDocuments(oldDocuments =>
        oldDocuments.filter(document => document.archive.name !== file.name)
      )
    },
    [addToast, initialValues?.formDocuments]
  )
  // const handleDownload = useCallback((file: File) => {
  //   const blobURL = URL.createObjectURL(file)
  //   const a = document.createElement('a')
  //   a.href = blobURL
  //   a.download = file.name
  //   document.body.appendChild(a)
  //   a.click()
  // }, [])
  // const handleRenameFile = useCallback(({ event, id }: UploadOnChangeProps) => {
  //   if (event.target.value === '') return
  //   setDocuments(oldDocuments =>
  //     oldDocuments.map(oldFile => {
  //       if (oldFile.id === id) {
  //         if (!Number.isNaN(oldFile.id)) {
  //           setExcludeDocuments(oldExcludeDocuments =>
  //             oldExcludeDocuments.includes(Number(oldFile.id))
  //               ? oldExcludeDocuments
  //               : [...oldExcludeDocuments, Number(oldFile.id)]
  //           )
  //         }
  //         const findExtensionIndex = oldFile.archive.name.lastIndexOf('.')
  //         const extension = oldFile.archive.name.slice(
  //           findExtensionIndex,
  //           oldFile.archive.name.length
  //         )
  //         return {
  //           ...oldFile,
  //           archive: new File(
  //             [oldFile.archive],
  //             event.target.value + `${extension}`
  //           )
  //         }
  //       }

  //       return { ...oldFile }
  //     })
  //   )
  // }, [])

  if (isLoading) {
    return <Loading isActive />
  }

  return (
    <>
      <Form onSubmit={onSubmitForm}>
        <>
          <div className="card">
            <div>
              <div className="row mb-5">
                <Select
                  className="col-md-3"
                  name="visibility"
                  label="Visível para"
                  options={[
                    {
                      name: 'Todos',
                      value: 'all'
                    },
                    {
                      name: 'Somente eu',
                      value: 'me'
                    }
                  ]}
                  blank
                  defaultValue=""
                  rules={{ required: true }}
                />
                <Select
                  className="col-md-3"
                  name="has_confirmation"
                  label="Precisa de confirmação"
                  options={[
                    {
                      name: 'Sim',
                      value: '1'
                    },
                    {
                      name: 'Não',
                      value: '0'
                    }
                  ]}
                  blank
                  defaultValue=""
                  rules={{ required: true }}
                />
                <div className="col-md-6 mt-13">
                  <input
                    className="form-control form-control-solid"
                    type="file"
                    ref={inputFileRef}
                    onChange={handleFileChange}
                  />
                </div>
              </div>
              <div className="row mb-5">
                <Textarea
                  className="col-md-12"
                  name="message"
                  label="Mensagem"
                  style={{ minHeight: '100px' }}
                />
              </div>
              {/* <div className="row mb-5">
                <input
                  className="form-control form-control-solid"
                  type="file"
                  ref={inputFileRef}
                  onChange={handleFileChange}
                />
              </div> */}
              {/* <div className="row mb-5" style={{ gap: '1rem' }}>
                {documents.map(document => (
                  <Upload.Root key={document.archive.name}>
                    <Upload.Preview file={document.archive} />
                    <Upload.Content
                      document={{ file: document.archive, id: document.id }}
                      onChange={handleRenameFile}
                    />
                    <Upload.Message message={document.message} />
                    <Upload.Actions>
                      {document.has_confirmation ? (
                        <>
                          <Upload.Action
                            className="btn btn-light-primary me-5"
                            onCLick={() => handleDownload(document.archive)}
                            icon={FaDownload}
                          />
                          <Upload.Action
                            className={`btn btn${
                              document.confirmed_at ? '' : '-light'
                            }-success me-5`}
                            icon={FaCheck}
                            disabled={!!document.confirmed_at}
                          />
                        </>
                      ) : (
                        <>
                          <Upload.Action
                            className="btn btn-light-primary me-5"
                            onCLick={() => handleDownload(document.archive)}
                            icon={FaDownload}
                          />
                          <Upload.Action
                            className="btn btn-light-danger me-5"
                            onCLick={() => {
                              if (initialValues.formDocuments.length) {
                                setIsActiveAlert({
                                  document: document.archive,
                                  isActive: true
                                })
                                return
                              }
                              handleRemoveFile(document.archive)
                            }}
                            icon={FaTrash}
                          />
                        </>
                      )}
                    </Upload.Actions>
                  </Upload.Root>
                ))}
              </div> */}
            </div>
            <div className="card-footer d-flex justify-content-end py-6 ">
              <button type="submit" className="btn btn-primary">
                Enviar arquivos
              </button>
            </div>
          </div>
        </>
      </Form>

      {alert && alert.isActive && (
        <Alert
          message={`Tem certeza que deseja excluir esse documento ${alert.document.name} ? ele não poderá ser recuperado`}
          onClickCancellButton={handlerClickButtonCancellAlert}
          onClickConfirmButton={() => {
            handleRemoveFile(alert.document)
            setIsActiveAlert(oldAlert => ({
              ...oldAlert,
              isActive: false
            }))
          }}
          isActive={alert.isActive}
        />
      )}
    </>
  )
}
