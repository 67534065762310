import React, { useCallback, useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select, Textarea } from '../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { FormContainer } from './styles'
import { SELECT_INSTALLMENTS_NUMBER } from '../../../../../common/constants/installments-number'
import { SELECT_SERVICE_FORMAT } from '../../../../../common/constants/service-formart'
import { getFileNameWithOutHash } from '../../../../../utils/getFileNameWithOutHash'
import { Upload } from '../Upload'
import { Alert } from '../../../../../components/Alert'
import { Loading } from '../../../../../components/Loading'
import {
  convertValueMaskInNumberWithTwoZero,
  genericMaskWithTwoZeroWithPoint
} from '../../../../../utils/mask'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}
type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: ServicesApiResponse & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}
const toastSuccessData: Record<string, () => ToastMessage> = {
  create: () => ({
    type: 'success',
    title: 'Registro criado',
    description: 'Registro criado com sucesso'
  }),
  update: () => ({
    type: 'success',
    title: 'Registro atualizado',
    description: 'Registro alterado com sucesso'
  })
}
const toastErrorData: Record<string, () => ToastMessage> = {
  create: () => ({
    type: 'error',
    title: 'Erro ao adicionar o registro',
    description:
      'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
  }),
  update: () => ({
    type: 'error',
    title: 'Erro ao atualizar',
    description:
      'Ocorreu um erro ao alterar os dados, por favor, tente novamente.'
  })
}

export const FormServices = ({
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [formTypes, setFormTypes] = useState([])
  const [serviceCategories, setServiceCategories] = useState([])
  const { activeLoading, disableLoading } = useLoading()
  const [isLoading, setIsLoading] = useState(false)
  const [coverImage, setCoverImage] = useState<ServiceFile>()
  const [imageDescription, setImageDescription] = useState<ServiceFile>()

  const [alert, setIsActiveAlert] =
    useState<{ isActive: boolean; document: File }>()

  useEffect(() => {
    activeLoading()
    async function loadServiceCategories(): Promise<void> {
      activeLoading()
      try {
        const responseFormTypes = await api.get<FormTypeApiResponse[]>(
          '/commercial/public/servicesCategories'
        )
        setServiceCategories(
          responseFormTypes.data.map(serviceCategory => ({
            name: serviceCategory.name,
            value: serviceCategory.id
          }))
        )
        disableLoading()
      } catch (err) {
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar as categorias',
          description:
            'Houve um error ao carregar as categorias, tente novamente mais tarde!'
        })
      }
    }
    loadServiceCategories()
  }, [activeLoading, addToast, disableLoading, history])

  useEffect(() => {
    activeLoading()
    async function loadFormTypes(): Promise<void> {
      activeLoading()
      try {
        const responseFormTypes = await api.get<FormTypeApiResponse[]>(
          '/commercial/formTypes'
        )
        setFormTypes(
          responseFormTypes.data.map(formType => ({
            name: formType.name,
            value: formType.id
          }))
        )
        disableLoading()
      } catch (err) {
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar os tipos de formulários',
          description:
            'Houve um error ao carregar os tipos de formulários, tente novamente mais tarde!'
        })
      }
    }
    loadFormTypes()
  }, [activeLoading, addToast, disableLoading, history])

  const onSubmitForm = useCallback(
    async (data: any) => {
      const formData = new FormData()
      Object.entries(data).forEach(([key, value]: [string, any]) => {
        if (['image_description', 'cover_image'].includes(key)) {
          return
        }
        if (!value) {
          return
        }
        if (key === 'value') {
          formData.append(
            key,
            convertValueMaskInNumberWithTwoZero(value).toString()
          )
          return
        }
        formData.append(key, value)
      })
      if (coverImage?.isNew) {
        formData.append('cover_image', coverImage.file)
      }
      if (imageDescription?.isNew) {
        formData.append('image_description', imageDescription.file)
      }
      const id = initialValues?.idUpdate
      const apiRequest =
        typeForm === 'create'
          ? api.post(apiCreate(), formData)
          : api.put(apiUpdate(id.toString()), formData)

      try {
        activeLoading()
        await apiRequest
        disableLoading()
        addToast(toastSuccessData[typeForm]?.())
        if (typeForm === 'update') {
          updateDataTable()
        }
        history.push(nameActions.read.to)
      } catch (error) {
        history.push(nameActions.read.to)
        addToast(toastErrorData[typeForm]?.())
      }
    },
    [
      activeLoading,
      addToast,
      coverImage,
      disableLoading,
      history,
      imageDescription,
      initialValues?.idUpdate,
      typeForm,
      updateDataTable
    ]
  )

  useEffect(() => {
    const newDocuments: File[] = []
    setIsLoading(true)
    Promise.all([
      !initialValues?.cover_image_url
        ? null
        : fetch(initialValues.cover_image_url)
            .then(response => response.blob())
            .then(blob => {
              const fileName = getFileNameWithOutHash(initialValues.cover_image)
              const file = new File([blob], fileName)
              setCoverImage({ isNew: false, file })
              newDocuments.push(file)
            })
            .catch(error => {
              console.error('Erro ao baixar o arquivo:', error)
            }),
      !initialValues?.image_description_url
        ? null
        : fetch(initialValues.image_description_url)
            .then(response => response.blob())
            .then(blob => {
              const fileName = getFileNameWithOutHash(
                initialValues.image_description
              )
              const file = new File([blob], fileName)
              setImageDescription({ isNew: false, file })
              newDocuments.push(file)
            })
            .catch(error => {
              console.error('Erro ao baixar o arquivo:', error)
            })
    ]).then(() => {
      setIsLoading(false)
    })
  }, [
    initialValues?.cover_image,
    initialValues?.cover_image_url,
    initialValues?.image_description,
    initialValues?.image_description_url
  ])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert(oldAlert => ({
      ...oldAlert,
      isActive: false
    }))
  }

  if (isLoading) {
    return <Loading isActive />
  }

  return (
    <>
      <Form
        onSubmit={onSubmitForm}
        defaultValues={{
          id: initialValues?.id,
          type: initialValues?.type,
          form_type_id: initialValues?.form_type_id,
          name: initialValues?.name,
          value: initialValues?.value
            ? genericMaskWithTwoZeroWithPoint(
                initialValues?.value?.toString() || '0'
              )
            : undefined,
          service_format: initialValues?.service_format,
          description: initialValues?.description,
          resume: initialValues?.resume,
          youtube_url: initialValues?.youtube_url,
          active: initialValues?.active,
          image_description: initialValues?.image_description,
          cover_image: initialValues?.cover_image,
          image_description_url: initialValues?.image_description_url,
          cover_image_url: initialValues?.cover_image_url,
          quantity: initialValues?.quantity,
          position: initialValues?.position,
          code: initialValues?.code,
          service_category_id: initialValues?.service_category_id,
          interest_free_installments: initialValues?.interest_free_installments
        }}
      >
        <>
          <div className="card">
            <FormContainer>
              <div className="row mb-5">
                <Input
                  name="name"
                  label="Nome"
                  rules={{ required: true }}
                  className="col-md-6"
                />
                <Select
                  name="service_format"
                  label="Forma de atendimento"
                  rules={{ required: true }}
                  className="col-md-2"
                  options={SELECT_SERVICE_FORMAT}
                />
                <Input
                  name="value"
                  label="Valor"
                  rules={{ required: true }}
                  className="col-md-2"
                  price
                />
                <Input
                  name="quantity"
                  label="Quantidade"
                  type="number"
                  rules={{ required: true }}
                  className="col-md-1"
                />
                <Input
                  name="position"
                  label="Posição"
                  type="number"
                  rules={{ required: false }}
                  className="col-md-1"
                />
              </div>
              <div className="row mb-5">
                <Select
                  name="interest_free_installments"
                  label="Parcelas sem juros"
                  rules={{ required: false }}
                  className="col-md-3"
                  options={[
                    {
                      name: 'Sem parcelamento sem juros',
                      value: 0
                    },
                    ...SELECT_INSTALLMENTS_NUMBER
                  ]}
                />
                <Select
                  className="col-md-3"
                  name="form_type_id"
                  label="Tipo de formulário"
                  options={formTypes}
                  rules={{ required: true }}
                  blank
                />
                <Select
                  className="col-md-3"
                  name="service_category_id"
                  label="Categoria"
                  options={serviceCategories}
                  rules={{ required: true }}
                  blank
                />
                {/* <Input
                  name="type"
                  label="Tipo"
                  rules={{ required: true }}
                  className="col-md-3"
                /> */}
                <Input
                  name="code"
                  label="Código"
                  rules={{ required: true }}
                  className={typeForm === 'update' ? 'col-md-2' : 'col-md-3'}
                  maxLength={5}
                />
                {typeForm === 'update' && (
                  <Select
                    className="col-md-1"
                    name="active"
                    label="Ativo"
                    options={[
                      {
                        name: 'Sim',
                        value: 1
                      },
                      {
                        name: 'Não',
                        value: 0
                      }
                    ]}
                    rules={{ required: true }}
                    blank
                  />
                )}
              </div>
              <div>
                <Input
                  name="youtube_url"
                  label="Link do video no youtube"
                  rules={{ required: true }}
                  className="col-md-12"
                />
              </div>
              <div className="row mb-5">
                <Input
                  name="cover_image"
                  label="Imagem da capa"
                  className="col-md-6"
                  type="file"
                  onChange={event =>
                    setCoverImage({ file: event.target.files[0], isNew: true })
                  }
                />
                <Input
                  name="image_description"
                  label="Imagem da descrição"
                  className="col-md-6"
                  type="file"
                  onChange={event =>
                    setImageDescription({
                      isNew: true,
                      file: event.target.files[0]
                    })
                  }
                />
              </div>
              {(imageDescription?.file || coverImage?.file) && (
                <div className="row mb-5" style={{ minHeight: '250px' }}>
                  <div className="col-md-6">
                    {coverImage?.file && (
                      <Upload
                        file={coverImage.file}
                        handleRemoveFile={() => {
                          setCoverImage({ isNew: true, file: null })
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-6">
                    {imageDescription?.file && (
                      <Upload
                        file={imageDescription.file}
                        handleRemoveFile={() => {
                          setImageDescription({ isNew: true, file: null })
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
              <div className="row mb-5">
                <Textarea
                  name="resume"
                  label="Resumo"
                  rules={{ required: true }}
                  style={{ minHeight: '100px' }}
                  className="col-md-12"
                />
              </div>
              <div className="row mb-5">
                <Textarea
                  name="description"
                  label="Descrição"
                  rules={{ required: true }}
                  style={{ minHeight: '100px' }}
                  className="col-md-12"
                />
              </div>
            </FormContainer>
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button type="submit" className="btn btn-primary">
                Salvar
              </button>
            </div>
          </div>
        </>
      </Form>
      {alert && alert.isActive && (
        <Alert
          message={`Tem certeza que deseja excluir esse documento ${alert.document.name} ? ele não poderá ser recuperado`}
          onClickCancellButton={handlerClickButtonCancellAlert}
          onClickConfirmButton={() => {
            // handleRemoveFile(alert.document)
            setIsActiveAlert(oldAlert => ({
              ...oldAlert,
              isActive: false
            }))
          }}
          isActive={alert.isActive}
        />
      )}
    </>
  )
}
