import React from 'react'
import PhaseProcessList from '../../pages/Commercial/PhaseProcess/List'
import PhaseProcessCreate from '../../pages/Commercial/PhaseProcess/Create'
import PhaseProcessUpdate from '../../pages/Commercial/PhaseProcess/Update'
import PhaseProcessView from '../../pages/Commercial/PhaseProcess/View'
import PrivateRoute from '../PrivateRoute'
import { CustomSwitch } from '../../components/CustomSwitch'

export const PhaseProcessRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoute
      path="/commercial/phasesProcess"
      exact
      component={PhaseProcessList}
    />
    <PrivateRoute
      path="/commercial/phasesProcess/create"
      exact
      component={PhaseProcessCreate}
    />
    <PrivateRoute
      path="/commercial/phasesProcess/view/:id"
      exact
      component={PhaseProcessView}
    />
    <PrivateRoute
      path="/commercial/phasesProcess/update/:id"
      exact
      component={PhaseProcessUpdate}
    />
  </CustomSwitch>
)
