import React, { useCallback, useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Textarea } from '../../../../../components/Form'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { FormContainer } from './styles'

type IsOpenInModalProps = {
  contract_id: number
  handleOnClose: () => void
}
type TypesFormProps = {
  isOpenInModal?: IsOpenInModalProps
  initialValues?: { id: number; contract_id: number; description: string } & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}
export const FormContractHistory = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()

  const { updateDataTable } = useUpdateDataTable()
  const [defaultValues, setDefaultValues] = useState({})

  const { activeLoading, disableLoading } = useLoading()

  const onSubmitForm = useCallback(
    async (data: any) => {
      data.contract_id = isOpenInModal.contract_id
      if (typeForm === 'update') {
        try {
          activeLoading()
          await api.put(
            `/commercial/contractHistories/update/${initialValues?.id}`,
            data
          )
          updateDataTable()
          disableLoading()
          isOpenInModal?.handleOnClose?.()
          addToast({
            type: 'success',
            title: 'Registro atualizado',
            description: 'Registro atualizado com sucesso'
          })
        } catch (error) {
          disableLoading()
          isOpenInModal?.handleOnClose?.()
          addToast({
            type: 'error',
            title: 'Erro ao atualizar o registro',
            description:
              'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
          })
        }
        return
      }
      try {
        activeLoading()
        await api.post('/commercial/contractHistories', data)
        updateDataTable()
        disableLoading()
        isOpenInModal?.handleOnClose?.()
        addToast({
          type: 'success',
          title: 'Registro adicionado',
          description: 'Registro adicionado com sucesso'
        })
      } catch (error) {
        disableLoading()
        isOpenInModal?.handleOnClose?.()
        addToast({
          type: 'error',
          title: 'Erro ao adicionado o registro',
          description:
            'Ocorreu um erro ao fazer a adicionado, por favor, tente novamente.'
        })
      }
      disableLoading()
    },
    [
      activeLoading,
      addToast,
      disableLoading,
      initialValues?.id,
      isOpenInModal,
      typeForm,
      updateDataTable
    ]
  )

  useEffect(() => {
    setDefaultValues({
      description: initialValues?.description,
      contract_id: initialValues?.contract_id
    })
  }, [initialValues])

  return (
    <>
      <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
        <>
          <div className="card">
            <FormContainer>
              <div className="row mb-5">
                <Textarea
                  name="description"
                  label="Descrição"
                  rules={{ required: true }}
                  style={{ minHeight: 100 }}
                  className="col-md-12"
                />
              </div>
            </FormContainer>
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button type="submit" className="btn btn-primary">
                Salvar
              </button>
            </div>
          </div>
        </>
      </Form>
    </>
  )
}
