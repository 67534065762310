import React from 'react'
import ServicesList from '../../pages/Commercial/Services/List'
import ServicesCreate from '../../pages/Commercial/Services/Create'
import ServicesUpdate from '../../pages/Commercial/Services/Update'
import ServicesView from '../../pages/Commercial/Services/View'
import PrivateRoute from '../PrivateRoute'
import { CustomSwitch } from '../../components/CustomSwitch'

export const ServicesRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoute path="/commercial/services" exact component={ServicesList} />
    <PrivateRoute
      path="/commercial/services/create"
      exact
      component={ServicesCreate}
    />
    <PrivateRoute
      path="/commercial/services/view/:id"
      exact
      component={ServicesView}
    />
    <PrivateRoute
      path="/commercial/services/update/:id"
      exact
      component={ServicesUpdate}
    />
  </CustomSwitch>
)
