import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import api from '../../../../../services/api'
import Form from '../../../../../components/Form'
import { ResponsiveTable } from '../../../../../components/ReponsiveTable'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiDeleteDocument, apiUploadDocuments } from '../../domain/api'
import { getFileNameWithOutHash } from '../../../../../utils/getFileNameWithOutHash'
import { Alert } from '../../../../../components/Alert'
import { Loading } from '../../../../../components/Loading'
import Modal from '../../../../../components/Modal'
import { FormAddDocument } from '../FormAddDocument'
import {
  FaDownload,
  FaFilePdf,
  FaFileWord,
  FaTrash,
  FaImage,
  FaEdit,
  FaSave
} from 'react-icons/fa'
import styled from 'styled-components'
import { handleGetExtension } from '../../../../../utils/getFileExtension'
import { INPUT_MASK } from '../../../../../common/constants'
import moment from 'moment'

type EditSaveFileProps = {
  isEditMode: boolean
  onClick: () => void
}
type ContainerEditFileProps = {
  item: FileProps
  isEditMode: boolean
  onChange: ({
    event,
    id
  }: {
    event: ChangeEvent<HTMLInputElement>
    id: string
  }) => void
}
type IsOpenInModalProps = {
  handleOnClose: () => void
}
type TypesFormProps = {
  isOpenInModal?: IsOpenInModalProps
  initialValues?: {
    id: string
    formDocuments: any[]
  }
}
const ContainerFile = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #009ef7;
  cursor: pointer;
`

const RENDER_ICON: Record<FileTypes, JSX.Element> = {
  pdf: <FaFilePdf size={16} />,
  word: <FaFileWord size={16} />
}

function handleDownload(file: File) {
  const blobURL = URL.createObjectURL(file)
  const a = document.createElement('a')
  a.href = blobURL
  a.download = file.name
  document.body.appendChild(a)
  a.click()
}
function EditSaveFile({ isEditMode, onClick }: EditSaveFileProps) {
  if (isEditMode) {
    return (
      <FaSave
        style={{ cursor: 'pointer' }}
        color="#009EF7"
        size={16}
        onClick={onClick}
      />
    )
  }
  return (
    <FaEdit
      style={{ cursor: 'pointer' }}
      size={16}
      onClick={onClick}
      color="#009EF7"
    />
  )
}

function ContainerEditFile({
  item,
  isEditMode,
  onChange
}: ContainerEditFileProps) {
  if (isEditMode) {
    return (
      <input
        type="text"
        className="form-control"
        onChange={event => onChange({ event, id: item.id })}
        disabled={!onChange}
        value={
          item?.archive?.name.slice(0, item?.archive?.name.lastIndexOf('.')) ||
          ''
        }
      />
    )
  }
  return (
    <ContainerFile>
      <p>{item.archive.name}</p>
      <a href="#" title="Baixar arquivo">
        <FaDownload size={16} onClick={() => handleDownload(item.archive)} />
      </a>
    </ContainerFile>
  )
}

export const FormDocument = ({
  isOpenInModal,
  initialValues
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const inputFileRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isEditMode, setIsEditMode] = useState<Record<string, boolean>>({})
  const [excludeDocuments, setExcludeDocuments] = useState<number[]>([])
  const { updateDataTable } = useUpdateDataTable()
  const [documents, setDocuments] = useState<Documents[]>([])
  const [openModalFolder, setOpenModalFolder] = useState(false)
  const [alert, setIsActiveAlert] =
    useState<{ isActive: boolean; document: File }>()
  useEffect(() => {
    if (initialValues) {
      // setIsLoading(true)
      if (documents.length) {
        // setIsLoading(false)
        return
      }
      const newDocuments: Documents[] = []
      const fetchPromises = initialValues.formDocuments.map(document =>
        fetch(document.file_url)
          .then(response => response.blob())
          .then(blob => {
            const fileName = getFileNameWithOutHash(document.file)
            const file = new File([blob], fileName)
            newDocuments.push({ archive: file, ...document })
          })
          .catch(error => {
            console.error('Erro ao baixar o arquivo:', error)
          })
      )
      Promise.all(fetchPromises).then(() => {
        setIsLoading(false)
        setDocuments(newDocuments)
      })
    }
  }, [initialValues])

  const { activeLoading, disableLoading } = useLoading()

  const onSubmitForm = useCallback(async () => {
    const formData = new FormData()
    const newDocuments = documents.filter(newDocument => {
      const isDuplicated = !!initialValues.formDocuments.find(
        oldDocument =>
          getFileNameWithOutHash(oldDocument.file) === newDocument.archive.name
      )
      return !isDuplicated
    })
    newDocuments.forEach(document => {
      formData.append('documents', document.archive)
    })

    formData.append('form_id', initialValues.id)
    formData.append('excludeDocuments', JSON.stringify(excludeDocuments))

    if (!isOpenInModal) return
    const { handleOnClose } = isOpenInModal

    try {
      activeLoading()
      await api.post(apiUploadDocuments(), formData)
      updateDataTable()
      disableLoading()
      handleOnClose()
      addToast({
        type: 'success',
        title: 'Registro atualizado',
        description: 'Registro alterado com sucesso'
      })
    } catch (error) {
      disableLoading()
      handleOnClose()
      addToast({
        type: 'error',
        title: 'Erro ao atualizar o registro',
        description:
          'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
      })
    }
    disableLoading()
  }, [
    activeLoading,
    addToast,
    disableLoading,
    documents,
    excludeDocuments,
    initialValues.formDocuments,
    initialValues.id,
    isOpenInModal,
    updateDataTable
  ])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert(oldAlert => ({
      ...oldAlert,
      isActive: false
    }))
  }

  const handleRemoveFile = useCallback(
    async (file: File) => {
      setIsLoading(true)
      if (initialValues.formDocuments.length) {
        const findDocument = initialValues.formDocuments.find(document => {
          const fileName = getFileNameWithOutHash(document.file)
          return fileName === file.name
        })
        if (findDocument) {
          try {
            await api.delete(apiDeleteDocument(findDocument.id))
            setIsLoading(false)
            addToast({
              title: 'Documento removido com sucesso',
              type: 'success'
            })
          } catch (error) {
            console.log(error)
            setIsLoading(true)
            addToast({
              title: 'Erro ao remover o arquivo',
              type: 'error'
            })
          }
        }
      }
      if (inputFileRef.current) {
        inputFileRef.current.value = ''
      }
      setDocuments(oldDocuments =>
        oldDocuments.filter(document => document.archive.name !== file.name)
      )
    },
    [addToast, initialValues?.formDocuments]
  )

  const handleClickOnCloseDocuments = () => {
    setOpenModalFolder(false)
  }
  const handleRenameFile = useCallback(({ event, id }: UploadOnChangeProps) => {
    if (event.target.value === '') return
    setDocuments(oldDocuments =>
      oldDocuments.map(oldFile => {
        if (oldFile.id === id) {
          if (!Number.isNaN(oldFile.id)) {
            setExcludeDocuments(oldExcludeDocuments =>
              oldExcludeDocuments.includes(Number(oldFile.id))
                ? oldExcludeDocuments
                : [...oldExcludeDocuments, Number(oldFile.id)]
            )
          }
          const findExtensionIndex = oldFile.archive.name.lastIndexOf('.')
          const extension = oldFile.archive.name.slice(
            findExtensionIndex,
            oldFile.archive.name.length
          )
          return {
            ...oldFile,
            archive: new File(
              [oldFile.archive],
              event.target.value + `${extension}`
            )
          }
        }

        return { ...oldFile }
      })
    )
  }, [])
  if (isLoading || !initialValues?.formDocuments) {
    return <Loading isActive />
  }

  return (
    <>
      <div style={{ height: '100%' }}>
        <Form onSubmit={onSubmitForm}>
          <div className="card" style={{ height: '100%' }}>
            <div
              className="d-flex flex-column justify-content-between"
              style={{ height: '100%' }}
            >
              <div className="d-flex mb-5 justify-content-end">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setOpenModalFolder(true)
                  }}
                >
                  Adicionar
                </button>
              </div>
              <ResponsiveTable
                headers={[
                  {
                    field: 'type',
                    name: 'Tipo',
                    element: item => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          {RENDER_ICON[
                            handleGetExtension(item.archive.name)
                          ] || <FaImage size={16} />}
                        </div>
                      )
                    }
                  },
                  {
                    field: 'file',
                    name: 'Arquivo',
                    element: item => {
                      return (
                        <ContainerEditFile
                          isEditMode={!!isEditMode[item.id]}
                          item={item}
                          onChange={handleRenameFile}
                        />
                      )
                    }
                  },
                  {
                    field: 'has_confirmation',
                    name: 'Precisa de confirmação',
                    element: item => {
                      console.log(item)
                      return (
                        <p>{Number(item.has_confirmation) ? 'SIM' : 'NÃO'}</p>
                      )
                    }
                  },
                  {
                    field: 'confirmed_at',
                    name: 'Confirmado em',
                    element: item => {
                      if (!item.confirmed_at) {
                        return null
                      }
                      return (
                        <p>
                          {moment(
                            item.confirmed_at,
                            INPUT_MASK.DATE_LOCALE
                          ).format(INPUT_MASK.DATE_LOCALE)}
                        </p>
                      )
                    }
                  },
                  {
                    field: 'actions',
                    name: 'Ações',
                    element: item => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '10px'
                          }}
                        >
                          <EditSaveFile
                            isEditMode={!!isEditMode[item.id]}
                            onClick={() => {
                              setIsEditMode(oldIsEditMode => ({
                                ...oldIsEditMode,
                                [item.id]: !oldIsEditMode[item.id]
                              }))
                            }}
                          />
                          <FaTrash
                            style={{ cursor: 'pointer' }}
                            color="#009EF7"
                            size={16}
                            onClick={() => {
                              if (initialValues.formDocuments.length) {
                                setIsActiveAlert({
                                  document: item.archive,
                                  isActive: true
                                })
                                return
                              }
                              handleRemoveFile(item.archive)
                            }}
                          />
                        </div>
                      )
                    }
                  }
                ]}
                rows={documents.map(item => {
                  return {
                    ...item,
                    file_url: item.file_url,
                    file: item.file,
                    archive: item.archive
                  }
                })}
              />

              {/* <div
                className="row mb-5"
                style={{ gap: '1rem', minHeight: '250px' }}
              >
                {documents.map(document => (
                  <Upload.Root key={document.archive.name}>
                    <Upload.Preview file={document.archive} />
                    <Upload.Content
                      document={{ file: document.archive, id: document.id }}
                    />
                    <Upload.Message message={document.message} />
                    <Upload.Actions>
                      {document.has_confirmation ? (
                        <>
                          <Upload.Action
                            className="btn btn-light-primary me-5"
                            onCLick={() => handleDownload(document.archive)}
                            icon={FaDownload}
                          />
                          <Upload.Action
                            className={`btn btn${
                              document.confirmed_at ? '' : '-light'
                            }-success me-5`}
                            icon={FaCheck}
                          />
                          <Upload.Action
                            className="btn btn-light-danger me-5"
                            onCLick={() => {
                              if (initialValues.formDocuments.length) {
                                setIsActiveAlert({
                                  document: document.archive,
                                  isActive: true
                                })
                                return
                              }
                              handleRemoveFile(document.archive)
                            }}
                            icon={FaTrash}
                          />
                        </>
                      ) : (
                        <>
                          <Upload.Action
                            className="btn btn-light-primary me-5"
                            onCLick={() => handleDownload(document.archive)}
                            icon={FaDownload}
                          />
                          <Upload.Action
                            className="btn btn-light-danger me-5"
                            onCLick={() => {
                              if (initialValues.formDocuments.length) {
                                setIsActiveAlert({
                                  document: document.archive,
                                  isActive: true
                                })
                                return
                              }
                              handleRemoveFile(document.archive)
                            }}
                            icon={FaTrash}
                          />
                        </>
                      )}
                    </Upload.Actions>
                  </Upload.Root>
                ))}
              </div> */}
            </div>
            <div className="card-footer d-flex justify-content-end py-6 px-9 pe-0">
              <button type="submit" className="btn btn-primary">
                Atualizar arquivos
              </button>
            </div>
          </div>
        </Form>
      </div>

      {alert && alert.isActive && (
        <Alert
          message={`Tem certeza que deseja excluir esse documento ${alert.document.name} ? ele não poderá ser recuperado`}
          onClickCancellButton={handlerClickButtonCancellAlert}
          onClickConfirmButton={() => {
            handleRemoveFile(alert.document)
            setIsActiveAlert(oldAlert => ({
              ...oldAlert,
              isActive: false
            }))
          }}
          isActive={alert.isActive}
        />
      )}

      {openModalFolder && (
        <Modal
          onClickButtonCancel={handleClickOnCloseDocuments}
          isOpenModal={openModalFolder}
          pageTitle="Documentos"
          style={{
            padding: 0,
            stylesModal: {
              width: '1150px',
              overflow: 'auto'
            }
          }}
          Children={
            <FormAddDocument
              initialValues={{
                ...initialValues
              }}
              updateDocuments={data => {
                setDocuments(oldDocuments => [...oldDocuments, data])
              }}
              isOpenInModal={{
                handleOnClose: () => {
                  handleClickOnCloseDocuments()
                }
              }}
            />
          }
        />
      )}
    </>
  )
}
