import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    text-transform: uppercase !important;
  }
  body{
    background-color: #eff3f8;
    overflow-x: hidden;
    input {
      text-transform: uppercase;
    }
    select {
      text-transform: uppercase;
    }
    hanging-punctuation: none;
  }
  button {
    cursor: pointer;
  }
  hr, p {
    margin: 0;
  }
`
