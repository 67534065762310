import { prefix } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { Input } from '../../../../../../components/Form'

interface InputProps {
  name: string
}

interface FormNameProps {
  first: InputProps
  second: InputProps
  parentField?: {
    name: string
    clearField: string
  }
  changeTab?: React.SetStateAction<string>
}

export const FormName: React.FC<FormNameProps> = ({
  first,
  second,
  parentField,
  changeTab
}) => {
  const {
    register,
    watch,
    resetField,
    getValues,
    setValue,
    clearErrors,
    formState: { errors }
  } = useFormContext()
  const watchField = watch(parentField?.name)
  useEffect(() => {
    if (watchField === 'false' && parentField) {
      const workFields = getValues(parentField?.clearField)
      if (workFields) {
        const objectEntries = Object.entries(workFields)
        for (let [key] of objectEntries) {
          key = `${parentField.clearField}.${key}`
          setValue(key, '')
        }
      }
      clearErrors(prefix)
    }
  }, [
    clearErrors,
    first.name,
    getValues,
    parentField,
    resetField,
    second.name,
    setValue,
    watchField
  ])
  return (
    <div className="row mb-5 pe-xl-5" id="name">
      <Input
        register={register}
        className="col-xl"
        label="Nome (EXATAMENTE conforme aparece no passaporte)"
        inputOptions={{
          classname: 'd-flex align-items-center'
        }}
        name={first?.name}
        tooltip={{
          container: {
            classname: 'ms-2'
          },
          classname: 'mw-30px',
          message: `<p style="max-width: 300px">
          Separe corretamente o nome do sobrenome
        </p>`
        }}
        errors={errors}
        rules={
          parentField
            ? { required: watchField === 'true' && changeTab !== 'previous' }
            : { required: changeTab !== 'previous' }
        }
        hasErrorCustom
        hasCustom
      />
      <Input
        className="col-xl"
        label="Sobrenome (EXATAMENTE conforme aparece no passaporte)"
        inputOptions={{
          classname: 'd-flex align-items-center'
        }}
        errors={errors}
        register={register}
        name={second.name}
        tooltip={{
          container: {
            classname: 'ms-2'
          },
          classname: 'mw-30px',
          message: `<p style="max-width: 300px">
          Separe corretamente o nome do sobrenome
                          </p>`
        }}
        rules={
          parentField
            ? { required: watchField === 'true' && changeTab !== 'previous' }
            : { required: changeTab !== 'previous' }
        }
        hasErrorCustom
        hasCustom
      />
    </div>
  )
}
