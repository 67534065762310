import useCollapse from 'react-collapsed'
import { Controller, useFormContext } from 'react-hook-form'
import { INPUT_MASK } from '../../../../../../common/constants'
import { Input, Select } from '../../../../../../components/Form'
import { ContainerAnimation } from '../../../styles'
import { CheckboxSimple } from '../CheckboxSimple'

interface FormProps {
  changeTab: any
  states: any
}

export const FormWillPayYouTrip: React.FC<FormProps> = ({
  changeTab,
  states
}) => {
  const {
    register,
    control,
    watch,
    formState: { errors }
  } = useFormContext()

  const watchField = watch('who_will_pay_for_your_trip.check')
  const watchFieldSameAddress = watch('has_same_address_applicant')

  const { getCollapseProps } = useCollapse({
    isExpanded: watchField >= '2'
  })
  const addressPayment = useCollapse({
    isExpanded: watchFieldSameAddress === 'false'
  })
  return (
    <div className="">
      <div className="row">
        <div
          {...getCollapseProps()}
          // isShow={watchField >= '2' ? 'show' : 'hidden'}
          // id="form_will_pay"
        >
          <div className="row mb-5">
            <Input
              errors={errors}
              register={register}
              className="col-xl"
              label="Nome"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name="who_will_pay_for_your_trip_name"
              hasCustom
              hasErrorCustom
              rules={{
                required: changeTab !== 'previous' && watchField >= '2'
              }}
            />
            {watchField === '2' && (
              <Select
                className="col-xl"
                label="Parentesco"
                name="who_will_pay_for_your_trip_relationship"
                rules={{
                  required: changeTab !== 'previous' && watchField >= '2'
                }}
                options={[
                  {
                    name: 'Filhos',
                    value: 'Filhos'
                  },
                  {
                    name: 'Pais',
                    value: 'Pais'
                  },
                  {
                    name: 'Irmãos',
                    value: 'Irmãos'
                  },
                  {
                    name: 'Cônjuge',
                    value: 'Cônjuge'
                  },
                  {
                    name: 'Amigos',
                    value: 'Amigos'
                  },
                  {
                    name: 'Outros',
                    value: 'Outros'
                  },
                  { name: 'Negócios', value: 'Negócios' },
                  { name: 'Outro Parente', value: 'Outro Parente' }
                ]}
                blank
                controlled
              />
            )}
          </div>
          <div className="row mb-5">
            <CheckboxSimple
              changeTab={changeTab}
              label="Mesmo endereço do aplicante?"
              positive={{
                name: 'has_same_address_applicant',
                hasValidationParent: {
                  parent_name: 'who_will_pay_for_your_trip.check',
                  condition: '2'
                }
              }}
              negative={{
                name: 'has_same_address_applicant',
                hasValidationParent: {
                  parent_name: 'who_will_pay_for_your_trip.check',
                  condition: '2'
                }
              }}
            />
          </div>
          <div {...addressPayment.getCollapseProps()}>
            <h3>Endereço do Pagante</h3>
            <div className="row mb-5">
              <Controller
                shouldUnregister={true}
                control={control}
                name="who_will_pay_for_your_trip.address.zip_code"
                render={({ field }) => (
                  <Input
                    {...field}
                    errors={errors}
                    register={register}
                    className="col-xl-2"
                    label="CEP"
                    inputOptions={{
                      classname: 'd-flex align-items-center'
                    }}
                    hasErrorCustom
                    mask={{
                      mask: INPUT_MASK.ZIP_CODE,
                      type: 'zipCode',
                      prefixInputsData: 'who_will_pay_for_your_trip.address.',
                      customFields: [
                        {
                          name: 'state',
                          type: 'state',
                          api_field: 'uf'
                        }
                      ]
                    }}
                    rules={{
                      required:
                        changeTab !== 'previous' &&
                        watchFieldSameAddress === 'false'
                    }}
                    // controlled
                    hasCustom
                  />
                )}
              />
              <Input
                errors={errors}
                register={register}
                className="col-xl"
                label="Rua, nº, complemento"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                controlled
                name={'who_will_pay_for_your_trip.address' + '.' + 'street'}
                rules={{
                  required:
                    changeTab !== 'previous' &&
                    watchFieldSameAddress === 'false'
                }}
                hasErrorCustom
                hasCustom
              />
              {/* <Input
                errors={errors}
                register={register}
                className="col-xl-2"
                label="Número"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={'who_will_pay_for_your_trip.address' + '.' + 'number'}
                controlled
                rules={{
                  required: changeTab !== 'previous' && watchField >= '2'
                }}
                hasErrorCustom
                hasCustom
              /> */}
            </div>
            <div className="row mb-5">
              <Input
                errors={errors}
                register={register}
                className="col-xl"
                label="Bairro"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={'who_will_pay_for_your_trip.address' + '.' + 'district'}
                controlled
                rules={{
                  required:
                    changeTab !== 'previous' &&
                    watchFieldSameAddress === 'false'
                }}
                hasErrorCustom
                hasCustom
              />
              <Input
                errors={errors}
                register={register}
                className="col-xl"
                label="Cidade"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                name={'who_will_pay_for_your_trip.address' + '.' + 'city'}
                controlled
                rules={{
                  required:
                    changeTab !== 'previous' &&
                    watchFieldSameAddress === 'false'
                }}
                hasErrorCustom
                hasCustom
              />
              <Select
                className="col-xl"
                label="Estado"
                labelOptions={{ classname: 'col-xl' }}
                selectOptions={{
                  classname: 'col-xl'
                }}
                controlled
                name={'who_will_pay_for_your_trip.address' + '.' + 'state'}
                rules={{
                  required:
                    changeTab !== 'previous' &&
                    watchFieldSameAddress === 'false'
                }}
                options={states}
                blank
              />
            </div>
          </div>
          <div className="row">
            <Controller
              shouldUnregister={true}
              control={control}
              name="who_will_pay_for_your_trip_cell_phone"
              render={({ field }) => (
                <Input
                  {...field}
                  errors={errors}
                  register={register}
                  className="col-xl"
                  label="DDD e Telefone"
                  inputOptions={{
                    classname: 'd-flex align-items-center'
                  }}
                  controlled
                  hasCustom
                  hasErrorCustom
                  rules={{
                    required: changeTab !== 'previous' && watchField >= '2'
                  }}
                />
              )}
            />
            <Input
              errors={errors}
              register={register}
              className="col-xl"
              label="Email"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              type={
                changeTab !== 'previous' && watchField >= '2'
                  ? 'email'
                  : undefined
              }
              name="who_will_pay_for_your_trip_email"
              hasCustom
              hasErrorCustom
              rules={{
                required: changeTab !== 'previous' && watchField >= '2'
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
