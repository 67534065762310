import { Header } from '../../../../../components/DataTable'

export const headers: Header[] = [
  { name: 'Nome', field: 'name', sortable: true },
  { name: 'Tipo do Form.', field: 'formType.name', sortable: true },
  { name: 'Cat.', field: 'serviceCategory.name', sortable: true },
  {
    name: 'Formato de atd.',
    field: 'service_format',
    sortable: true,
    custom: true
  },
  { name: 'Qtd.', field: 'quantity', sortable: true },
  {
    name: 'Parc. sem juros',
    field: 'interest_free_installments',
    sortable: true,
    custom: true
  },
  { name: 'Ativo', field: 'active', sortable: true, custom: true },
  { name: 'Criado em', field: 'created_at', sortable: true, custom: true },
  {
    name: 'Ações',
    field: 'actions',
    sortable: false
  }
]
