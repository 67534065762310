import React, { useCallback, useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Select } from '../../../../../components/Form'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { FormContainer } from './styles'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}
type TypesFormProps = {
  isOpenInModal?: IsOpenInModalProps
  initialValues?: FormApiResponse & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}
export const FormChangeStatus = ({
  isOpenInModal,
  initialValues
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()

  const { updateDataTable } = useUpdateDataTable()
  const [phasesProcess, setPhasesProcess] = useState<SelectOptions[]>([])
  const [defaultValues, setDefaultValues] = useState({})

  const { activeLoading, disableLoading } = useLoading()

  const onSubmitForm = useCallback(
    async (data: any) => {
      const formDataFormPhaseProcess = new FormData()
      formDataFormPhaseProcess.append('form_id', initialValues?.id?.toString())
      formDataFormPhaseProcess.append(
        'phase_process_id',
        data.phase_process_id.toString()
      )
      try {
        activeLoading()
        await api.post(
          '/commercial/formPhasesProcess',
          formDataFormPhaseProcess
        )
        updateDataTable()
        disableLoading()
        isOpenInModal?.handleOnClose?.()
        addToast({
          type: 'success',
          title: 'Registro atualizado',
          description: 'Registro alterado com sucesso'
        })
      } catch (error) {
        disableLoading()
        isOpenInModal?.handleOnClose?.()
        addToast({
          type: 'error',
          title: 'Erro ao atualizar o registro',
          description:
            'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
        })
      }
      disableLoading()
    },
    [
      activeLoading,
      addToast,
      disableLoading,
      initialValues,
      isOpenInModal,
      updateDataTable
    ]
  )

  const loadPhasesProcess = useCallback(async () => {
    activeLoading()
    try {
      const responsePhasesProcess = await api.get<PhaseProcessApiResponse[]>(
        '/commercial/phasesProcess'
      )
      setPhasesProcess(
        responsePhasesProcess.data.map(phaseProcess => ({
          name: phaseProcess.name,
          value: phaseProcess.id
        }))
      )
      const findPhaseProcess = responsePhasesProcess.data.find(phaseProcess => {
        let lastPhaseProcess: Partial<FormPhaseProcessApiResponse> = {
          id: 0
        }
        initialValues?.formPhaseProcess.forEach((formPhaseProcess: any) => {
          if (lastPhaseProcess?.id < formPhaseProcess.id) {
            lastPhaseProcess = formPhaseProcess
          }
        })
        if (lastPhaseProcess?.phaseProcess?.id === phaseProcess.id) {
          return phaseProcess
        }
        return false
      })
      setDefaultValues({
        phase_process_id: findPhaseProcess?.id
      })
      disableLoading()
    } catch (error) {
      disableLoading()
    }
  }, [activeLoading, disableLoading, initialValues?.formPhaseProcess])

  useEffect(() => {
    loadPhasesProcess()
  }, [loadPhasesProcess])

  return (
    <>
      <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
        <>
          <div className="card">
            <FormContainer>
              <div className="row mb-5">
                <Select
                  className="col-md-12"
                  name="phase_process_id"
                  label="Status"
                  options={phasesProcess}
                  rules={{ required: true }}
                  blank
                  defaultValue=""
                />
              </div>
            </FormContainer>
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button type="submit" className="btn btn-primary">
                Salvar
              </button>
            </div>
          </div>
        </>
      </Form>
    </>
  )
}
