export const SERVICE_FORMAT = ['in_person', 'virtual'] as const
export const SERVICE_FORMAT_TRANSLATED: Record<string, string> = {
  in_person: 'Pessoalmente',
  virtual: 'Virtual'
}

export const SELECT_SERVICE_FORMAT = SERVICE_FORMAT.map(serviceFormat => {
  return {
    name: SERVICE_FORMAT_TRANSLATED[serviceFormat],
    value: serviceFormat
  }
})
