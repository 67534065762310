export const INSTRUCTION_FILE: Record<string, string> = {
  readonly: 'DOCUMENTOS PARA LEITURA – NÃO LEVE AO CONSULADO',
  print: 'IMPRIMIR'
}
export const INSTRUCTION_FILE_OPTIONS = Object.entries(INSTRUCTION_FILE).map(
  ([key, value]) => ({
    name: value,
    value: key
  })
)
