import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import {
  Checkbox,
  Textarea,
  InputProps,
  TextareaProps
} from '../../../../../../components/Form'
import { ContainerAnimation } from '../../../styles'
interface FormGeneralProps {
  label: string
  positive: InputProps
  negative: InputProps
  explain: TextareaProps
  changeTab?: React.SetStateAction<string>
}

export const FormGeneral: React.FC<FormGeneralProps> = ({
  label,
  negative,
  positive,
  explain,
  changeTab
}) => {
  const {
    register,
    watch,
    resetField,
    getValues,
    clearErrors,
    setValue,
    formState: { errors }
  } = useFormContext()
  const watchField = watch(positive?.name)
  useEffect(() => {
    if (watchField === 'false') {
      const prefixArray = positive.name.split('.')
      prefixArray.pop()
      const prefix = prefixArray.join('.')
      const workFields = getValues(prefix)
      if (workFields) {
        const objectEntries = Object.entries(workFields)
        for (let [key] of objectEntries) {
          key = `${prefix}.${key}`
          if (positive.name !== key) {
            setValue(key, '')
          }
        }
      }
      clearErrors(prefix)
    }
  }, [
    clearErrors,
    explain.name,
    getValues,
    positive.name,
    resetField,
    setValue,
    watchField
  ])
  return (
    <div className="row my-10">
      <div>
        <p className="mb-2 form-check-label text-gray-700 fw-bold p-0">
          {label}
        </p>
        <div className="d-flex">
          <Checkbox
            className="col-xl-1"
            label="Sim"
            register={register}
            value="true"
            inputOptions={{
              classname: 'd-flex align-items-center'
            }}
            {...positive}
            rules={{ required: changeTab !== 'previous' }}
            type="radio"
            errors={errors}
            hasCustom
            hasErrorCustom
          />
          <Checkbox
            className="col-xl-1"
            label="Não"
            register={register}
            inputOptions={{
              classname: 'd-flex align-items-center'
            }}
            type="radio"
            value="false"
            rules={{ required: changeTab !== 'previous' }}
            errors={errors}
            hasCustom
            checked={!watchField || watchField === 'false'}
            {...negative}
          />
        </div>
      </div>
      <ContainerAnimation
        isShow={watchField === 'true' ? 'show' : 'hidden'}
        id="general"
      >
        <Textarea
          className="col-xl"
          label="Explique"
          register={register}
          hasCustom
          {...explain}
          errors={errors}
          rules={
            changeTab !== 'previous'
              ? {
                  required: watchField === 'true'
                }
              : { required: false }
          }
          style={{ minHeight: 150 }}
          hasErrorCustom
        />
      </ContainerAnimation>
    </div>
  )
}
