import { forwardRef, useState } from 'react'
import ReactQuillComponent from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import styled from 'styled-components'

type HtmlEditorProps = {
  onChange?: (value: string) => void
  defaultValue?: string
}

const ReactQuill = styled(ReactQuillComponent)`
  .ql-editor {
    color: unset;
  }
`

export const HtmlEditor = forwardRef(function HtmlEditor(
  props: HtmlEditorProps,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _ref: React.ForwardedRef<unknown>
) {
  const [editorState, setEditorState] = useState<string>(
    props.defaultValue || ''
  )

  return (
    <ReactQuill
      theme="snow"
      value={editorState}
      onChange={value => {
        setEditorState(value)
        props.onChange?.(value)
      }}
      onKeyDown={event => {
        if (event.key === 'Tab') {
          event.preventDefault()
          event.stopPropagation()
        }
      }}
      formats={[
        'bold',
        'italic',
        'underline',
        'strike',
        'list',
        'link',
        'video'
      ]}
      modules={{
        toolbar: {
          container: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'video'],
            ['clean']
          ]
        }
      }}
    />
  )
})
