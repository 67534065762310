import React, { useCallback, useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select } from '../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { FormContainer } from './styles'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}
type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: PhaseProcessApiResponse & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}
const toastSuccessData: Record<string, () => ToastMessage> = {
  create: () => ({
    type: 'success',
    title: 'Registro criado',
    description: 'Registro criado com sucesso'
  }),
  update: () => ({
    type: 'success',
    title: 'Registro atualizado',
    description: 'Registro alterado com sucesso'
  })
}
const toastErrorData: Record<string, () => ToastMessage> = {
  create: () => ({
    type: 'error',
    title: 'Erro ao adicionar o registro',
    description:
      'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
  }),
  update: () => ({
    type: 'error',
    title: 'Erro ao atualizar',
    description:
      'Ocorreu um erro ao alterar os dados, por favor, tente novamente.'
  })
}

export const FormPhaseProcess = ({
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [formTypes, setFormTypes] = useState([])
  const { activeLoading, disableLoading } = useLoading()

  useEffect(() => {
    activeLoading()
    async function loadFormTypes(): Promise<void> {
      activeLoading()
      try {
        const responseFormTypes = await api.get<FormTypeApiResponse[]>(
          '/commercial/formTypes'
        )
        setFormTypes(
          responseFormTypes.data.map(formType => ({
            name: formType.name,
            value: formType.id
          }))
        )
        disableLoading()
      } catch (err) {
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar os tipos de formulários',
          description:
            'Houve um error ao carregar os tipos de formulários, tente novamente mais tarde!'
        })
      }
    }
    loadFormTypes()
  }, [activeLoading, addToast, disableLoading, history])

  const onSubmitForm = useCallback(
    async (data: any) => {
      data = {
        ...data
      }
      const id = initialValues?.idUpdate
      const apiRequest =
        typeForm === 'create'
          ? api.post(apiCreate(), { ...data })
          : api.put(apiUpdate(id.toString()), { ...data })

      try {
        activeLoading()
        await apiRequest
        disableLoading()
        addToast(toastSuccessData[typeForm]?.())
        if (typeForm === 'update') {
          updateDataTable()
        }
        history.push(nameActions.read.to)
      } catch (error) {
        history.push(nameActions.read.to)
        addToast(toastErrorData[typeForm]?.())
      }
    },
    [
      activeLoading,
      addToast,
      disableLoading,
      history,
      initialValues?.idUpdate,
      typeForm,
      updateDataTable
    ]
  )

  return (
    <>
      <Form
        onSubmit={onSubmitForm}
        defaultValues={{
          name: initialValues?.name,
          form_type_id: initialValues?.form_type_id
        }}
      >
        <>
          <div className="card">
            <FormContainer>
              <div className="row mb-5">
                <Input
                  name="name"
                  label="Nome"
                  rules={{ required: true }}
                  className="col-md-4"
                />
                <Select
                  className="col-md-3"
                  name="form_type_id"
                  label="Tipo de formulário"
                  options={formTypes}
                  blank
                />
                <Select
                  className="col-md-3"
                  name="finisher"
                  label="Finalizador"
                  options={[
                    {
                      name: 'Sim',
                      value: 1
                    },
                    {
                      name: 'Não',
                      value: 0
                    }
                  ]}
                  blank
                />
              </div>
            </FormContainer>
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button type="submit" className="btn btn-primary">
                Salvar
              </button>
            </div>
          </div>
        </>
      </Form>
    </>
  )
}
